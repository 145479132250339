import * as React from "react";
import "./CallQueueSummaryView.scss";
import 'bootstrap-icons/font/bootstrap-icons.css';
import { AppsIcon } from "@fluentui/react-icons-northstar";

const CallQueueSummaryView = ({ data }) => {
    let pcaPercentage = data.pca + '%';
    let gosPercentage = data.gos + '%';
    return (
        <div className="container-fluid card365 px-4 px-md-5 py-1 m-0" style={{ position: "relative" }}>
            <div className="row py-1 text-primary border-left px-2"><h6 className="m-0">{data.callQueueName}</h6></div>
            <div className="row row-cols-2 card365kpi py-3 row-cols-md-4 mb-2">
                <div className="col card365kpi-h py-1 px-3 border-right">
                    <div className="d-flex card365kpi-sh1 sh1-c1">
                        {data.totalCalls}
                    </div>
                    <div className="d-flex card365kpi-sh2 align-items-center">
                        <div className="flex-grow-1 card365kpi-hc1 hct-c1">Calls Offered</div>
                        <div className="card365kpi-hc2 hc2-bg1"><i className="bi bi-arrow-left-right"></i></div>
                    </div>
                </div>
                <div className="col card365kpi-h py-1 px-3 border-right">
                    <div className="d-flex card365kpi-sh1 sh1-c2">
                        {data.answeredCalls}
                    </div>
                    <div className="d-flex card365kpi-sh2 align-items-center">
                        <div className="flex-grow-1 card365kpi-hc1 hct-c1">Answered</div>
                        <div className="card365kpi-hc2 hc2-bg2"><i className="bi bi-telephone"></i></div>
                    </div>
                </div>
                <div className="col card365kpi-h py-1 px-3 border-right">
                    <div className="d-flex card365kpi-sh1 sh1-c3">
                        {data.missedCalls}
                    </div>
                    <div className="d-flex card365kpi-sh2 align-items-center">
                        <div className="flex-grow-1 card365kpi-hc1 hct-c1">Missed</div>
                        <div className="card365kpi-hc2 hc2-bg3"><i className="bi bi-telephone-x"></i></div>
                    </div>
                </div>
                {/* <div className="col card365kpi-h py-1 px-3">
                    <div className="d-flex card365kpi-sh1 sh1-c4">
                        {data.returnedCalls}
                    </div>
                    <div className="d-flex card365kpi-sh2 align-items-center">
                        <div className="flex-grow-1 card365kpi-hc1 hct-c1">Returned</div>
                        <div className="card365kpi-hc2 hc2-bg4"><i className="bi bi-arrow-return-left"></i></div>
                    </div>
                </div> */}
                <div className="col card365kpi-h py-1 px-3">
                    <div className="d-flex card365kpi-sh1 sh1-c3">
                        {data.returnedCalls}
                    </div>
                    <div className="d-flex card365kpi-sh2 align-items-center">
                        <div className="flex-grow-1 card365kpi-hc1 hct-c1">Unique Missed</div>
                        <div className="card365kpi-hc2 hc2-bg3"><i className="bi bi-telephone-x"></i></div>
                    </div>
                </div>

            </div>

            <div className="row row-cols-2 card365kpi py-3 row-cols-md-4 mb-2">
                <div className="col card365kpi-h py-1 px-3 border-right">
                    <div className="card365kpi-sh1 sh1-c1 align-self-end">
                        {data.pca}<span className="fs_i_percent">%</span>
                    </div>
                    <div className="d-flex card365kpi-sh2 align-items-center">
                        <div className="flex-grow-1 card365kpi-hc1 hct-c1">Percentage Calls Answered</div>
                        <div className="card365kpi-hc3 hc3-ic4"><i className="bi bi-telephone-inbound-fill"></i></div>
                    </div>
                </div>
                <div className="col card365kpi-h py-1 px-3 border-right">
                    <div className="card365kpi-shTime sh1-c2">
                        {data.avgAnswerTime}
                    </div>
                    <div className="d-flex card365kpi-sh2 align-items-center">
                        <div className="flex-grow-1 card365kpi-hc1 hct-c1">Average Answer Time</div>
                        <div className="card365kpi-hc3 hc3-ic4"><i className="bi bi-stopwatch-fill"></i></div>
                    </div>
                </div>
                <div className="col card365kpi-h py-1 px-3 border-right">
                    <div className="card365kpi-shTime sh1-c3">
                        {data.longestWaitTime}
                    </div>
                    <div className="d-flex card365kpi-sh2 align-items-center">
                        <div className="flex-grow-1 card365kpi-hc1 hct-c1">Longest Wait Time</div>
                        <div className="card365kpi-hc3 hc3-ic4"><i className="bi bi-hourglass-bottom"></i></div>
                    </div>
                </div>
                <div className="col card365kpi-h py-1 px-3">
                    <div className="card365kpi-sh1 sh1-c4">
                        {data.gos}<span className="fs_i_percent">%</span>
                    </div>
                    <div className="d-flex card365kpi-sh2 align-items-center">
                        <div className="flex-grow-1 card365kpi-hc1 hct-c1">Grade of Service</div>
                        <div className="card365kpi-hc3 hc3-ic4"><i className="bi bi-person-fill-gear"></i></div>
                    </div>
                </div>

            </div>

            <div className="row card365kpi-h mb-2" style={{ gap: "16px" }}>
                <div className="col-auto card365kpi-h2 p-3 d-md-block d-none">
                    <i className="bi bi-grip-horizontal opacity_4"></i>
                    <i className="bi bi-grip-horizontal opacity_4"></i>
                    <i className="bi bi-grip-horizontal opacity_4"></i>
                    <i className="bi bi-grip-horizontal opacity_4"></i>
                    <i className="bi bi-grip-horizontal opacity_4"></i>
                    <i className="bi bi-grip-horizontal opacity_4"></i>
                </div>
                <div className="col card365kpi-h2 p-3">
                    <div className="d-flex card365kpi-sh1 sh1-c3">
                        {data.unreturnedCalls}
                    </div>
                    <div className="d-flex card365kpi-sh2 align-items-center">
                        <div className="flex-grow-1 card365kpi-hc1 hct-c1">Unreturned</div>
                        <div className="card365kpi-hc3 hc3-ic2"><i className="bi bi-telephone-x"></i></div>
                    </div>
                </div>
                <div className="col card365kpi-h2 p-3">
                    <div className="d-flex card365kpi-sh1 sh1-c1">
                        {data.returnedCalls}
                    </div>
                    <div className="d-flex card365kpi-sh2 align-items-center">
                        <div className="flex-grow-1 card365kpi-hc1 hct-c1">Returned</div>
                        <div className="card365kpi-hc3 hc3-ic1"><i className="bi bi-telephone-forward"></i></div>
                    </div>
                </div>
                <div className="col-auto card365kpi-h2 p-3 d-md-block d-none">
                    <i className="bi bi-grip-horizontal opacity_4"></i>
                    <i className="bi bi-grip-horizontal opacity_4"></i>
                    <i className="bi bi-grip-horizontal opacity_4"></i>
                    <i className="bi bi-grip-horizontal opacity_4"></i>
                    <i className="bi bi-grip-horizontal opacity_4"></i>
                    <i className="bi bi-grip-horizontal opacity_4"></i>

                </div>
            </div>

            <div className="row card365kpi-h mb-3" style={{ gap: "16px" }}>

                <div className="col card365kpi-h2 p-3">
                    <div className="d-flex card365kpi-sh1 sh1-c1 justify-content-between align-items-center">
                        <span>{data.returnedCallsByCaller}</span>
                        <span className="fs_i_percentHead">{data.perReturnedCallsByCaller}<span className="fs_i_percent">%</span></span>
                    </div>
                    <div className="d-flex card365kpi-sh2 align-items-center">
                        <div className="flex-grow-1 card365kpi-hc1 hct-c1">Returned by caller</div>
                        <div className="card365kpi-hc3 hc3-ic1 i-clr-bg-transparent"><i className="bi bi-person"></i></div>
                    </div>
                </div>
                <div className="col card365kpi-h2 p-3">
                    <div className="d-flex card365kpi-sh1 sh1-c1 justify-content-between align-items-center">
                        <span>{data.returnedCallsInTime}</span>
                        <span className="fs_i_percentHead">{data.perReturnedCallsInTime}<span className="fs_i_percent">%</span></span>
                    </div>
                    <div className="d-flex card365kpi-sh2 align-items-center">
                        <div className="flex-grow-1 card365kpi-hc1 hct-c1">Returned in Target</div>
                        <div className="card365kpi-hc3 hc3-ic1 i-clr-bg-transparent"><i className="bi bi-clock-history"></i></div>
                    </div>
                </div>
                <div className="col card365kpi-h2 p-3">
                    <div className="d-flex card365kpi-sh1 sh1-c1 justify-content-between align-items-center">
                        <span>{data.returnedCallsOutsideTarget}</span>
                        <span className="fs_i_percentHead">{data.perReturnedCallsOutsideTarget}<span className="fs_i_percent">%</span></span>
                    </div>
                    <div className="d-flex card365kpi-sh2 align-items-center">
                        <div className="flex-grow-1 card365kpi-hc1 hct-c1">Returned Outside Target</div>
                        <div className="card365kpi-hc3 hc3-ic1 i-clr-bg-transparent"><i className="bi bi-flower1 opacity_4"></i></div>
                    </div>
                </div>
                {/* <div className="col mr-2 card365kpi-h2 mt-2 p-3">
                            <div className="d-flex card365kpi-sh1 sh1-c3">
                                {data.uniqueMissedCalls}
                            </div>
                            <div className="d-flex card365kpi-sh2 align-items-center">
                                <div className="flex-grow-1 card365kpi-hc1 hct-c1">Unique missed</div>
                                <div className="card365kpi-hc3 hc3-ic2"><i className="bi bi-telephone-x"></i></div>
                            </div>
                        </div> */}
                {/* <div className="col card365kpi-h2 p-3">
                    <div className="d-flex card365kpi-sh1 sh1-c1">
                        {data.totalTalkTime}
                    </div>
                    <div className="d-flex card365kpi-sh2 align-items-center">
                        <div className="flex-grow-1 card365kpi-hc1 hct-c1">Total talk time</div>
                        <div className="card365kpi-hc3 hc3-ic1"><i className="bi bi-clock"></i></div>
                    </div>
                </div> */}
            </div>
            <div className="row">
                <div className="col d-flex flex-grow-1 flex-column" style={{ lineHeight: 1 }}>
                    <div className="pb365kpi-t1">Average Call Quality</div>
                    {/* <div className="d-flex text-right mr-1 mr-md-4 pt-2">
                                <div className="pb365kpi-ind2" title={"Poor: " + data.perPoorCallQuality + "%"} style={{ width: data.perPoorCallQuality + "%"}}></div>
                                <div className="pb365kpi-ind3" title={"Average: " + data.perAvgCallQuality + "%"} style={{ width: data.perAvgCallQuality + "%"}}></div>
                                <div className="pb365kpi-ind4" title={"Good: " + data.perGoodCallQuality + "%"} style={{ width: data.perGoodCallQuality + "%"}}></div>
                            </div> */}
                    <div className="pb365kpi-out mr-1 mr-md-4 mt-2">
                        <div className="d-flex">
                            <div className="pb365kpi-in2" style={{ width: data.perPoorCallQuality + "%" }} title={"Poor : " + data.perPoorCallQuality + "%"}></div>
                            <div className="pb365kpi-in3" style={{ width: data.perAvgCallQuality + "%" }} title={"Average : " + data.perAvgCallQuality + "%"}></div>
                            <div className="pb365kpi-in4" style={{ width: data.perGoodCallQuality + "%" }} title={"Good : " + data.perGoodCallQuality + "%"}></div>
                        </div>
                    </div>
                </div>
                <div className="col-auto d-flex text-right align-items-center">
                    {data.perGoodCallQuality >= data.perAvgCallQuality && data.perGoodCallQuality >= data.perPoorCallQuality ? <div className="pb365kpi-hx1">Good</div> :
                        data.perAvgCallQuality >= data.perPoorCallQuality ? <div className="pb365kpi-hx3">Average</div> : <div className="pb365kpi-hx2">Poor</div>}
                </div>
            </div>
        </div>


    );
    //     <div className="d-flex text-right align-items-center">
    //     {data.perGoodCallQuality >= data.perAvgCallQuality && data.perGoodCallQuality >= data.perPoorCallQuality ? <div className="pb365kpi-hx1">Good</div> :
    //         data.perAvgCallQuality >= data.perPoorCallQuality ? <div className="pb365kpi-hx3">Average</div> : <div className="pb365kpi-hx2">Poor</div>}
    // </div>
};

export default CallQueueSummaryView;
