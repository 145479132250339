import "hammerjs";
import "./OrganizationalChart.scss";
import { Avatar, Tooltip, Popup, Box, AudienceIcon } from "@fluentui/react-northstar";
import { Image, Circle, Surface, Path, Text, Group, geometry, align, vAlign } from "@progress/kendo-drawing";
import WindowContext from "../../Shared/Context/Context";

const CellWithUserList = (props) => {
    const data = props.data;
    return (
        data.length > 0 ?
            data.map(user => {
                return <div className="col align-items-center d-flex cursor-default p-1 border-bottom">
                    <Avatar className="mr-2 ml-1 collab-network-avtar"
                        image={user.image}
                        name={user.userName}
                    />
                    <div className="col d-flex align-items-center p-0 justify-content-between">
                        <div className="fs-12 text-secondary"><b>{user.userName}</b>
                            <div className="fs-10 lh-1-cst">{user.jobTitle}</div>
                        </div>
                        <div className="fs-10 mr-1 text-dark-mode" title="Collaboration time">{user.collaborationTime}</div>
                    </div>
                </div>
            })
            :
            <div className="col align-items-center cursor-default d-flex justify-content-center text-center p-1">
                <div className="fs-12 text-secondary">
                    <b>No one yet!</b><span className="fs-22 text-shadow-cst">&#129310;</span>
                </div>
            </div>

    )
}
// <WindowContext.Consumer>
//     {(context) => (
//         <div className="col justify-content-center align-items-center d-flex  p-1 border-bottom text-center">
//             {data.length > (context.mediaCategory.sm ? 6 : 10) ?
//                 <>
//                     {
//                         data.slice(0, (context.mediaCategory.sm ? 6 : 10)).map(user => {
//                             return <Tooltip
//                                 content={
//                                     <div>
//                                         <p className="pTooltipContent">{user.userName}</p>
//                                     </div>
//                                 }
//                                 trigger={<Avatar name={user.userName} image={user.image} />}
//                             />
//                         })
//                     }
//                     <Popup
//                         trigger={<Avatar label={"+" + (data.length - (context.mediaCategory.sm ? 6 : 10))} />}
//                         on="hover"
//                         pointing
//                         content={
//                             <div style={{ maxWidth: "250px" }}>
//                                 {
//                                     data.slice((context.mediaCategory.sm ? 6 : 10)).map(user => {
//                                         return <Tooltip
//                                             content={
//                                                 <div>
//                                                     <p className="pTooltipContent">{user.userName}</p>
//                                                     <p className="pTooltipContent">Collaboration Time : {user.collaborationTime}</p>
//                                                 </div>
//                                             }
//                                             trigger={<Avatar name={user.userName} image={user.image} />}
//                                         />
//                                     })
//                                 }
//                             </div>
//                         }
//                     />
//                 </>
//                 :
//                 data.map(user => {
//                     return <Tooltip
//                         content={
//                             <div>
//                                 <p className="pTooltipContent">{user.userName}</p>
//                                 <p className="pTooltipContent">Collaboration Time : {user.collaborationTime}</p>
//                             </div>
//                         }
//                         trigger={
//                                 <div>
//                                     <Avatar
//                                         image={user.image}
//                                         name={user.userName}
//                                     />
//                                     <div className="fs-12"><b>{user.userName}</b>
//                                         <div className="fs-10">{user.jobTitle}</div>
//                                     </div>
//                                 </div>
//                         }
//                     />
//                 })
//             }
//         </div>
//     )}
// </WindowContext.Consumer>


const OrganizationalChart = ({ data, widgetId }) => {
    return (
        <WindowContext>{
            (context) => (
                <>
                    <div className="container-fluid h-100">
                        <div className="container h-100">
                            <div className="row">
                                <div className={context.mediaCategory.sm ? "col my-2" : "col my-4"}>
                                    <div className="topUsrAreaPic">
                                        <div className="topUsrAreaPic-i">
                                            <div className="contact-list-icon">
                                                <div className="contact-list-icon-txt">
                                                    <div className="contact-list-icon-img">
                                                        <img src={data.userDetails.image} />
                                                    </div>
                                                    {data.userDetails.image == "" ? data.userDetails.initials : ""}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="topUsrAreaPic-tx">
                                            <div className="topUsrAreaPic-h">{data.userDetails.userName}</div>
                                            <div className="topUsrAreaPic-p">{data.userDetails.jobTitle}</div>
                                            <div className="topUsrAreaPic-p">{data.userDetails.email}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{ height: `${context.mediaCategory.sm ? "" : "calc(100% - 112px)"}` }}>
                                <div className="col m-2 z-5 py-1 collab-network align-items-center justify-content-center" style={{ backgroundColor: "#464775" }}>
                                    <div className="row text-center">
                                        <div className="col mx-2 my-2 text-white pb-1"><b>High</b></div>
                                    </div>
                                    <div className="row">
                                        <div className="rounded mx-1 container px-0 bg-light">
                                            <div id="card-high-collab" className={context.mediaCategory.sm ? "container p-0 collab-network-cards-m" : "container p-0 collab-network-cards"}>
                                                <CellWithUserList data={data.collaborationData.filter(e => e.category === "High")} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col m-2 z-4 py-1 collab-network align-items-center justify-content-center" style={{ backgroundColor: "#8B8CC7" }}>
                                    <div className="row text-center">
                                        <div className="col mx-2 my-2 text-white pb-1"><b>Medium</b></div>
                                    </div>
                                    <div className="row">
                                        <div className="rounded mx-1 container px-0 bg-light">
                                            <div id="card-med-collab" className={context.mediaCategory.sm ? "container p-0 collab-network-cards-m" : "container p-0 collab-network-cards"}>
                                                <CellWithUserList data={data.collaborationData.filter(e => e.category === "Medium")} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col m-2 z-3 py-1 collab-network2 align-items-center justify-content-center" style={{ backgroundColor: "#E2E2F6" }}>
                                    <div className="row text-center">
                                        <div className="col mx-2 my-2 text-primary pb-1"><b>Low</b></div>
                                    </div>
                                    <div className="row">
                                        <div className="rounded mx-1 container px-0 bg-light">
                                            <div id="card-low-collab" className={context.mediaCategory.sm ? "container p-0 collab-network-cards-m" : "container p-0 collab-network-cards"}>
                                                <CellWithUserList data={data.collaborationData.filter(e => e.category === "Low")} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        }</WindowContext>
    );
};

export default OrganizationalChart;
