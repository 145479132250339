import React from "react";
import { useState, useEffect } from "react";
import {
    Flex,
    Button,
    Loader,
    Dialog,
    Card,
    CardHeader,
    CardBody,
    Text,
    Header,
    FlexItem,
    Form,
    OpenOutsideIcon,
    FormCheckbox
} from "@fluentui/react-northstar";
import {
    CloseIcon
} from "@fluentui/react-icons-northstar";
import ConfigurationApiService from "../../../services/ConfigurationApiService";
import AuthHelper from "../../../services/auth-helper";
import Impersonation from "./Impersonation";
import { Position } from "@fluentui/react";
import { Icon } from 'semantic-ui-react';
import { OPERATIONSTATUS} from "../../Shared/uicomponents/OperationStatus/index";
import { Error403 } from "../../Shared/Error/403";
import { Error500 } from "../../Shared/Error/500";



const AccountSummary = (props) => {
    const [isOpen, setOpen] = useState(false);
    const [isOperating, setOperating] = useState(false);
    const [accountsummaryData, setAccountSummaryData] = useState(null);
    const [accountsummaryPlanData, setAccountSummaryPlanData] = useState(null);
    const [trialExtended, setTrialExtended] = useState(null);
    const [isDemo, setIsDemo] = useState(false);
    const [isDemoMode, setIsDemoMode] = useState(global.localStorage.getItem("demoGlobal"));
    const [isDirectCustomer, setIsDirectCustomer] = useState(false);
    const [appstoreUrl, setAppstoreUrl] = useState(null);
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [showWarning, setShowWarning] = useState(false);
    const [showError, setShowError] = useState(false);
    const [checkboxErrors, setCheckboxErrors] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [tenantStatus, setTenantStatus] = useState(null);

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }


    useEffect(() => {
        if (isDemoMode === "true") {
            setIsDemo(true);
        } else {
            setIsDemo(false);
        }
    }, []);

    useEffect(() => {
        AuthHelper.getAccessToken(function (token) {
            ConfigurationApiService.GetAccountSummary(token).then((response) => {

                if (response && response.status == 200) {
                    setAccountSummaryData(response.data);
                    setAccountSummaryPlanData(response.data._Plan);
                    if (response.data._Plan.filter((x) => x.isDirectCustomer == true).length > 0)
                        setIsDirectCustomer(true);
                    if (response.data._Plan.filter((x) => x.purchaseURL !== null).length > 0)
                        response.data._Plan.map((x) => { if (x.purchaseURL !== null) setAppstoreUrl(x.purchaseURL) });
                } else {

                }
                props.viewStatus.setPageStatus(response.status);
            });
        });
    }, []);

    //const fnExtendTrial = () => {
    //    setOperating(true);
    //    AuthHelper.getAccessToken(function (token) {
    //        ConfigurationApiService.UpdateTrialPeriod(7, token).then((response) => {
    //          if (response != null) {
    //              accountsummaryData.plan.dateStamp = response.dateStamp;
    //              accountsummaryData.plan.trialExtendCount = response.trialExtendCount; 
    //              setAccountSummaryData(accountsummaryData);     
    //              setTrialExtended(true);           
    //          } else {
    //            setTrialExtended(false);
    //          }
    //        }).finally(() => {
    //            setOpen(false);
    //            setOperating(false);
    //            setTimeout(function(){
    //                setTrialExtended(null);
    //            }, 3000);
    //        });
    //      });
    //}



    const fnUpdateAutoBuySubscription = (isChecked) => {
        AuthHelper.getAccessToken(function (token) {
            ConfigurationApiService.UpdateAutoBuySubscription(isChecked, token).then((response) => {
                if (response && response.status == 200) {
                    accountsummaryData.appSettings.isAutoBuySubscription = isChecked;
                    setAccountSummaryData(accountsummaryData);
                    props.handleTenantUpdate();
                    props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
                }
                else if (response.status == 401)
                    props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
                else
                    props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
            });
        });
    }

    const fnUpdateAutoRemoveDeletedEmployees = (isChecked) => {
        AuthHelper.getAccessToken(function (token) {
            ConfigurationApiService.UpdateAutoRemoveDeletedEmployees(isChecked, token).then((response) => {
                if (response && response.status == 200) {
                    accountsummaryData.appSettings.isAutoRemoveDeletedEmployees = isChecked;
                    setAccountSummaryData(accountsummaryData);
                    props.handleTenantUpdate();
                    props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
                 
                }
                else if (response.status == 401)
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
        else 
            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
                
            });
        });
    }

    useEffect(() => {
        AuthHelper.getAccessToken(function (token) {
            ConfigurationApiService.SelectPermissions(token)
                .then((response) => {
                    setSelectedPermissions(response.data);
                    setIsLoading(false);
                    props.viewStatus.setPageStatus(response.status);
                })
                .catch(error => {
                    console.error('Failed to fetch selected permissions:', error);
                    setIsLoading(false);
                });
        });
    }, []);

    useEffect(() => {
        AuthHelper.getAccessToken(function (token) {
            ConfigurationApiService.GetPermissionsUrl(token).then((response) => {
                if (response)
                    global.localStorage.setItem("permissionsUrl", response);
            });
        });
    }, []);


    useEffect(() => {
        const updateCheckboxErrors = () => {
            const apiPermissions = ['Organisation', 'Calls', 'Messages', 'Meetings'];
            const errors = {};

            apiPermissions.forEach((permission) => {
                if (!selectedPermissions.includes(permission)) {
                    errors[permission] = true;
                }
            });
            setCheckboxErrors(errors);
        };
        updateCheckboxErrors();
    }, [selectedPermissions]);

    const areAllPermissionsChecked = selectedPermissions.length >= 4;
    const showEditButton = !areAllPermissionsChecked;

    useEffect(() => {
        AuthHelper.getAccessToken(function (token) {
            ConfigurationApiService.GetTenantStatus(token)
                .then((response) => {
                    setTenantStatus(response);
                })
                .catch((error) => {
                    console.error('An error occurred while fetching the tenant status:', error);
                });
        });

    }, []);

    return (
        <>
            {isLoading ? (
                <Loader/>
            ) :
                props.viewStatus.pageStatus == 401 ? <Error403 /> :
                    props.viewStatus.pageStatus == 500 ? <Error500 /> :
                (
            <Flex gap="gap.small" column>
                <Card compact fluid>
                    <CardHeader>
                        <Text content="Current Plan" weight="bold" />
                    </CardHeader>
                    <CardBody style={{ padding: '10px' }}>
                        <Header as="h3" content={`${process.env.REACT_APP_APP_BRAND_NAME}`} />
                        {

                            accountsummaryData && accountsummaryData._Plan && !isDemo ? (
                                accountsummaryData._Plan.map((d) => {
                                    return (
                                        <div key={d.productName}>
                                            {d.productName !== "Insights" ? (
                                                <Header as="h6" content={`${d.quantity - d.licencesConsumed} out of ${d.quantity} ${d.productName} licences are Unused.`} weight="bold" />
                                            ) : (
                                                <></>
                                            )}
                                            {/* Uncomment the line below if you want to include the product price */}
                                            {/* at $ ${(d.productPrice * d.quantity).toFixed(2)} */}
                                        </div>
                                    );
                                })
                            ) : (
                                isDemo && (
                                    <Header as="h6" content={`2 out of 6 Collaboration Analytics licences are Unused.`} weight="bold" />
                                )
                            )



                        }
                        
                         {isDirectCustomer && appstoreUrl !== null && <div> < Button > <a href={appstoreUrl} target="_blank">Edit Subscriptions</a></Button></div>} 
                    </CardBody>
                </Card>
                {/*<Card compact fluid>*/}
                {/*    <CardHeader>*/}
                {/*        <Text content="Usage" weight="bold" />*/}
                {/*    </CardHeader>*/}
                {/*    <CardBody style={{ padding: '10px' }}>*/}
                {/*        <div className="mb-1" style={{ display: 'grid', gridTemplateColumns: '200px 1fr', borderBottom: 'inset', borderBottomWidth: 'thin' }}><span>Subscription Activated: </span><b>{accountsummaryData && accountsummaryData.usage ? accountsummaryData.usage.totalSubscriptions : '-'}</b></div>*/}
                {/*        <div className="mb-1" style={{ display: 'grid', gridTemplateColumns: '200px 1fr', borderBottom: 'inset', borderBottomWidth: 'thin' }}><span>Subscription on Trial: </span><b>{accountsummaryData && accountsummaryData.usage ? accountsummaryData.usage.totalTrialSubscriptions : '-'}</b></div>*/}
                {/*        <div className="mb-1" style={{ display: 'grid', gridTemplateColumns: '200px 1fr', borderBottom: 'inset', borderBottomWidth: 'thin' }}><span>Cards Created: </span><b>{accountsummaryData && accountsummaryData.usage ? accountsummaryData.usage.totalCards : '-'}</b></div>*/}
                {/*        <div className="mb-1" style={{ display: 'grid', gridTemplateColumns: '200px 1fr', borderBottom: 'inset', borderBottomWidth: 'thin' }}><span>Dashboards Created: </span><b>{accountsummaryData && accountsummaryData.usage ? accountsummaryData.usage.totalDashboards : '-'}</b></div>*/}
                {/*    </CardBody>*/}
                {/*</Card>*/}

                <Card compact fluid>
                    <CardHeader>
                        <Text content="App Settings" weight="bold" />
                    </CardHeader>
                    <CardBody style={{ padding: '10px' }}>
                        <Form>
                            <FormCheckbox
                                checked={accountsummaryData && accountsummaryData.appSettings ? accountsummaryData.appSettings.isAutoRemoveDeletedEmployees : false}
                                onClick={(event, { checked }) => {
                                    fnUpdateAutoRemoveDeletedEmployees(checked);

                                }}
                                disabled={!accountsummaryData || isDemo === true}
                                label="Automatically remove employees that have left the organization"
                                message={<><span className="text-danger">*</span> You will lose historic reporting on these employees.</>}
                            />
                            <FormCheckbox
                                checked={accountsummaryData && accountsummaryData.appSettings ? accountsummaryData.appSettings.isAutoBuySubscription : false}
                                onClick={(event, { checked }) => {
                                    fnUpdateAutoBuySubscription(checked);

                                }}
                                disabled={!accountsummaryData || isDemo === true}
                                label="Automatically increase licence quantity as required when all the licences have been exhausted"
                                message={<><span className="text-danger">*</span> This will update the billing amount required for these licences.</>}
                            />
                        </Form>
                    </CardBody>
                </Card>

                <Card compact fluid>
                    <CardHeader>
                        <Text content={`Microsoft Permissions for ${process.env.REACT_APP_APP_BRAND_NAME}`} weight="bold" />
                    </CardHeader>
                    <CardBody style={{ padding: '10px' }}>
                                <Form>
                                    {isDemoMode ? (
                                        <>
                                            <div className="d-flex align-items-end">
                                                <div className="fs-1rem"><i className="bi bi-check-circle-fill mr-1 text-success"></i>Organisation</div>
                                                <div className="ml-2 form-label text-secondary">
                                                    Access to all permissions.
                                                </div>
                                            </div>

                                            <div className="d-flex align-items-end">
                                                <div className="fs-1rem"><i className="bi bi-check-circle-fill mr-1 text-success"></i>Calls</div>
                                                <div className="ml-2 form-label text-secondary">
                                                    Access to all permissions.
                                                </div>
                                            </div>

                                            <div className="d-flex align-items-end">
                                                <div className="fs-1rem"><i className="bi bi-check-circle-fill mr-1 text-success"></i>Messages</div>
                                                <div className="ml-2 form-label text-secondary">
                                                    Access to all permissions.
                                                </div>
                                            </div>

                                            <div className="d-flex align-items-end">
                                                <div className="fs-1rem"><i className="bi bi-lock-fill mr-1"></i>Meetings</div>
                                                <div className="ml-2 form-label text-danger">
                                                    Scheduled meeting events will not be displayed in cards.
                                                </div>
                                            </div>

                                            <div className="col-auto mt-0">
                                                {showEditButton && (
                                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                                                        <Button
                                                            disabled
                                                            style={{ float: "right" }}
                                                            className="mb-1"
                                                            icon={<OpenOutsideIcon />}
                                                            content="Edit permissions"
                                                            onClick={() => openInNewTab(global.localStorage.getItem('permissionsUrl'))}
                                                            tinted
                                                        />
                                                    </div>

                                                )}
                                            </div>
                                        </>
                                    ) : (
                                            <>
                                                {selectedPermissions.includes('Organisation') ? (

                                                    <div className="d-flex align-items-end">
                                                        <div className="fs-1rem"><i className="bi bi-check-circle-fill mr-1 text-success"></i>Organisation</div>
                                                        <div className="ml-2 form-label text-secondary">
                                                            Access to all permissions.
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="d-flex align-items-end">
                                                        <div className="fs-1rem"><i className="bi bi-lock-fill mr-1"></i>Organisation</div>
                                                        <div className="ml-2 form-label text-danger">
                                                            Organisation data will not be displayed in cards.
                                                        </div>
                                                    </div>
                                                )}

                                                {selectedPermissions.includes('Calls') ? (
                                                    <div className="d-flex align-items-end">
                                                        <div className="fs-1rem"><i className="bi bi-check-circle-fill mr-1 text-success"></i>Calls</div>
                                                        <div className="ml-2 form-label text-secondary">
                                                            Access to all permissions.
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="d-flex align-items-end">
                                                        <div className="fs-1rem"><i className="bi bi-lock-fill mr-1"></i>Calls</div>
                                                        <div className="ml-2 form-label text-danger">
                                                            Meetings/Calls & Phone Calls events will not be displayed in cards.
                                                        </div>
                                                    </div>
                                                )}

                                                {selectedPermissions.includes('Messages') ? (
                                                    <div className="d-flex align-items-end">
                                                        <div className="fs-1rem"><i className="bi bi-check-circle-fill mr-1 text-success"></i>Messages</div>
                                                        <div className="ml-2 form-label text-secondary">
                                                            Access to all permissions.
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="d-flex align-items-end">
                                                        <div className="fs-1rem"><i className="bi bi-lock-fill mr-1"></i>Messages</div>
                                                        <div className="ml-2 form-label text-danger">
                                                            Message events and sentiment will not be displayed in cards.
                                                        </div>
                                                    </div>

                                                )}

                                                {selectedPermissions.includes('Meetings') ? (
                                                    <div className="d-flex align-items-end">
                                                        <div className="fs-1rem"><i className="bi bi-check-circle-fill mr-1 text-success"></i>Meetings</div>
                                                        <div className="ml-2 form-label text-secondary">
                                                            Access to all permissions.
                                                        </div>
                                                    </div>

                                                ) : (
                                                    <div className="d-flex align-items-end">
                                                        <div className="fs-1rem"><i className="bi bi-lock-fill mr-1"></i>Meetings</div>
                                                        <div className="ml-2 form-label text-danger">
                                                            Scheduled meeting events will not be displayed in cards.
                                                        </div>
                                                    </div>

                                                )}

                                                <div className="col-auto mt-0">
                                                    {showEditButton && (
                                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                                                            <Button
                                                                disabled={tenantStatus === 4}
                                                                style={{ float: "right" }}
                                                                className="mb-1"
                                                                icon={<OpenOutsideIcon />}
                                                                content="Edit permissions"
                                                                onClick={() => openInNewTab(global.localStorage.getItem('permissionsUrl'))}
                                                                tinted
                                                            />

                                                            {tenantStatus === 4 && (
                                                                <div className="d-flex align-items-end">
                                                                    <i className="bi bi-exclamation-triangle-fill mr-1" style={{ color: "yellow" }}></i>
                                                                    <div className="m1-1 form-label" style={{ color: "amber", fontSize: "12px" }}>
                                                                        Edit permissions request is being processed.
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>

                                                    )}
                                                </div>
                                
                                            </>
                                    )}
                            
                        </Form>
                    </CardBody>
                </Card>

                <Card compact fluid>
                    <CardHeader>
                        <Text content="Help" weight="bold" />
                    </CardHeader>
                    <CardBody style={{ padding: '10px' }}>
                                <p className="mb-1">If you have any questions, please visit our searchable online knowledgebase at <a href={`${process.env.REACT_APP_HELP_LINK}`} target="_blank">{process.env.REACT_APP_HELP_LINK}</a></p>
                    </CardBody>
                </Card>
                </Flex >
            )}
            {
                //accountsummaryData && accountsummaryData.plan ?
                //    <Dialog
                //        className=""
                //        backdrop={true}
                //        open={isOpen}
                //        header="Would you like to extend your trial?"
                //        headerAction={{
                //            icon: <CloseIcon />,
                //            title: 'Close',
                //            onClick: () => setOpen(false),
                //        }}
                //        footer={{
                //            children: (Component, props) => {
                //                const { styles, ...rest } = props;
                //                return (
                //                    <Flex styles={styles} hAlign="end" gap="gap.small">
                //                        <Button
                //                            size="medium"
                //                            content="Yes"
                //                            primary
                //                            onClick={fnExtendTrial}
                //                            disabled={isOperating || accountsummaryData.plan.trialExtendCount >= 2 || !accountsummaryData.plan.isBillingSet}
                //                            loading={isOperating}
                //                        />
                //                        <Button
                //                            disabled={isOperating}
                //                            size="medium"
                //                            content="No"
                //                            onClick={() => setOpen(false)}
                //                        />
                //                    </Flex>
                //                );
                //            },
                //        }}
                //        content={
                //            <div style={{ padding: '10px 4px' }}>
                //                {
                //                    accountsummaryData.plan.trialExtendCount >= 2 ?
                //                        <p><span className="text-danger">*</span> You have already extended your trial and we are unable to extend it again.</p>
                //                        :
                //                        <p><span className="text-danger">*</span> You can extend your trial for 7 days.</p>
                //                }
                //                {
                //                    accountsummaryData.plan.isBillingSet ?
                //                        <p><span className="text-danger">*</span> Your billing will start automatically after the trial.</p>
                //                        :
                //                        <p><span className="text-danger">*</span> Your billing is currently not setup. Trial can only be extended once billing is set. Please go to <a href="https://store.uk.analytics-365.com/" target="_blank">App Store</a> to set billing.</p>
                //                }
                //            </div>
                //        }
                //    />
                //    :
                //    <></>
            }
        </>
    )
}

export default AccountSummary;