import { useState, useEffect, useCallback } from "react";
import _, { repeat } from "lodash";
import "./dashboard.scss";

import "../../../../node_modules/react-grid-layout/css/styles.css";
import "../../../../node_modules/react-resizable/css/styles.css";
import { useHistory, Switch, Route, Redirect } from "react-router-dom";

import DashboardApiService from "../../../services/DashboardApiService";
import FiltersApiService from "../../../services/FiltersApiService";
import AuthHelper from "../../../services//auth-helper";
import AuthenticatedRoute from "../../RouteHelper";
import { OperationStatusIndicator } from "../../Shared/uicomponents/OperationStatus"
import {
  Dropdown,
  Tooltip,
  Button,
  Flex,
  Text,
  Loader,
  SettingsAudioIcon,
  Checkbox,
  Box,
} from "@fluentui/react-northstar";
import {
  AddIcon,
  BulletsIcon,
  ArrowLeftIcon,
  CalendarIcon,
  MeetingTimeIcon,
  LinkIcon,
  EditIcon,
  QuestionCircleIcon,
  ExclamationTriangleIcon,
  OpenOutsideIcon
} from "@fluentui/react-icons-northstar";
import AddCard from "./addcard";
import EditCard from "./editcard";
import GridComponent from "./DashGridLayout";

import WindowContext from "../../Shared/Context/Context";
import NativeDatePicker from "../../Shared/uicomponents/NativeDatePicker";
import TeamsDatePicker from "../../Shared/uicomponents/TeamsDatePicker";
import { Dialog } from "@progress/kendo-react-dialogs";
import MultiCardSettings from "../../filters/card/MultiCardSettings/MultiCardSettingsEdit";
import DashboardScheduler from "./DashboardScheduler";
import { Calendar } from "@material-ui/pickers";
import * as moment from "moment";
import NotProvisioned from "../../Auth/NotProvisioned";
import Impersonation from "../Configuration/Impersonation";
import { SignUpConsumer } from "../../Shared/Context/SignUpContext";
import copy from "copy-to-clipboard";
import ReactJoyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { useSetState } from "react-use";
import { Error403 } from "../../Shared/Error/403";
import { Error500 } from "../../Shared/Error/500";

//import { useSetState } from "react-use";
// import ReactJoyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";

const DashGrid = (props) => {
  const history = useHistory();
  const [dashboardList, setDashboardList] = useState([]);
  const [demoDashboardList, setDemoDashboardList] = useState([]);
  const [userDashboardList, setUserDashboardList] = useState([]);
  const [isDemo, setIsDemo] = useState(false);
  const [nonEditableDashboard, setNonEditableDashboard] = useState(false);
  const [nonEditableDashboardID, setNonEditableDashboardID] = useState(0);
  const [open, setOpen] = useState(false);
  const [openScheduler, setOpenScheduler] = useState(false);
  const [cardList, setCardList] = useState(null);
  const [cardsData, setCardsData] = useState([]);
  const [dashboardID, setDashboardID] = useState(Number(props.match.params.dashboardId));
  const [operation, setOperation] = useState(props.match.params.operation);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [editCardID, setEditCardID] = useState(-2);
  const [userFiltersData, setUserFiltersData] = useState(null);
  const [dashboardURL, setDashboardURL] = useState(null);
  const [copied, setCopied] = useState(false);
  const [introJourney, setIntroJourney] = useState(0)
  //const [dataLoaded,setDataLoaded] =useState(false);
  //const [isBtnVisible,setIsBtnVisible] = useState(false);
  // const [isBtnEdited,setIsBtnEdited] = useState(false);
  useEffect(() => {
    AuthHelper.getAccessToken(function (token) {
      FiltersApiService.GetAddCards(token).then(
        (response) => {
          if (response && response.status == 200) {
            setCardsData(response.data);
            props.viewStatus.setPageStatus(200);
            //setCard(cards);
          }
          else if (response.status == 401)
            props.viewStatus.setPageStatus(401);
          else
            props.viewStatus.setPageStatus(500);
        }
      );
    });

  }, []);
  useEffect(() => {
    setOperation(props.match.params.operation);
    props.viewStatus.setPageStatus(null);
    props.viewStatus.InformChildPageStatus(null);
  }, [props.match.params.operation]);

  useEffect(() => {

    let demo = global.localStorage.getItem("demoGlobal");
    if (demo === "true") {
      Impersonation.GetImpersonatedData(LoadUserFilter);
      Impersonation.RemoveImpersonation();
    }
    else {
      AuthHelper.getAccessToken(function (token) {
        LoadUserFilter(token);
      });
    }

    AuthHelper.getAccessToken(function (token) {
      DashboardApiService.GetDashboards(token, demo === "true" ? 1 : 0).then((response) => {
        if (response != null) {
          if (response.dashboards.length > 0) {
            if (response.dashboards.findIndex((d) => d.userDashboardsID === dashboardID) === -1) {
              let dId = response[0].userDashboardsID;
              setDashboardID(dId);
              history.push(`/dashboards/${operation}/${dId}`);
              if (global.localStorage) {
                global.localStorage.setItem(
                  "contentUrl_dashboards",
                  `/dashboards/${operation}/${dId}`
                );
              }
            }

            setUserDashboardList(
              response.dashboards.map((d) => {
                return {
                  key: d.userDashboardsID,
                  value: String(d.userDashboardsID),
                  header: d.name,
                };
              })
            );

            let demoDashboards = [];
            let dashboards = [];

            response.dashboards.forEach((e) => {
              if (e.isDemo === true) {
                demoDashboards.push(e);
              }
              if (e.isDemo === false) {
                dashboards.push(e);
              }
            });

            setDemoDashboardList(
              demoDashboards.map((d) => {
                return {
                  key: d.userDashboardsID,
                  value: String(d.userDashboardsID),
                  header: d.name,
                  //dateSelected: d.dateSelected
                  //  ? new Date(d.dateSelected)
                  //  : new Date(),
                };
              })
            );

            setDashboardList(
              dashboards.map((d) => {
                return {
                  key: d.userDashboardsID,
                  value: String(d.userDashboardsID),
                  header: d.name,
                  //dateSelected: d.dateSelected
                  //  ? new Date(d.dateSelected)
                  //  : new Date(),
                };
              })
            );
          } else {
            history.replace("/dashboards");
          }

          for (let i = 0; i < response.dashboards.length; i++) {
            if (dashboardID === response.dashboards[i].userDashboardsID && response.dashboards[i].isDefault && response.dashboards[i].name === "Insights") {
              setNonEditableDashboard(true);
              setNonEditableDashboardID(response.dashboards[i].userDashboardsID);
            }
          }
        }
      });
    });
  }, [dashboardID]);

  function LoadUserFilter(token) {
    FiltersApiService.GetUserData(token).then((response) => {
      let data = response;
      data.teamFilter.items = data.teamFilter.items.map((obj) => ({
        ...obj,
        key: obj.value,
      }));
      data.groupFilter.items = data.groupFilter.items.map((obj) => ({
        ...obj,
        key: obj.value,
      }));
      data.callQueueFilter.items = data.callQueueFilter.items.map((obj) => ({
        ...obj,
        key: obj.value,
      }));
      data.agentFilter.items = data.agentFilter.items.map((obj) => ({
        ...obj,
        key: obj.value,
      }));
      data.userFilter.items = data.userFilter.items.map((obj) => ({
        ...obj,
        key: obj.value,
      }));
      setUserFiltersData(data);
    });
  }

  useEffect(() => {
    if (demoDashboardList.some((e) => e.key === dashboardID)) {
      setIsDemo(true);
    } else {
      setIsDemo(false);
    }

  }, [dashboardID, demoDashboardList]);


  function getShareableDashboardUrl() {


  }

  function fnGetDashboardUrl(_dId) {
    AuthHelper.getAccessToken(function (token) {
      DashboardApiService.GetDashboardURL(_dId, token)
        .then((response) => {
          if (response) {
            setDashboardURL(response);
          }
        }
        );
    });
  }


  useEffect(() => {
    fnGetDashboardUrl(dashboardID);
  }, []);

  useEffect(() => {
    var demoMode = global.localStorage.getItem("demoGlobal");
    AuthHelper.getAccessToken(function (token) {
      DashboardApiService.GetJourneyState(demoMode, token).then((response) => {
        if (response === 1) {
          setIntroJourney(response);
        }
      })
    });
  }, []);

  const handleNavigationClick = (param, isDemo) => {

    history.push("/dashboards/" + param);
    if (global.localStorage) {
      global.localStorage.setItem(
        "contentUrl_dashboards",
        "/dashboards/" + param
      );
      global.localStorage.setItem("demo", isDemo);

    }
  };

  const onCancel = () => {
    setOpen(false);
  };

  const onConfirm = () => {
    setOpen(false);
    window.location.reload();
  };

  const handleCardData = (cardList) => {
    setCardList(cardList);
  }



  const handleScroll = (_cardList) => {
    let eleCardListLength = parseInt(global.localStorage.getItem('TestItems'));
    let eleEditCardID = global.localStorage.getItem('sCard');
    setCardList(_cardList);
    global.localStorage.getItem('CardEditedID');

    if ((eleCardListLength < _cardList?.length) && _cardList !== null && _cardList !== undefined) {
      let targetEle = "cardParent_" + _cardList[eleCardListLength].cardID;
      document.getElementById(targetEle)?.scrollIntoView({ behavior: 'smooth', block: "start" });
      global.localStorage.setItem('TestItems', _cardList?.length);
    }

    else if (_cardList?.length == eleCardListLength && _cardList !== null && _cardList !== undefined && eleEditCardID != null) {
      let targetEle = "cardParent_" + eleEditCardID;
      document.getElementById(targetEle)?.scrollIntoView({ behavior: 'smooth', block: "start" });
    }

    // setIsBtnVisible(false);  
    // setIsBtnEdited(false);

    if ((eleCardListLength < _cardList?.length) && _cardList !== null && _cardList !== undefined) {

      //for add card
      for (let i = eleCardListLength; i < _cardList?.length; i++) {
        document.getElementById(`${"cardParent_" + _cardList[i].cardID}`)?.classList.add("animated-box", "in");
        setTimeout(() => document.getElementById(`${"cardParent_" + _cardList[i].cardID}`)?.classList.remove("animated-box", "in"), 10000);
      }

    }
    else if (_cardList?.length == eleCardListLength && _cardList !== null && _cardList !== undefined && eleEditCardID != null) {

      //for edit card  
      document.getElementById(`${"cardParent_" + eleEditCardID}`)?.classList.add("animated-box", "in");
      setTimeout(() => document.getElementById(`${"cardParent_" + eleEditCardID}`)?.classList.remove("animated-box", "in"), 10000);
    }

    global.localStorage.removeItem('CardEditedID');

  }

  const convertDate = (str) => {
    var date = new Date(str),
      month = "" + (date.getMonth() + 1),
      day = "" + date.getDate(),
      year = date.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };
  const handleDashboardShare = () => {
    var getUrl = window.location;
    var urlValue = getUrl.protocol + "//" + getUrl.host + '/sd/?dID=' + dashboardURL + (isDemo === true ? '&d=1' : '');
    copy(urlValue);
    setCopied(true);
    setTimeout(() =>
      setCopied(false),
      2000);
  }

  const handleDashboardChange = (dId) => {

    if (dId == -1) {
      history.push(`/teaminsights`);
      if (global.localStorage) {
        global.localStorage.setItem(
          "contentUrl_dashboards",
          `dashboards/teaminsights`
        );
      }
    }
    else if (dId !== dashboardID) {
      setEditCardID(-1);
      setDashboardID(dId);
      fnGetDashboardUrl(dId);
      //setSelectedDate(
      //  dashboardList.find((d) => d.value === String(dId))
      //    ? dashboardList.find((d) => d.value === String(dId)).dateSelected
      //    : new Date()
      //);
      if (dId !== nonEditableDashboardID) {
        setNonEditableDashboard(false);
        console.log(nonEditableDashboardID);
        console.log(nonEditableDashboard);
      } else {
        setNonEditableDashboard(true);
        console.log(nonEditableDashboardID);
        console.log(nonEditableDashboard);
      }
      history.push(`/dashboards/${operation}/${dId}`);
      if (global.localStorage) {
        global.localStorage.setItem(
          "contentUrl_dashboards",
          `/dashboards/${operation}/${dId}`
        );
      }
    }
    // setIsBtnEdited(false);
    // setIsBtnVisible(false);

    global.localStorage.getItem('TestItems');
    global.localStorage.getItem('CardEditedID');

    if (global.localStorage) {
      global.localStorage.removeItem('TestItems');
      global.localStorage.removeItem('CardEditedID');
    }
  };

  const toggleOperation = (opr) => {

    setOperation(opr);
    handleNavigationClick(`${opr}/${dashboardID}`);
  };

  const onCardEdit = (cardID) => {
    setEditCardID(cardID);
    setOperation("edit");
    handleNavigationClick(`edit/${dashboardID}`);

    global.localStorage.setItem('CardEditedID', cardID);

  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  //  const updateSelectedDate = (selectedValue) => {
  //      ;
  //  if (isDemo) {
  //    setSelectedDate(selectedValue);
  //  } else {
  //    AuthHelper.getAccessToken((token) => {
  //      DashboardApiService.UpdateDashboardSelectedDate(
  //        dashboardID,
  //        (new Date).toDateString() == (new Date(selectedValue)).toDateString() ? null : selectedValue,
  //        token
  //      ).then((response) => {
  //        if (response === "Success") {
  //          dashboardList.find(
  //            (d) => d.value === String(dashboardID)
  //          ).dateSelected = selectedValue;
  //          setSelectedDate(selectedValue);
  //        } else {
  //          setSelectedDate(
  //            dashboardList.find((d) => d.value === String(dashboardID))
  //              .dateSelected
  //              ? dashboardList.find((d) => d.value === String(dashboardID))
  //                  .dateSelected
  //              : new Date()
  //          );
  //        }
  //      });
  //    });
  //  }
  //};

  const handleDemoClick = () => {
    setIsDemo(false);
    console.log(isDemo);
    localStorage.removeItem("contentUrl_dashboards");
    localStorage.removeItem("contentUrl_configuration");
    localStorage.removeItem("demoGlobal");
    localStorage.removeItem("demo");
    localStorage.removeItem("impersonationMode");

    /*history.push("/dashboards");*/
    /*setDemoDashboardList([]);*/
    setDashboardList([])
    window.location.href = `${window.location.origin}/dashboards`;
    /*window.location.reload(false);*/


    /*if(isChecked.checked){     

    var tenantGUID = "567b307c-5c6f-47a6-9d5b-9edb72f0a8a6";
    var tokenName = "tokenName";
    var expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 1);
   
    var tokenData = {
        tokenName: tokenName.trim(),
        userEmail: "bob.fleming@countrymatters.co.uk",
        userGUID: "f229584c-9ba7-4a13-9998-909e82b7ee19",
        expiryDate: expiryDate
    }
    AuthHelper.getAccessToken(function (token) {
        DashboardApiService.CreateImpersonationToken(tokenData, tenantGUID, token).then((response) => {
            if (response !== undefined && response !== "") {
                window.localStorage.setItem("impersonationToken", response.token);
                window.localStorage.setItem("impersonationMode", true);
                //props.handleglobalimpMode(true);
            }            
        });
      });

    setImpersonateMode(isChecked.checked);
    }
    else{
      setGlobalimpMode(isChecked.checked);
      window.localStorage.removeItem("impersonationMode")
      window.localStorage.removeItem("impersonationToken"); 
    }*/
  };

  const [{ run, steps, stepIndex, HSAClick }, setState] = useSetState({
    //stepIndex: 0,
    //HSAClick: false,
    run: true,
    steps: [
      {
        content: "Select default dashboards from the dropdown menu",
        locale: { skip: <strong>Skip</strong> },
        placement: "bottom",
        target: "#dashSelectBox",
        disableOverlayClose: true,
        disableBeacon: true,
      },
      {
        content: "Create new dashboards and add cards",
        locale: { skip: <strong>Skip</strong> },
        placement: "bottom",
        target: "#introViewDashList",
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
      },
      {
        content: "Edit cards. Filter by people, group or date and time",
        locale: { skip: <strong>Skip</strong> },
        placement: "bottom",
        target: "#editId",
        disableOverlayClose: true,
        disableBeacon: true,
      },

    ]
  })


  const handleJoyrideCallback = (data, context) => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      setState(index + (action === ACTIONS.PREV ? -1 : 1))
    }
    else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setState({ run: false });
      setIntroJourney(2);
      var demoMode = global.localStorage.getItem("demoGlobal");
      AuthHelper.getAccessToken(function (token) {
        DashboardApiService.UpdateJourneyState(2, demoMode, token).then((response) => {
          if (response) {
            if (demoMode === "true") {
              context.journeyState.setlatestDemoDashboardJourneyState(2);
            }
            else {
              context.journeyState.setlatestDashboardJourneyState(2);
            }
          }
        })
      });

    }
  };

  // const introClickHit = () => {

  //   setState({ run: true })
  //   if (stepIndex === 1) {
  //     setState({
  //       run: stepIndex === 1 ? false : run,
  //       HSAClick: !HSAClick,
  //       stepIndex: stepIndex
  //     })
  //   }
  // }


  return (
    <WindowContext.Consumer>
      {(context) =>
        ((isDemo === false && dashboardList.length) || (isDemo === true && demoDashboardList.length)) > 0 ? (
          props.viewStatus.pageStatus == 401 ? <Error403 /> :
            props.viewStatus.pageStatus == 500 ? <Error500 /> :
              <SignUpConsumer>
                {
                  (tenantStatus) => (
                    <>
                      {introJourney === 1 && <ReactJoyride
                        continuous
                        callback={(data) => handleJoyrideCallback(data, context)}
                        run={run}
                        steps={steps}
                        hideCloseButton
                        //scrollToFirstStep
                        showSkipButton
                        //stepIndex={stepIndex}
                        showProgress={true}
                        styles={{
                          options: {

                            primaryColor: 'rgb(98, 100, 167)',
                            //zIndex: 2000,
                          }
                        }}
                      />}

                      {isDemo && <div className="row mx-0">
                        <div className="col-auto mx-auto p-0" style={{ height: "43px" }}>
                          <div className="row rounded-lg cst-shadow align-items-center alert-primary mx-auto"
                            style={{ overflow: "hidden", lineHeight: "1.25" }}>
                            <div className="col">
                              <div className="row">
                                {tenantStatus === 100 && <div className="col-auto pl-2" style={{ lineHeight: "1.25" }}>
                                  <ExclamationTriangleIcon />
                                </div>}
                                <div className="col pl-0">
                                  {tenantStatus === 100 ? <div className="row">
                                    <div className="col pl-2 pr-0 text-nowrap">
                                      {/* <p className="m-0"
                                          style={{ fontWeight: "300", color: "darkslategray" }}>
                                        Analytics</p>
                                        <p className="m-0"
                                          style={{ fontWeight: "500" }}>365,&nbsp;
                                        </p> 
                                    */}
                                      Exploring app with sample data,
                                    </div>
                                    <div className="col-auto pl-2 ">
                                      Sign up <b>NOW</b> !
                                    </div>
                                  </div> : <div className="col-auto pr-0"><b>Exploring app</b> with sample data.</div>}
                                </div>
                              </div>
                            </div>
                            {tenantStatus === 100 ? <div className="col-auto h-100 p-1">
                              < button type="button" style={{ lineHeight: "1" }}
                                onClick={() => openInNewTab(global.localStorage.getItem("signUpUrl"))}
                                className="btn h-100 btn-primary"><OpenOutsideIcon className='fUi-1_25' /> Sign up
                              </button>
                            </div> : <div className="col-auto h-100 p-1">
                              <Checkbox className="demo-checkbox"
                                toggle
                                checked
                                onClick={() => {
                                  handleDemoClick();
                                }}
                              />
                              {/* < button type="button" style={{ lineHeight: "1" }}
                              onClick={() => {
                                handleDemoClick();
                              }}
                              className="btn h-100 btn-warning px-3">Off
                            </button> */}
                            </div>}

                          </div>
                        </div>
                      </div>
                      }

                      <Flex
                        space="between"
                        wrap
                        className={"py-1 dashboardheade"}
                        styles={{ padding: "0 8px", margin: `${context.mediaCategory.sm ? "auto" : "auto 16px"} ` }}
                      >
                        {operation === "view" ? (
                          <>


                            <Dropdown
                              id="dashSelectBox"
                              className={context.mediaCategory.sm ? "dashboard-selectbox mb-2 w-100" : "dashboard-selectbox"}
                              styles={{ width: 200 }}
                              items={isDemo ? demoDashboardList : dashboardList}
                              placeholder="Select your dashboard"
                              checkable
                              highlightedIndex={dashboardList.findIndex(
                                (d) => d.value === String(dashboardID)
                              )}
                              defaultValue={
                                userDashboardList.find(
                                  (d) => d.value === String(dashboardID)
                                )
                                //dashboardList.find((d) => d.value === String(dashboardID))
                              }
                              onChange={(e, selectedOption) =>
                                handleDashboardChange(Number(selectedOption.value.value))
                              }
                              itemToValue={(obj) => {
                                return obj.key;
                              }}
                            />


                            {/* { isBtnVisible  && <Button onClick={handleScroll}                     
                      content ="Click to view newly added cards!">                     
                      </Button> } 
                      { isBtnEdited &&  <Button onClick={handleScroll}
                    content ="Continue from where you left!">                          
                    </Button> }                                                                                                   */}
                            <Flex
                              className={context.mediaCategory.sm ? "w-100" : " "}
                              gap="gap.small"
                              vAlign="center"
                              styles={{ position: "relative" }}
                            >
                              {(cardList != null && dashboardID != -1) &&
                                <>
                                  <Tooltip
                                    trigger={
                                      <Button
                                        id="scheDashDashboard"
                                        disabled={nonEditableDashboard}
                                        icon={<MeetingTimeIcon size="large" />}
                                        iconOnly
                                        text
                                        onClick={() => setOpenScheduler(true)}
                                      />
                                    }
                                    content="Create new schedule"
                                  />
                                  {openScheduler && (
                                    <Dialog
                                      title="Create new schedule"
                                      autoFocus={true}
                                      onClose={() => setOpenScheduler(false)}
                                      width={650}
                                    >
                                      <DashboardScheduler
                                        dashboardId={dashboardID}
                                        cardList={cardList}
                                        isQuickAdd={false}
                                        cancelHandler={() => setOpenScheduler(false)}
                                      />
                                    </Dialog>
                                  )}
                                </>

                              }

                              <Tooltip
                                trigger={
                                  <Button
                                    id="copyLinkDashboard"
                                    className={copied ? "text-success" : ""}
                                    icon={<LinkIcon size="large" />}
                                    iconOnly
                                    text
                                    onClick={handleDashboardShare}
                                  />
                                }
                                content="Create Shared Dasboard Link"
                              />

                              {dashboardID != -1 && <Tooltip
                                trigger={
                                  <Button
                                    id="quickEditDashboard"
                                    disabled={nonEditableDashboard}
                                    icon={<EditIcon />}
                                    iconOnly
                                    text
                                    onClick={() => setOpen(true)}
                                  />
                                }
                                content="Quick Edit"
                              />}

                              {open && (
                                <Dialog
                                  title="Quick Edit"
                                  autoFocus={true}
                                  onClose={() => onCancel()}
                                  width={550}
                                // height={380}
                                >
                                  <MultiCardSettings
                                    dashboardId={dashboardID}
                                    submitHandler={onConfirm}
                                    cancelHandler={onCancel}
                                    userFiltersData={userFiltersData}
                                    addcardList={cardsData}
                                    quickEdit={true}
                                    viewStatus={props.viewStatus}
                                    onE
                                  />
                                </Dialog>
                              )}

                              {dashboardID != -1 && <Tooltip
                                trigger={
                                  <Button
                                    id="dashNavAdd"
                                    className="mb-1"
                                    icon={<AddIcon size="large" />}
                                    text
                                    iconOnly
                                    onClick={() => toggleOperation("add")}
                                    disabled={nonEditableDashboard}
                                  />
                                }
                                content="Add New Card"
                              />}

                              <Tooltip
                                trigger={
                                  <Button
                                    id="introViewDashList"
                                    className="ml-auto"
                                    icon={<BulletsIcon />}
                                    iconOnly
                                    onClick={() => handleNavigationClick("", isDemo)}
                                    primary
                                  />
                                }
                                content="View My Dashboards"
                              />
                              <Tooltip
                                trigger={
                                  <a href={`${process.env.REACT_APP_HELP_LINK}`} target="_blank">
                                    <Button
                                      id="helpDashboard"
                                      icon={<QuestionCircleIcon />}
                                      iconOnly
                                      primary
                                    /></a>
                                }
                                content="Help"
                              />

                            </Flex>

                          </>
                        ) : operation === "edit" ? (
                          <>
                            <Flex
                              gap="gap.small"
                              vAlign="center"
                              styles={{
                                position: "relative",
                                justifyContent: "space-between",
                              }}
                              fill
                            >
                              <Text className="d-flex align-items-center text-nowrap" size="medium" weight="regular" styles={{ marginBottom: "3.6px", fontSize: `${context.mediaCategory.sm ? ".8em" : ""}` }}>
                                Edit Card |{" "}
                                <Dropdown
                                  //inline
                                  className={context.mediaCategory.sm ? "w176fixSm pl-1" : "pl-1"}
                                  //styles={{ minWidth: 200 }}
                                  items={isDemo ? demoDashboardList : dashboardList.filter(f => f.key != -1)}
                                  placeholder="Select your dashboard"
                                  checkable
                                  defaultValue={userDashboardList.find(
                                    (d) => d.value === String(dashboardID)
                                  )}
                                  onChange={(e, selectedOption) =>
                                    handleDashboardChange(
                                      Number(selectedOption.value.value)
                                    )
                                  }
                                  itemToValue={(obj) => {
                                    return obj.key;
                                  }}
                                />
                              </Text>
                              <Flex gap="gap.smaller">
                                <Tooltip
                                  trigger={
                                    <Button
                                      id="back_dashboard_edit"
                                      className="mb-1"
                                      icon={<ArrowLeftIcon />}
                                      content={context.mediaCategory.sm ? "" : "Dashboard"}
                                      iconOnly={context.mediaCategory.sm}
                                      onClick={() => {
                                        toggleOperation("view");
                                      }}
                                    />
                                  }
                                  content="Back to Dashboard"
                                />
                                <Tooltip
                                  trigger={
                                    <Button
                                      id="view_dashboards_edit"
                                      className="mb-1"
                                      icon={<BulletsIcon />}
                                      iconOnly
                                      onClick={() => handleNavigationClick("", isDemo)}
                                      primary
                                    />
                                  }
                                  content="View My Dashboards"
                                />
                                <Tooltip
                                  trigger={
                                    <a href={`${process.env.REACT_APP_HELP_LINK}`} target="_blank">
                                      <Button
                                        id="help_edit"
                                        icon={<QuestionCircleIcon />}
                                        iconOnly
                                        primary
                                      /></a>
                                  }
                                  content="Help"
                                />
                              </Flex>
                            </Flex>
                          </>
                        ) : (
                          <>
                            {" "}
                            <Flex
                              gap="gap.small"
                              vAlign="center"
                              styles={{
                                position: "relative",
                                justifyContent: "space-between",
                              }}
                              fill
                            >
                              <Text className="d-flex align-items-center text-nowrap" size="medium" weight="regular" styles={{ marginBottom: "3.6px", fontSize: `${context.mediaCategory.sm ? ".8em" : ""}` }}>
                                Add Card |{" "}
                                <Dropdown
                                  className={context.mediaCategory.sm ? "w142fixSm pl-1" : "pl-1"}
                                  // className="mb-1 dashboard-selectbox"
                                  // styles={{ width: 200 }}
                                  items={isDemo ? demoDashboardList : dashboardList.filter(f => f.key != -1)}
                                  placeholder="Select your dashboard"
                                  checkable
                                  defaultValue={userDashboardList.find(
                                    (d) => d.value === String(dashboardID)
                                  )}
                                  onChange={(e, selectedOption) =>
                                    handleDashboardChange(
                                      Number(selectedOption.value.value)
                                    )
                                  }
                                  itemToValue={(obj) => {
                                    return obj.key;
                                  }}
                                />
                              </Text>

                              {tenantStatus === 2 && isDemo && <div className="row rounded-lg cst-shadow alert-primary btn-group p-0 mx-auto"
                                style={{ overflow: "hidden" }}>
                                <div className="col-auto p-2 pl-3">
                                  <ExclamationTriangleIcon />
                                </div>
                                <div className="col py-2 pl-0 pr-4 d-flex align-items-center">
                                  <div className="d-flex"
                                    style={{ letterSpacing: ".02em", fontSize: "1rem" }}>
                                    <p className="m-0"
                                      style={{ fontWeight: "300", color: "darkslategray" }}>
                                      Analytics</p>
                                    <p className="m-0"
                                      style={{ fontWeight: "500" }}>365,&nbsp;
                                    </p>
                                    <p className="text-nowrap m-0">Sign up <b>NOW</b> !</p>
                                  </div>
                                </div>
                                <button type="button"
                                  onClick={() => openInNewTab(global.localStorage.getItem("signUpUrl"))}
                                  className="btn btn-primary rounded-0 border-right-0 border-top-0 border-bottom-0 border-left"><OpenOutsideIcon className='fUi-1_25' /> Sign up
                                </button>
                              </div>}


                              <Flex gap="gap.smaller">
                                <Tooltip
                                  trigger={
                                    <Button
                                      id="back_dashboard"
                                      className="mb-1"
                                      icon={<ArrowLeftIcon />}
                                      content={context.mediaCategory.sm ? "" : "Dashboard"}
                                      iconOnly={context.mediaCategory.sm}
                                      onClick={() => {
                                        toggleOperation("view");
                                      }}
                                    />
                                  }
                                  content="Back to Dashboard"
                                />
                                <Tooltip
                                  trigger={
                                    <Button
                                      id="view_dashboards"
                                      className="mb-1"
                                      icon={<BulletsIcon />}
                                      iconOnly
                                      onClick={() => handleNavigationClick("", isDemo)}
                                      primary
                                    />
                                  }
                                  content="View My Dashboards"
                                /> <Tooltip
                                  trigger={
                                    <a href={`${process.env.REACT_APP_HELP_LINK}`} target="_blank">
                                      <Button
                                        id="help"
                                        icon={<QuestionCircleIcon />}
                                        iconOnly
                                        primary
                                      /></a>
                                  }
                                  content="Help"
                                />
                              </Flex>
                            </Flex>
                          </>
                        )}
                      </Flex>
                      <div style={{ padding: `${context.mediaCategory.sm ? "0" : "0 4px 0 6px"}`, height: `${context.mediaCategory.sm ? isDemo ? "calc(100vh - 129px)" : "calc(100vh - 83px)" : isDemo ? "calc(100vh - 86px)" : "calc(100vh - 43px)"}`, overflowY: "auto" }}>
                        <Switch>
                          <AuthenticatedRoute exact path="/dashboards/view/:dashboardId">
                            {props.viewStatus.operationStatus && <OperationStatusIndicator status={props.viewStatus.operationStatus} revertStatusToNull={() => props.viewStatus.DisplayOperationStatus(null)} />}
                            <GridComponent
                              demo={isDemo}
                              dashboardId={dashboardID}
                              addHandler={toggleOperation}
                              // onHit={introClickHit}
                              onCardEdit={onCardEdit}// {/*selectedDate={selectedDate}*/}
                              handleCardData={handleCardData}
                              disabled={nonEditableDashboard}
                              userFiltersData={userFiltersData}
                              AddcardList={cardsData}
                              handleScroll={handleScroll}
                              viewStatus={props.viewStatus}
                            />
                          </AuthenticatedRoute>

                          <AuthenticatedRoute path="/dashboards/add/:dashboardId">
                            <AddCard id={"addCard"}
                              dashboardId={dashboardID}
                              userFiltersData={userFiltersData}
                              demo={isDemo}
                              AddcardList={cardsData}
                              viewStatus={props.viewStatus}
                            />
                          </AuthenticatedRoute>
                          <AuthenticatedRoute path="/dashboards/edit/:dashboardId">
                            {/*<OperationStatusIndicator status={props.viewStatus.operationStatus} revertStatusToNull={() => props.viewStatus.DisplayOperationStatus(null)} />*/}
                            <EditCard

                              dashboardId={dashboardID}
                              editCardID={editCardID}
                              userFiltersData={userFiltersData}
                              AddcardList={cardsData}
                              demo={isDemo}
                              viewStatus={props.viewStatus}
                            />
                          </AuthenticatedRoute>
                        </Switch>
                      </div>
                    </>
                  )
                }
              </SignUpConsumer>
        ) : (

          props.viewStatus.pageStatus == 401 ? <Error403 /> :
            props.viewStatus.pageStatus == 500 ? <Error500 /> :
              <Loader />
        )
      }
    </WindowContext.Consumer>
  );
};
export default DashGrid;

// {/* <Dialog
//         className="shareDialog"
//         closeOnOutsideClick={true}
//         cancelButton="Close"
//         trigger={<Button className="mb-1" icon={<ShareAltIcon size="larger" />} text iconOnly/>}
//         content={<Text size="large" content="Coming Soon: You will be able to send a link to your dashboard for others users to see this information." />}
//     /> */}
