import React from 'react';
import { Redirect, useLocation } from "react-router-dom";
import { Flex, Button, Text, Segment, Checkbox } from '@fluentui/react-northstar'
import './auth.scss';
import './notprovisioned.scss';
import AuthHelper from '../../services/auth-helper';
import DashboardApiService from '../../services/DashboardApiService';
import { useState } from 'react';
import { useEffect } from 'react';


const ProvisioningInProgress = (props) => {
    const { state } = useLocation();
    const tenantStatus = state.tenantStatus;
    const subscriptionStatus = state.subscriptionStatus;
    const [globalimpMode, setGlobalimpMode] = useState(false);


    useEffect(() => {
        //AuthHelper.getAccessToken(function (token) {
        //   DashboardApiService.GetAppStoreAccountAdmin(token).then((response) => {
        //     if(response !== null)
        //     {
        //       if(dashboardList !== null)
        //       {
        //         if((dashboardList[0].tenantGUID === "9a4c70c3-ceef-4b3f-84c0-c728cb9a5fa3" && response) || window.localStorage.getItem("impersonationMode"))
        //           setImpersonationModeVisisble(true);
        //         else
        //           setImpersonationModeVisisble(false);
        //       }
        //     }
        //   });
        // });

        if (window.localStorage.getItem("impersonationMode")) {
            setGlobalimpMode(window.localStorage.getItem("impersonationMode"));
        }
    });

    const handleImpersonateMode = (checked) => {
        if (!checked) {
            setGlobalimpMode(checked);
            window.localStorage.removeItem("impersonationMode")
            window.localStorage.removeItem("impersonationToken");
            window.location.reload(false);
        }
        // else
        // {
        //   setImpersonateMode(checked);
        // }
    };

    const handleglobalimpMode = () => {
        if (window.localStorage.getItem("impersonationMode")) {
            window.location.reload(false);
        }
    };
    return (
        <div className="login-container-msg datatrkMain">
            <div className="innerlogin-msg">
                <div className="signUp-data">
                    <div className="signUp-datatrk datatrkL">
                        <div className="signUp-datatrkInr">
                            <div className="datatrk-logo">
                                <a className="navbar-logo" title="Logo">
                                    <img src="/logo365.png" alt="Logo" />
                                </a>
                            </div>
                            <div className="datatrk-h">
                                Analytics for Customer Engagement, Collaboration and Wellbeing
                            </div>
                            <div className="datatrk-p">
                                {process.env.REACT_APP_APP_BRAND_NAME} is a native app for Microsoft Teams that provides actionable insights into internal and external collaboration to improve business performance.
                                Intuitive dashboards display collaboration trends, participation, activity, sentiment and quality issues.
                                Company data protection policies and individual privacy is maintained through tight integration with Microsoft 365 admin permissions.
                                {process.env.REACT_APP_APP_BRAND_NAME} data insights provide a holistic view of people, team and group collaboration. Detailed individual interactions and personal data (such as chat messages and call data) are not made accessible to other subscribers.
                            </div>
                        </div>
                    </div>
                    <div className="signUp-datatrk datatrkR">
                        <div className="signUp-datatrkInr">
                            {subscriptionStatus === 4 &&
                                <>
                                    <div className="datatrk-h">Incomplete registration details</div>
                                <div className="datatrk-p">Please visit {process.env.REACT_APP_APP_BRAND_NAME} Store to complete your registration details to start using {process.env.REACT_APP_APP_BRAND_NAME}.</div>
                                </>
                            }
                            {subscriptionStatus === 8 &&
                                <>
                                    <div className="datatrk-h">Provisioning is in progress</div>
                                    {tenantStatus && tenantStatus < 4
                                        ? <div className="datatrk-p">We are waiting for your Office 365 administrator to grant access.</div>
                                        : <div className="datatrk-p">Please wait for 8-12 hours for us to sync your organisation details.</div>
                                    }
                                </>
                            }
                            {subscriptionStatus === 9 &&
                                <>
                                    <div className="datatrk-h">Incomplete registration process</div>
                                    {tenantStatus && tenantStatus === 2
                                        ? <div className="datatrk-p">We are waiting for your Office 365 administrator to grant access.</div>
                                    : <div className="datatrk-p">Please visit {process.env.REACT_APP_APP_BRAND_NAME} Store to complete your registration details to start using {process.env.REACT_APP_APP_BRAND_NAME}</div>
                                    }
                                </>
                            }
                            {globalimpMode ? <div className="row">
                                <div className="col m-4 text-center">
                                    <Checkbox label="Impersonation mode"
                                        checked={globalimpMode}
                                        onClick={(event, isChecked) => {
                                            handleImpersonateMode(isChecked.checked);
                                        }}
                                    />
                                </div>
                            </div> : <></>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProvisioningInProgress;