import React, { useEffect, useState } from "react";
//import parse from 'html-react-parser';
import {
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartLegend,
    ChartTooltip,
    ChartSeriesLabels,
    ChartValueAxis,
    ChartValueAxisItem
} from "@progress/kendo-react-charts";
import "hammerjs";
import './BarChart.scss';
import { SavePDFConsumer } from "../../Shared/Context/ExploreAppMode";
import { useInView } from "react-intersection-observer";


// const categories = ["Jan", "Feb", "March", "April"];
// const series = [
//     {
//         name: "Jatin",
//         data: [300, 350, 500, 400]
//     },
//     {
//         name: "Neeraj",
//         data: [500, 450, 350, 250]
//     },
//     {
//         name: "Prabhat",
//         data: [400, 250, 300, 350]
//     }
// ];

export default function BarChart({ data, widgetId }) {
    const [toolTipElementStyle, setToolTipElementStyle] = useState("");
    const [toolTipElementData, setToolTipElementData] = useState("");
    const { ref, inView } = useInView()
    const handleWindowMouseMove = (e) => {
        setToolTipElementStyle("");
        setToolTipElementData("");
        var elementArray = document.body.getElementsByClassName('k-chart-tooltip-wrapper');
        if (elementArray.length > 0) {
            console.log(elementArray[0].outerHTML);
            //console.log(parse(elementArray[0].getAttribute("style")));
            console.log(elementArray[0].getElementsByTagName("span")[0].innerHTML);
            setToolTipElementStyle(elementArray[0].getAttribute("style"));
            setToolTipElementData(elementArray[0].getElementsByTagName("span")[0].innerHTML);
        }

    }
    // var xstyle = {toolTipElementStyle};
    // var xdata = {toolTipElementData};

    const renderTooltip = ({
        point
    }) => <span>{point.category.toString()}: {point.value}</span>;

    return (

        // <div onMouseMove={handleWindowMouseMove}>
        // <div style={{position:"absolute", top:"100px", background:"red", color:"#000"}}>
        //     <div>{toolTipElementData}</div>
        // </div>
        // <div onMouseMove={handleWindowMouseMove}>
        //     <div className="col"
        //         style={{toolTipElementStyle}}>
        //         <div className="" style="transition-delay: 0ms;">
        //             <div className=""
        //                 style="background-color: rgb(255, 205, 86); border-color: rgb(255, 205, 86); opacity: 1; border-width: 1px;">
        //                 <span>{{toolTipElementData}}</span></div>
        //         </div>
        //     </div> */}
        // <div className="col-auto">{toolTipElementData}
        <SavePDFConsumer>
            {
                (yesPDF) => {
                    return <>
                        {data.categories.length >= 40 && <div ref={ref}> {inView && <div className="hideMeAfter5Seconds">
                            <p className='click-drag-flash p-2 rounded'><i className="bi bi-arrow-left-short"></i> <i className="bi bi-mouse-fill"></i> <i className="bi bi-arrow-right-short"></i></p>
                        </div>}</div>}
                        {
                            data && data.categories && data.categories.length > 0 &&
                            <Chart style={{ height: '100%' }} pannable={{
                                lock: "y",
                            }}
                                zoomable={{
                                    mousewheel: {
                                        lock: yesPDF ? ["y", "x"] : "y"
                                    },
                                    selection: {
                                        lock: yesPDF ? ["y", "x"] : "y"
                                    },
                                }}>
                                <ChartLegend position="top" orientation="horizontal" />
                                <ChartCategoryAxis>
                                    <ChartCategoryAxisItem categories={data.categories} labels={{
                                        rotation: 'auto'
                                    }} maxDivisions={10} max={yesPDF ? null : 40} />
                                </ChartCategoryAxis>
                                 <ChartValueAxis>
                                    {widgetId === 13 && data.series[0].data && Math.max(...data.series[0].data) < 10  && <ChartValueAxisItem  title={{
                                        text: "Total Messages",
                                    }} labels={{ padding: 5 }} majorUnit = {1} />}
                                    {widgetId === 13 && data.series[0].data && Math.max(...data.series[0].data) > 10 && <ChartValueAxisItem  title={{
                                        text: "Total Messages",
                                    }} labels={{ padding: 5 }} />}
                                    {widgetId === 11 && data.series[0].data && Math.max(...data.series[0].data) < 10 && <ChartValueAxisItem title={{
                                        text: "Number of Meetings/Calls",
                                    }} labels={{ padding: 5 }} majorUnit = {1} />}
                                    {widgetId === 11 && data.series[0].data && Math.max(...data.series[0].data) > 10 && <ChartValueAxisItem title={{
                                        text: "Number of Meetings/Calls",
                                    }} labels={{ padding: 5 }} />}
                                </ChartValueAxis>
                                <ChartSeries>
                                    {data.series.map((item, idx) => (
                                        <ChartSeriesItem
                                            key={idx}
                                            type="column"
                                            stack={true}
                                            color={item.color}
                                            tooltip={{ visible: true }}
                                            data={item.data}
                                            name={item.name}
                                        />
                                    ))}
                                    <ChartSeriesLabels data={data} />
                                </ChartSeries>
                                <ChartTooltip render={renderTooltip} />
                            </Chart>
                        }
                    </>
                }
            }
        </SavePDFConsumer>
    );
}
