import {
    Chart,
    ChartAxisDefaults,
    ChartSeries,
    ChartSeriesItem,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartLegend,
    ChartTooltip,
    ChartValueAxis,
    ChartValueAxisItem
} from '@progress/kendo-react-charts';
import "hammerjs";
import { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { SavePDFConsumer } from '../../Shared/Context/ExploreAppMode';
import './StackedBarChart.scss';

// const categories = ["01/12", "02/12", "03/12", "04/12", "05/12", "06/12", "07/12", "08/12", "09/12", "10/12", "11/12", "12/12", "13/12"];
// const series = [
//     {
//         name: "Good",
//         data: [300, 850, 500, 400, 600, 650, 800, 400, 300, 500, 450, 350, 250],
//         color: 'rgb(35, 123, 75)'
//     },
//     {
//         name: "Average",
//         data: [500, 450, 350, 650, 400, 500, 600, 650, 750, 200, 550, 350, 250],
//         color: 'rgb(248, 210, 42)'
//     },
//     {
//         name: "Poor",
//         data: [400, 250, 300, 350, 400, 600, 500, 400, 600, 400, 650, 550, 350],
//         color: 'rgb(196, 49, 75)'
//     }
// ];

export default function StackedBarChart({ data, widgetId }) {
    console.log(data);
    const { ref, inView } = useInView()
    const [timesUp, setTimesUp] = useState()
    const suffix = widgetId === 52 || widgetId === 53 || widgetId === 41 || widgetId === 42 || widgetId === 43 ? "" : "%";
    const labelContent = (e) => e.value + suffix;
    const renderTooltip = ({
        point
    }) => <span>{point.category.toString()}: {point.value}</span>;
    return (
        <SavePDFConsumer>
            {
                (yesPDF) => {
                    return <>
                        {data.categories.length >= 40 && <div ref={ref}> {inView && <div className="hideMeAfter5Seconds">
                            <p className='click-drag-flash p-2 rounded'><i className="bi bi-arrow-left-short"></i> <i className="bi bi-mouse-fill"></i> <i className="bi bi-arrow-right-short"></i></p>
                        </div>}</div>}
                        {
                            data && data.categories && data.categories.length > 0 &&
                            <Chart style={{ height: '100%' }} pannable={{
                                lock: "y",
                            }}
                                zoomable={{
                                    mousewheel: {
                                        lock: yesPDF ? ["y", "x"] : "y"
                                    },
                                    selection: {
                                        lock: yesPDF ? ["y", "x"] : "y"
                                    },
                                }}>
                                <ChartValueAxis>
                                    {(widgetId === 52 || widgetId === 53) && <ChartValueAxisItem
                                        title={{
                                            text: "Number of Calls",
                                        }}
                                        labels={{
                                            content: labelContent,
                                        }}
                                        min={0}
                                    // max={100}
                                    />}
                                    {/*
                                    {(widgetId === 41) && (data.series[0].data && data.series[0].data.length < 5) && <ChartValueAxisItem
                                        title={{
                                            text: "Number of Meetings/Calls",
                                        }}
                                        labels={{
                                            content: labelContent,
                                        }}
                                        min={0}
                                        majorUnit={1}

                                    />}
                                    {(widgetId === 41) && (data.series[0].data && data.series[0].data.length > 5) && <ChartValueAxisItem
                                        title={{
                                            text: "Number of Meetings/Calls",
                                        }}
                                        labels={{
                                            content: labelContent,
                                        }}
                                        min={0}
                                    />} */}


                                    {(widgetId === 41) && <ChartValueAxisItem
                                        title={{
                                            text: "Number of Meetings/Calls",
                                        }}
                                        labels={{
                                            content: labelContent,
                                        }}
                                        min={0}
                                        majorUnit={data.series[0].data < 5 ? 1 : null}
                                    />}


                                    {(widgetId === 42) && <ChartValueAxisItem
                                        title={{
                                            text: "Total Messages",
                                        }}
                                        labels={{
                                            content: labelContent,
                                        }}
                                        min={0}
                                    // max={100}
                                    />}
                                    {(widgetId === 43) && <ChartValueAxisItem
                                        title={{
                                            text: "Platform usage",
                                        }}
                                        labels={{
                                            content: labelContent,
                                        }}
                                        min={0}
                                    // max={100}
                                    />}
                                    {/* {(widgetId === 52 || widgetId === 53) && <ChartValueAxisItem
                            title={{
                                text: "Number of Calls",
                            }}
                            labels={{
                                content: labelContent,
                            }}
                            min={0}
                        // max={100}
                        />} */}
                                </ChartValueAxis>
                                {/* <ChartAxisDefaults
                        labels={{
                            format: "%0",
                        }}
                        /> */}
                                <ChartLegend position="top" orientation="horizontal" />
                                <ChartCategoryAxis>
                                    <ChartCategoryAxisItem categories={data.categories} labels={{
                                        rotation: 'auto'
                                    }} maxDivisions={10} max={yesPDF ? null : 40} />
                                </ChartCategoryAxis>
                                <ChartSeries>
                                    {data.series.map((item, idx) => (
                                        <ChartSeriesItem
                                            border={
                                                {
                                                    color: "white",
                                                    width: "1px"
                                                }
                                            }
                                            key={idx}
                                            color={item.color}
                                            type="column" stack={true}
                                            tooltip={{ visible: true }}
                                            data={item.data}
                                            name={item.name}
                                        />
                                    ))}
                                </ChartSeries>
                                <ChartTooltip render={renderTooltip} />
                            </Chart>
                        }
                    </>
                }
            }
        </SavePDFConsumer>
    );
}
