import "./../HSCProgress/HSCProgress.scss"
import React, { useState, useEffect } from 'react';
const HSCProgress = ({ className, progress = 0, progBarH = 6, label, progressLabel = true, zones = [20, 40, 60, 80], }) => {
    function roundNumber(value) {
        // Check if the number is an integer
        if (value % 1 === 0) {
            return Math.floor(value); // Return as an integer
        } else {
            return value.toFixed(2);  // Return rounded to 2 decimal places
        }
    }
    return (
        <>
            <div className={`${className ? className : ""} hsc-progress`}>
                <div className="progress-label">
                    <label className="m-0 text-nowrap">
                        {(!label) ? "Progress" : label}
                    </label>
                </div>
                <div className="wrapper">
                    <div className="progress-main" style={{ height: `${progBarH}px`, opacity: `${!progress ? '.3' : '1'}` }}>
                        {
                            zones != null && zones.length === 4 ?
                                <div className="couple-prog-gradient" style={{ height: `${progBarH}px` }}>
                                    <div className='insight-prog danger' style={{ width: `${zones[0]}%` }}></div>
                                    <div className='insight-prog warning' style={{ width: `${zones[1] - zones[0]}%` }}></div>
                                    <div className='insight-prog success' style={{ width: `${zones[2] - zones[1]}%` }}></div>
                                    <div className='insight-prog warning' style={{ width: `${zones[3] - zones[2]}%` }}></div>
                                    <div className='insight-prog danger' style={{ width: `${100 - zones[3]}%` }}></div>
                                </div>
                                :
                                <div className="couple-prog-gradient" style={{ height: `${progBarH}px` }}>
                                    <div className='insight-prog prog-bg'></div>
                                </div>

                        }
                        {progress ? <div className="progress-set-mark" style={{ left: `${progress > 0 ? Math.min(progress, 100) : Math.max(progress, 0)}%`, top: `${-(progBarH / 2 - 1)}px` }}>
                            <div className="cL mx-auto border-dark" style={{ height: `${progBarH + 4}px`, width: '0px', borderRadius: `${(progBarH + 4) / 2}px` }}></div>
                        </div> : <></>}
                    </div>
                    {progressLabel && <div className="progress-value">
                        <label>{progress ? `${roundNumber(progress)}%` : 'NA'}</label>
                    </div>}
                </div>
            </div>
        </>
    )
}

export default HSCProgress