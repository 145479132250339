import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartSeriesItemTooltip
} from '@progress/kendo-react-charts';
import { Flex, Text } from "@fluentui/react-northstar";
import { CircleIcon } from "@fluentui/react-icons-northstar";
import "hammerjs";
import { Grid, GridColumn as Column, GridColumn } from "@progress/kendo-react-grid";
import * as React from "react";
import { orderBy } from "@progress/kendo-data-query";
import './StackedBarChartAndGroupedGrid.scss';
import { tooltipAsLabelBehavior } from '@fluentui/react-northstar';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { SavePDFConsumer } from '../../Shared/Context/ExploreAppMode';
import { useEffect } from 'react';
import { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import WindowContext from '../../Shared/Context/Context';


const HeaderCell = (props) => {

  return (
    <a className="k-link" onClick={props.onClick} title={props.title}>
      <span title={props.title}>
        {props.title}
      </span>
      {props.children != null && props.children[0]}
    </a>
  );
};

const cellWithTotalMessages = (props) => {
  const data = props.dataItem;
  return (
    <td>
      {data.totalMessages} ({data.external})
    </td>
  );
}

const cellWithChats = (props) => {
  const data = props.dataItem;
  return (
    <td>
      {data.chats} ({data.oneToOne})
    </td>
  );
}

class cellWithSentiment extends React.Component {
  render() {
    let sentiment = this.props.dataItem.sentiment;
    let className = "st_good";
    switch (sentiment) {
      case "Negative":
        className = "st_poor";
        break;
      case "Neutral":
        className = "st_average";
        break;
      case "Positive":
        className = "st_good";
        break;
      default:
        className = "st_good";
        sentiment = "Positive";
    }
    return (
      <td>
        <CircleIcon className={className} /> {sentiment}
      </td>
    );
  }
}

export default function StackedBarChartAndGroupedGrid({ data, widgetId, downloadCard, valueAfterCardDownload }) {
  const [sort, setSort] = React.useState([]);
  const [cardDownload, setCardDownload] = React.useState(false);
  const _export = React.useRef(null);
  const { ref, inView } = useInView()
  useEffect(() => {
    setCardDownload(downloadCard)
    if (cardDownload) {
      if (_export.current !== null) {
        _export.current.save();
      }
      setCardDownload(false);
      valueAfterCardDownload(false);
    }
  }, []);
  const CustomTooltip = ({ data }) => {
    const getTooltipContent = (point) => {
      const { series, category, value } = point;
      const seriesItem = data.stackedBarChartData.series[series.index];
      return (
        <div className="custom-tooltip">
          <span>{category} : </span>
          <span>{value} | </span>
          <span>{seriesItem.percent[point.categoryIndex]}%</span>
        </div>
      );
    };

    return (
      <ChartTooltip render={({ point }) => getTooltipContent(point)} />
    );
  };
  const renderTooltip = ({
    point
  }) =>
    <span>{point.category.toString()}: {point.value} </span>;


  return (
    <WindowContext>{
      (context) => (
        <SavePDFConsumer>

          {
            (yesPDF) => {
              return <>
                {data.stackedBarChartData.categories.length >= 18 && <div ref={ref}> {inView && <div className="hideMeAfter5Seconds">
                  <p className='click-drag-flash-2 p-2 rounded'><i className="bi bi-arrow-left-short"></i> <i className="bi bi-mouse-fill"></i> <i className="bi bi-arrow-right-short"></i></p>
                </div>}</div>}
                {data.stackedBarChartData && data.stackedBarChartData.categories && data.stackedBarChartData.categories.length > 0 &&
                  <Chart style={{ height: '260px', marginBottom: "16px" }} pannable={{
                    lock: "y",
                  }}
                    zoomable={{
                      mousewheel: {
                        lock: yesPDF ? ["y", "x"] : "y"
                      },
                      selection: {
                        lock: yesPDF ? ["y", "x"] : "y"
                      },
                    }}>
                    <ChartValueAxis>

                      {(widgetId === 37 || widgetId === 40) && <ChartValueAxisItem
                        title={{
                          text: "Number of calls",
                        }}
                        min={0}
                      />}
                      {(widgetId === 34) && <ChartValueAxisItem
                        title={{
                          text: "Number of messages",
                        }}
                        min={0}
                      />}
                    </ChartValueAxis>
                    <ChartLegend position="top" orientation="horizontal" />
                    <ChartCategoryAxis>
                      <ChartCategoryAxisItem categories={data.stackedBarChartData.categories} labels={{
                        rotation: 'auto'
                      }} maxDivisions={10} max={yesPDF ? null : context.mediaCategory.sm ? 18 : 50} />
                    </ChartCategoryAxis>
                    <ChartSeries>
                      {data.stackedBarChartData.series.map((item, idx) => (
                        <ChartSeriesItem
                          key={idx}
                          color={item.color}
                          type="column" stack={true}
                          tooltip={{ visible: true }}
                          data={item.data}
                          name={item.name}
                        >
                        </ChartSeriesItem>


                      ))}
                    </ChartSeries>
                    {widgetId === 37 ? <CustomTooltip data={data} /> : <ChartTooltip render={renderTooltip} />}
                  </Chart>
                }
                {data.groupedGridData && data.groupedGridData.length > 0 && (widgetId === 37) && (
                  <Grid
                    className="groupedGrid"
                    style={{ height: "50%" }}
                    // resizable={true}
                    // reorderable={true}
                    data={orderBy(data.groupedGridData, sort)}
                    sortable={true}
                    sort={sort}
                    onSortChange={(e) => {
                      setSort(e.sort);
                    }}
                  >
                    <Column
                      field="userName"
                      title="Group"
                      width={120}
                      resizable={false}
                      locked
                    />
                    <Column title="Calls">
                      <Column className="darken" width={70} field="total.calls" title="Total (External)" />
                      <Column className="darken" width={65} field="total.talkTime" title="Talk Time" />
                      <Column className="darken" width={50} field="total.meetingsCalls" title="Meetings/Calls" />
                      <Column className="darken" width={50} field="total.video" title="%Video" />
                      <Column className="darken" width={30} field="total.phone" title="Phone Calls" />

                    </Column>
                    <Column title="One-to-One Meetings/Calls">
                      <Column width={70} field="oneToOne.calls" title="Total (External)" />
                      <Column width={65} field="oneToOne.talkTime" title="Talk Time" />
                      {/* <Column width={50} field="oneToOne.video" title="Video" />
                  <Column width={50} field="oneToOne.audio" title="Audio" /> */}
                    </Column>
                    <Column title="Group Meetings/Calls">
                      <Column className="darken" width={70} field="group.calls" title="Total (External)" />
                      <Column className="darken" width={65} field="group.talkTime" title="Talk Time" />
                      {/* <Column className="darken" width={50} field="group.video" title="Video" />
                  <Column className="darken" width={50} field="group.audio" title="Audio" /> */}
                    </Column>
                  </Grid>
                )}
                {data.groupedGridData && data.groupedGridData.length > 0 && (widgetId === 40) && (
                  <Grid
                    className="groupedGrid"
                    style={{ height: "50%" }}
                    // resizable={true}
                    // reorderable={true}
                    data={orderBy(data.groupedGridData, sort)}
                    sortable={true}
                    sort={sort}
                    onSortChange={(e) => {
                      if (e.sort.length === 0) {
                        setSort(e.sort);
                        return;
                      }
                      var sortOrder = e.sort[0].dir;
                      var columnName = e.sort[0].field;
                      if (columnName === "total.calls") {
                        let sortArray = [{ field: 'total.totalcalls', dir: sortOrder },
                        { field: 'total.calls', dir: sortOrder }];
                        setSort(sortArray);
                      } else { setSort(e.sort); }
                    }}
                  >
                    <GridColumn
                      field="userName"
                      title="Name"
                      width={120}
                      // resizable={false}
                      locked
                    />
                    <GridColumn title="Calls" >
                      <GridColumn className="darken" width={70} field="total.calls" title="Total (External)" />
                      <GridColumn className="darken" width={65} field="total.talkTime" title="Talk Time" />
                      <GridColumn className="darken" width={50} field="total.meetingandcalls" title="Meetings/Calls" />
                      <GridColumn className="darken" width={50} field="total.perVideo" title="% Video" />
                      <GridColumn className="darken" width={50} field="total.phone" title="Phone Calls" />

                    </GridColumn>
                    <GridColumn title="One-to-One Meetings/Calls">
                      <GridColumn width={70} field="oneToOne.calls" title="Total (External)" />
                      <GridColumn width={65} field="oneToOne.talkTimeTeamsCall" title="Talk Time" />
                    </GridColumn>
                    <GridColumn title="Group Meetings/Calls">
                      <GridColumn className="darken" width={70} field="group.calls" title="Total (External)" />
                      <GridColumn className="darken" width={65} field="group.talkTimeTeamsCall" title="Talk Time" />
                    </GridColumn>
                  </Grid>
                )}
                {data.gridData && data.gridData.length > 0 && (widgetId === 34) && (
                  <ExcelExport data={data.gridData} ref={_export}>
                    <Grid
                      className="standardGrid"
                      style={{ height: "50%" }}
                      data={orderBy(data.gridData, sort)}
                      sortable={true}
                      sort={sort}
                      onSortChange={(e) => {
                        setSort(e.sort);
                      }}
                    >
                      <Column field="userName" title="User Name" locked width={130} />
                      <Column
                        field="totalMessages"
                        title="Messages (External)"
                        width={130}
                        cell={cellWithTotalMessages}
                      />
                      <Column
                        field="chats"
                        title="Chat (One-To-One)"
                        width={130}
                        cell={cellWithChats}
                      />
                      <Column field="posts" title="Posts" width={85} />
                      <Column field="replies" title="Replies" width={85} />
                      <Column field="sentiment" title="Average Sentiment" width={120} cell={cellWithSentiment} />
                      <Column field="perPositive" title="% Positive" width={100} />
                      <Column
                        field="messageRecievedWhileBusy"
                        title="Message Sent While On Meeting/Call"
                        width={130}
                      />
                    </Grid>
                  </ExcelExport>
                )}
              </>
            }
          }
        </SavePDFConsumer>
      )
    }
    </WindowContext>

  );
}


