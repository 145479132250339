
import React, { useState, useEffect } from "react";
import "./teaminsight.scss";
import _, { set } from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import FilterPopup from "../TeamInsight/FilterPopup";
import {
    Dropdown,
    Breadcrumb,
    Flex,
    Tooltip,
    Text,
    Button,
    Form,
    Loader,
    Segment,
    Checkbox,
    FlexItem,
    Grid as FluentGrid,
    Box,
    Card,
    Image,
    List,
    Accordion,
    indicatorBehavior,
    FormField,
    FormButton,
    FormInput,
    FormDropdown,
    FormLabel
} from "@fluentui/react-northstar";
import {
    BulletsIcon, ChevronEndMediumIcon,
    AddIcon,
    MeetingTimeIcon,
    OpenOutsideIcon,
    TrashCanIcon,
    EditIcon,
    ShareGenericIcon,
    LinkIcon,
    QuestionCircleIcon,
    ExclamationTriangleIcon,
    ArrowLeftIcon,
} from "@fluentui/react-icons-northstar";
import {
    Grid,
    GridColumn as Column,
    GridNoRecords,
} from "@progress/kendo-react-grid";
import { Dialog as KendoDialog } from "@progress/kendo-react-dialogs";
import copy from "copy-to-clipboard";
import DashboardApiService from "../../../services/DashboardApiService";
import AuthHelper from "../../../services//auth-helper";
import CenteredHeader from "../../Shared/Header/Header";
import WindowContext from "../../Shared/Context/Context";
import Impersonation from "../Configuration/Impersonation";
import { SignUpConsumer } from "../../Shared/Context/SignUpContext";
import { Error403 } from "../../Shared/Error/403";
import { Error500 } from "../../Shared/Error/500";
import MyInsights from "../TeamInsight/myInsights";



const TeamInsightsBreadCrumb = ({ stack, setStack, fnUpdateUserToView }) => {
    const handleBreadcrumbClick = (index, userguid, ismanager) => {
        setStack(stack.slice(0, index));
        fnUpdateUserToView(userguid, ismanager);
    };

    return (
        <div>
            <Breadcrumb aria-label="breadcrumb">
                {stack.map((user, index) => (
                    <React.Fragment key={user.userguid}>
                        <Breadcrumb.Item>
                            {index === stack.length - 1 ? (
                                <span>{user.username}</span>
                            ) : (
                                <Breadcrumb.Link
                                    href="#"
                                    onClick={() => handleBreadcrumbClick(index, user.userguid, user.ismanager)}
                                >
                                    {user.username}
                                </Breadcrumb.Link>
                            )}
                        </Breadcrumb.Item>
                        {index < stack.length - 1 && (
                            <Breadcrumb.Divider>
                                <ChevronEndMediumIcon />
                            </Breadcrumb.Divider>
                        )}
                    </React.Fragment>
                ))}
            </Breadcrumb>
        </div>
    );
};

const TeamInsights = (props) => {
    const [insightData, setInsightData] = useState(null);
    const [isTeamView, setTeamView] = useState(null);
    const [isLoggedInUserManager, setIsLoggedInUserManager] = useState(null);

    const [myNetworkUsers, setMyNetworkUsers] = useState(null);
    const [wellbeingCategories, setWellbeingCategories] = useState([]);
    const [wellBeingScoreDescriptions, setWellBeingScoreDescriptions] = useState([]);
    const [viewUserGuid, setViewUserGuid] = useState(null);
    const [loggedInUserGuid, setLoggedInUserGuid] = useState(null);
    const [filterData, setFilterData] = useState({
       endDate: new Date(),
        startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
        timeCategory: 1,
        period: 3
    })
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [childViewLoading, setChildViewLoading] = useState(true);


    const [isDemo, setIsDemo] = useState(false);
    const [demoMode, setDemoMode] = useState(false);
    const [isCustomerSignedUp, setIsCustomerSignedUp] = useState(global.localStorage.getItem("isCustomerNotRegistered"));

    const [impersonateMode, setImpersonateMode] = useState(false);
    const [globalimpMode, setGlobalimpMode] = useState(false);
    const [impersonationModeVisisble, setImpersonationModeVisisble] = useState(false);

    const handleNavigationClick = (param) => {
        history.push("/dashboards/");

        if (global.localStorage) {
            global.localStorage.setItem(
                "contentUrl_dashboards",
                "/dashboards/"
            );
        }
    };



    const handleDemoMode = (isChecked) => {
        setDemoMode(isChecked.checked);
        setIsDemo(isChecked.checked);
        var storeDemo = "false";

        if (isChecked.checked) {
            storeDemo = "true";
        }

        global.localStorage.setItem("demoGlobal", storeDemo);

        if (isChecked.checked === false) {
            window.location.reload(false);
        }
    };
    const handleImpersonateMode = (checked) => {
        if (!checked) {
            setGlobalimpMode(checked);
            window.localStorage.removeItem("impersonationMode")
            window.localStorage.removeItem("impersonationToken");
            window.location.reload(false);
        }
        else {
            setImpersonateMode(checked);
        }
    }

    const handleglobalimpMode = () => {
        if (window.localStorage.getItem("impersonationMode")) {
            window.location.reload(false);
        }
    }

    const transformData = (apiData, categoryData) => {
        let transformedData = {};
        let customerProfileMap = apiData.customerProfileDetails?.reduce((acc, item) => {
            acc[item.id] = item;
            return acc;
        }, {});

        let customerProfile;
        if (apiData.managerProfileDetails) {
            customerProfile = customerProfileMap[apiData.managerProfileDetails.customerProfileId];
            let manager = apiData.managerProfileDetails;

            let userSentimentsArray = Object.values(manager.userSentiments);
            let positiveSentimentUsers = userSentimentsArray.filter(user => user.sentiment > 0);
            let negativeSentimentUsers = userSentimentsArray.filter(user => user.sentiment < 0);
            let neutralSentimentUsers = userSentimentsArray.filter(user => user.sentiment == 0);
            let userWellbeingScore = categoryData.filter(val => val.userGUID === manager.userGUID);
            let negativeSentimentUserGUIDs = Object.keys(manager.userSentiments)
                .filter(key => manager.userSentiments[key].sentiment < 0) // Filter only negative sentiments
                .sort((a, b) => manager.userSentiments[b].sentiment - manager.userSentiments[a].sentiment) // Sort by sentiment
            //.slice(0, 5);
            let positiveSentimentUserGUIDs = Object.keys(manager.userSentiments)
                .filter(key => manager.userSentiments[key].sentiment > 0) // Filter only positive sentiments
                .sort((a, b) => manager.userSentiments[b].sentiment - manager.userSentiments[a].sentiment) // Sort by sentiment
            let neutralSentimentUserGUIDs = Object.keys(manager.userSentiments)
                .filter(key => manager.userSentiments[key].sentiment === 0) // Filter only neutral sentiments
                .sort((a, b) => manager.userSentiments[b].sentiment - manager.userSentiments[a].sentiment) // Sort by sentiment
            let negativeSentimentUsersList = negativeSentimentUserGUIDs.map(key => ({
                key: key,
                value: manager.userSentiments[key]
            }));
            let positiveSentimentUsersList = positiveSentimentUserGUIDs.map(key => ({
                key: key,
                value: manager.userSentiments[key]
            }));
            let neutralSentimentUsersList = neutralSentimentUserGUIDs.map(key => ({
                key: key,
                value: manager.userSentiments[key]
            }));
            let top5keyValuePairs = negativeSentimentUserGUIDs.slice(0, 5).map(key => ({
                key: key,
                value: manager.userSentiments[key]
            }));
            let managerData =
            {
                vibe: 'positive',
                userName: manager.displayName,
                userGUID: manager.userGUID,
                userDesignation: manager.jobTitle,
                userEmail: manager.mail,
                userImage: manager.userImage,
                reporteeCount: manager.reporteeCount,
                userStatus: 'success',
                manager: 'Shiv',
                managerDesignation: 'CTO',
                managerStatus: 'error',
                managerImage: '',
                progressType: 'productive-hours',
                meetingsPercentage: Number(manager.meetingTime),//Number(((manager.meetingTime / (manager.avgWorkingHours * 3600 * numberOfDays)) * 100).toFixed(1)),
                callsPercentage: Number(manager.callTime),//Number((((manager.messageTime + manager.callTime) / (manager.avgWorkingHours * 3600 * numberOfDays)) * 100).toFixed(1)),
                focusedPercentage: Number(100 - Number(manager.meetingTime + manager.callTime)),//Number(((((manager.avgWorkingHours * 3600 * numberOfDays) - (manager.meetingTime + manager.callTime + manager.messageTime)) / (manager.avgWorkingHours * 3600 * numberOfDays)) * 100).toFixed(1)),
                meetingsZone: customerProfile?.meetingsZone ? customerProfile.meetingsZone : null,
                callsZone: customerProfile?.callsZone ? customerProfile.callsZone : null,
                focusedZone: customerProfile ? customerProfile.focusedZone : null,
                expectedMeetingsPercentage: customerProfile ? customerProfile.percentageTimeInMeetings : 0,
                expectedCallsPercentage: customerProfile ? customerProfile.percentageTimeInCallsAndMessages : 0,
                expectedFocusedPercentage: customerProfile ? customerProfile.percentageTimeInFocus : 0,
                dviNeutralVal: neutralSentimentUsers.length,//Number(manager.neutralSentmentScore * 100),
                dviPositiveVal: positiveSentimentUsers.length,//Number(manager.positiveSentmentScore * 100),
                dviNegativeVal: negativeSentimentUsers.length,//Number(manager.negetiveSentmentScore * 100),
                categoryTag: categoryData.filter(tag => tag.isSelected === 1).map(tag => {
                    let filteredData = userWellbeingScore.filter(v => v.questionCategoryName === tag.questionCategoryName && v.isSelected === 1);
                    let score = filteredData.length > 0
                        ? Math.ceil(filteredData.reduce((sum, item) => sum + item.score, 0) / filteredData.length)
                        : 0;
                    return {
                        label: tag.questionCategoryName,
                        val: score
                    }
                })
                    .filter((item, index, self) =>
                        index === self.findIndex(t => t.label === item.label && t.val === item.val)
                    ),
                lvTypeMeeting: 'Negative',
                lvTypeMessages: 'Neutral',
                lvTypeFocused: 'Positive',
                topSentimentUsers: top5keyValuePairs,
                negativeSentimentUsersList: negativeSentimentUsersList,
                positiveSentimentUsersList: positiveSentimentUsersList,
                neutralSentimentUsersList: neutralSentimentUsersList,
                positiveSentimentCount: positiveSentimentUsers.length,
                neutralSentimentCount: neutralSentimentUsers.length
            }
            transformedData.managerData = managerData;
        } else {
            transformedData.managerData = null;
        }

        transformedData.userData = apiData.userDetails.map(user => {
            customerProfile = customerProfileMap[user.customerProfileId];
            let userSentimentsArray = Object.values(user.userSentiments);
            let positiveSentimentUsers = userSentimentsArray.filter(user => user.sentiment > 0);
            let negativeSentimentUsers = userSentimentsArray.filter(user => user.sentiment < 0);
            let neutralSentimentUsers = userSentimentsArray.filter(user => user.sentiment == 0);
            let negativeSentimentUserGUIDs = Object.keys(user.userSentiments)
                .filter(key => user.userSentiments[key].sentiment < 0) // Filter only negative sentiments
                .sort((a, b) => user.userSentiments[b].sentiment - user.userSentiments[a].sentiment) // Sort by sentiment
            //.slice(0, 5);
            let positiveSentimentUserGUIDs = Object.keys(user.userSentiments)
                .filter(key => user.userSentiments[key].sentiment > 0) // Filter only positive sentiments
                .sort((a, b) => user.userSentiments[b].sentiment - user.userSentiments[a].sentiment) // Sort by sentiment
            let neutralSentimentUserGUIDs = Object.keys(user.userSentiments)
                .filter(key => user.userSentiments[key].sentiment === 0) // Filter only neutral sentiments
                .sort((a, b) => user.userSentiments[b].sentiment - user.userSentiments[a].sentiment) // Sort by sentiment
            let top5negativeSentimentUserGUIDs = negativeSentimentUserGUIDs.slice(0, 5);
            let top5keyValuePairs = top5negativeSentimentUserGUIDs.map(key => ({
                key: key,
                value: user.userSentiments[key]
            }));
            let negativeSentimentUsersList = negativeSentimentUserGUIDs.map(key => ({
                key: key,
                value: user.userSentiments[key]
            }));
            let positiveSentimentUsersList = positiveSentimentUserGUIDs.map(key => ({
                key: key,
                value: user.userSentiments[key]
            }));
            let neutralSentimentUsersList = neutralSentimentUserGUIDs.map(key => ({
                key: key,
                value: user.userSentiments[key]
            }));
            let remainingUserIds = Object.keys(user.userSentiments).filter(user => !top5negativeSentimentUserGUIDs.includes(user));
            let remainingkeyValuePairs = remainingUserIds.map(key => ({
                key: key,
                value: user.userSentiments[key]
            }));
            let categoryTotal = 0, categoryTotalOld = 0;
            let numberOfCategories = categoryData.filter(tag => tag.isSelected === 1).length;
           
            return {
                vibe: 'neutral',
                userName: user.displayName,
                userGUID: user.userGUID,
                userDesignation: user.jobTitle,
                userEmail: user.mail,
                userImage: user.userImage,
                reporteeCount: user.reporteeCount,
                userStatus: 'success',
                manager: user.managerDisplayName,
                managerGUID: user.managerGUID,
                managerDesignation: user.managerDesignation,
                managerStatus: 'success',
                managerImage: user.managerPhoto,
                progressType: 'productive-hours',
                meetingsPercentage: Number(user.meetingTime),//Number(((user.meetingTime / avgWorkingHoursInSeconds) * 100).toFixed(1)),
                callsPercentage: Number(user.callTime),//Number((((user.messageTime + user.callTime) / avgWorkingHoursInSeconds) * 100).toFixed(1)),
                focusedPercentage: Number(100 - Number(user.meetingTime + user.callTime)),//Number((((avgWorkingHoursInSeconds - (user.meetingTime + user.callTime + user.messageTime)) / avgWorkingHoursInSeconds) * 100).toFixed(1)),
                meetingsZone: customerProfile?.meetingsZone ? customerProfile.meetingsZone : null,
                callsZone: customerProfile?.callsZone ? customerProfile.callsZone : null,
                focusedZone: customerProfile ? customerProfile.focusedZone : null,
                expectedMeetingsPercentage: customerProfile ? customerProfile.percentageTimeInMeetings : 0,
                expectedCallsPercentage: customerProfile ? customerProfile.percentageTimeInCallsAndMessages : 0,
                expectedFocusedPercentage: customerProfile ? customerProfile.percentageTimeInFocus : 0,
                dviNeutralVal: neutralSentimentUsers.length,
                dviPositiveVal: positiveSentimentUsers.length,
                dviNegativeVal: negativeSentimentUsers.length,
                categoryTag: categoryData.filter(tag => tag.isSelected === 1).map(tag => {
                    categoryTotal += user.wellbeingScores[tag.id] != null ? user.wellbeingScores[tag.id] : 0;
                    categoryTotalOld += user.wellbeingOldScores[tag.id] != null ? user.wellbeingOldScores[tag.id] : 0;
                    return {
                        desc: tag.questionCategoryDesc,
                        label: tag.questionCategoryName,
                        val: user.wellbeingScores != null && user.wellbeingScores[tag.id] != null ? Math.ceil(user.wellbeingScores[tag.id]) : 0

                    }
                })
                    .filter((item, index, self) =>
                        index === self.findIndex(t => t.label === item.label && t.val === item.val)
                    ),// remove any duplicates
                employeeVibe: numberOfCategories === 0 ? 0 : (categoryTotal / numberOfCategories),
                employeeVibeOld: numberOfCategories === 0 ? 0 : (categoryTotalOld / numberOfCategories),
                lvTypeMeeting: 'Neutral',
                lvTypeMessages: 'Neutral',
                lvTypeFocused: 'Neutral',
                topSentimentUsers: top5keyValuePairs,
                negativeSentimentUsersList: negativeSentimentUsersList,
                positiveSentimentUsersList: positiveSentimentUsersList,
                neutralSentimentUsersList: neutralSentimentUsersList,
                otherSentimentUsers: remainingkeyValuePairs,
                positiveSentimentCount: positiveSentimentUsers.length,
                neutralSentimentCount: neutralSentimentUsers.length,

                sentimentAvg: userSentimentsArray.length === 0 ? 0 : userSentimentsArray.reduce((sum, user) => {
                    return sum + (user.sentiment || 0); // Add user.sentiment or 0 if undefined
                }, 0) / userSentimentsArray.length,
                userCalenderDetails: user.userCalenderDetails,

               
            };
        });
        transformedData.teamStats = apiData.teamStats;
        return transformedData;
    };
    function fnFormatTime(minutes) {
        if (minutes > 0) {
            const totalSeconds = Math.floor(minutes * 60);
            const hours = Math.floor(totalSeconds / 3600);
            const remainingSeconds = totalSeconds % 3600;
            const mins = Math.floor(remainingSeconds / 60);
            const secs = remainingSeconds % 60;

            // Format the result as hh:mm:ss
            return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
        }
        else
            return '00:00:00';
    }
    function fnFillTeamData(data) {

        let numberOfEmployees = data.userData.length;
        let teamStats = data.teamStats;

        let vibeSum = data.userData.reduce((sum, user) => {
            return sum + (user.employeeVibe || 0); // Add user.employeeVibe or 0 if undefined
        }, 0);
        let teamVibe = numberOfEmployees === 0 ? 0 : ((vibeSum / numberOfEmployees) * 100) / 5;//(_wellbeingCategories.filter(tag => tag.isSelected === 1).length * 5)

        let vibeSumOld = data.userData.reduce((sum, user) => {
            return sum + (user.employeeVibeOld || 0); // Add user.employeeVibeOld or 0 if undefined
        }, 0);
        let teamVibeDiff = numberOfEmployees === 0 ? 0 : (((vibeSumOld - vibeSum) / numberOfEmployees) * 100) / 5;//(_wellbeingCategories.filter(tag => tag.isSelected === 1).length * 5)


        let sentimentSum = data.userData.reduce((sum, user) => {
            return sum + (user.sentimentAvg || 0); // Add user.employeeVibe or 0 if undefined
        }, 0);
        let teamSentiment = numberOfEmployees === 0 ? 0 : ((sentimentSum / numberOfEmployees) * 100);//(_wellbeingCategories.filter(tag => tag.isSelected === 1).length * 5)
        
        let teamData = ({
            teamVibe: Math.floor(teamVibe * 100) / 100,
            vibeDiff: Number((Math.floor(teamVibeDiff * 100) / 100)),
            teamSentimentVal: Math.abs(Math.floor(teamSentiment * 100) / 100),
            teamSentiment: teamSentiment > 0 ? "positive" : "negative",
            teamCallsPercentage: teamStats.teamTotalWorkingHours === 0 ? 0 : Math.abs(Math.floor((teamStats.teamTotalCallTime / teamStats.teamTotalWorkingHours) * 100) / 100),
            teamMeetingsPercentage: teamStats.teamTotalWorkingHours === 0 ? 0 : Math.abs(Math.floor((teamStats.teamTotalMeetingTime / teamStats.teamTotalWorkingHours) * 100) / 100),
            teamMsgPercentage: teamStats.teamTotalWorkingHours === 0 ? 0 : Math.abs(Math.floor((teamStats.teamTotalMsgTime / teamStats.teamTotalWorkingHours) * 100) / 100),
            teamCallsExternal: fnFormatTime(teamStats.teamCallTimeExternal),
            teamMeetingsExternal: fnFormatTime(teamStats.teamMeetingTimeExternal),
            teamMsgTimeExternal: fnFormatTime(teamStats.teamMsgTimeExternal),
            teamMsgExternal: Math.floor(teamStats.teamMsgTimeExternal*12),//as it was msgCount*5/60
            teamCallsInternal: fnFormatTime(teamStats.teamTotalCallTime - teamStats.teamCallTimeExternal),
            teamMeetingsInternal: fnFormatTime(teamStats.teamTotalMeetingTime - teamStats.teamMeetingTimeExternal),
            teamMsgTimeInternal: fnFormatTime(teamStats.teamTotalMsgTime - teamStats.teamMsgTimeExternal),
            teamMsgInternal: Math.floor((teamStats.teamTotalMsgTime - teamStats.teamMsgTimeExternal)*12),
            teamCallsExternalPercentage: teamStats.teamTotalCallTime === 0 ? 0 : Math.abs(Math.floor((teamStats.teamCallTimeExternal / teamStats.teamTotalCallTime) * 100) / 100),
            teamMeetingsExternalPercentage: teamStats.teamTotalMeetingTime === 0 ? 0 : Math.abs(Math.floor((teamStats.teamMeetingTimeExternal / teamStats.teamTotalMeetingTime) * 100) / 100),
            teamMsgExternalPercentage: teamStats.teamTotalMsgTime === 0 ? 0 : Math.abs(Math.floor((teamStats.teamMsgTimeExternal / teamStats.teamTotalMsgTime) * 100) / 100),
            teamCallsInternalPercentage: teamStats.teamTotalCallTime === 0 ? 0 : Math.abs(Math.floor(((teamStats.teamTotalCallTime - teamStats.teamCallTimeExternal) / teamStats.teamTotalCallTime) * 100) / 100),
            teamMeetingsInternalPercentage: teamStats.teamTotalMeetingTime === 0 ? 0 : Math.abs(Math.floor(((teamStats.teamTotalMeetingTime - teamStats.teamMeetingTimeExternal) / teamStats.teamTotalMeetingTime) * 100) / 100),
            teamMsgInternalPercentage: teamStats.teamTotalMsgTime === 0 ? 0 : Math.abs(Math.floor(((teamStats.teamTotalMsgTime - teamStats.teamMsgTimeExternal) / teamStats.teamTotalMsgTime) * 100) / 100),
        });
        return teamData;
    }
    const loadTeamInsightsMasterData = () => {
        AuthHelper.getAccessToken(function (token) {
            DashboardApiService.GetTeamInsightsMasterData(token).then(
                (response) => {
                    if (response && response.status == 200) {
                        setViewUserGuid(response.data.loggedInUserGuid);
                        setLoggedInUserGuid(response.data.loggedInUserGuid);
                        setIsLoggedInUserManager(response.data.isLoggedInUserManager);
                        setTeamView(response.data.isLoggedInUserManager ? true : false);
                        if (response.data.networkUsers !== "") {
                            const myPeopleDropdownValues = response.data.networkUsers.map(user => ({
                                id: user.userGUID,
                                key: user.userGUID,
                                header: user.displayName,
                                image: user.isManager ? '../../images/isManager.png' : '../../images/isUser.png',
                                content: 'Role',
                                ismanager: user.isManager.toString().toLowerCase()
                            }));
                            setMyNetworkUsers(myPeopleDropdownValues);
                        } else {
                            const defaultEntry = [{
                                id: 1,
                                key: 'no-network',
                                header: 'No Network Below You',
                                disabled: true
                            }];

                            setMyNetworkUsers(defaultEntry);
                        }
                        if (response.data.wellBeingCategories !== "") {
                            setWellbeingCategories(response.data.wellBeingCategories.filter(f => f.isSelected === 1));
                        }
                        if (response.data.wellBeingScoreDescriptions !== "") {
                            setWellBeingScoreDescriptions(response.data.wellBeingScoreDescriptions);
                        }
                        props.viewStatus.setPageStatus(200);
                    }
                    else if (response && response.status == 401) {
                        props.viewStatus.setPageStatus(401);
                        //alert('Error in fetching data');
                    }
                    else {
                        props.viewStatus.setPageStatus(500);
                    }
                    setLoading(false);
                    fetchTeamInsightData(filterData, response.data.wellBeingCategories, null, null);
                }
            );
        });
    };

    useEffect(() => {
        props.viewStatus.setPageStatus(null);
        props.viewStatus.InformChildPageStatus(null);
        loadTeamInsightsMasterData();
    }, []);

    const fnUpdateUserToView = (userGuid, isTeamView) => {
        setTeamView(isTeamView);
        setViewUserGuid(userGuid);
        if (isTeamView) {
            //fetchTeamInsightData(filterData, wellbeingCategories, userGuid, userGuid);
            fetchTeamInsightData(filterData, wellbeingCategories, userGuid, null);
        }
        else {
            fetchTeamInsightData(filterData, wellbeingCategories, null, userGuid);
        }
    }

    const fnHandleFilterUpdate = (_filterData) => {
        setFilterData(_filterData);
        fetchTeamInsightData(_filterData, wellbeingCategories, isTeamView ? viewUserGuid : null, isTeamView ? null : viewUserGuid)
        // fetchTeamInsightData(_filterData, wellbeingCategories, viewUserGuid, viewUserGuid)
    }

    const fetchTeamInsightData = (_filterData, _wellbeingCategories, managerId = null, userGUID = null) => {
        setChildViewLoading(true);
        let APIdata = {
            StartTime: _filterData.startDate,
            EndTime: _filterData.endDate,
            TimeCategory: _filterData.timeCategory,
            ManagerId: managerId,
            UserGUID: userGUID
        };

        AuthHelper.getAccessToken((token) => {
            DashboardApiService.GetTeamInsightsData(token, APIdata).then((response) => {
                if (response && response.status == 200) {
                    let transformedData = transformData(response.data, _wellbeingCategories);
                    let teamData = fnFillTeamData(transformedData);

                    setInsightData({
                        managerData: transformedData.managerData,
                        userData: transformedData.userData,
                        teamData: teamData
                    });
                    props.viewStatus.setPageStatus(200);
                } else if (response && response.status == 401) {
                    props.viewStatus.setPageStatus(401);
                    //alert('Error in fetching data');
                }
                else {
                    props.viewStatus.setPageStatus(500);
                }
                setChildViewLoading(false);
            });
        });
    }


    return (
        <WindowContext.Consumer>
            {(context) => (
                <SignUpConsumer>
                    {
                        (tenantStatus) => (
                            (loading) ? <Loader label="Loading..." /> :
                                props.viewStatus.pageStatus == 401 ? <Error403 /> :
                                    props.viewStatus.pageStatus == 500 ? <Error500 /> :
                                        <>
                                            <Flex className="mb-2 mx-2 pt-2" gap="gap.small" hAlign="center">
                                                <FlexItem>
                                                    <Dropdown
                                                        className='insights-dropdown-list-cst'
                                                        items={myNetworkUsers}
                                                        placeholder="Select People"
                                                        fluid
                                                        value={myNetworkUsers.find(f => f.key === viewUserGuid)}
                                                        label="My People"
                                                        onChange={(e, { value }) => {
                                                            fnUpdateUserToView(value.key, value.ismanager === "true")
                                                        }}
                                                    />
                                                </FlexItem>
                                                {
                                                      isLoggedInUserManager &&  !isTeamView && <Button
                                                    icon={<ArrowLeftIcon />}
                                                    title="Back to team view"
                                                    content={context.mediaCategory.sm ? "" : "Team View"}
                                                    iconOnly={context.mediaCategory.sm}
                                                    onClick={() => fnUpdateUserToView(loggedInUserGuid,true) }
                                                />}
                                                <Text className="text-view" content={isTeamView ? "Team View" : "User View"} />
                                                <FlexItem push>
                                                    <Flex gap="gap.small">
                                                        <FilterPopup
                                                            {...filterData}
                                                            fnHandleFilterUpdate={fnHandleFilterUpdate}
                                                            myNetworkUsers={myNetworkUsers} />
                                                        <Tooltip
                                                            trigger={
                                                                <Button
                                                                    id="introViewDashList"
                                                                    className="ml-auto"
                                                                    icon={<BulletsIcon />}
                                                                    iconOnly
                                                                    onClick={() => handleNavigationClick("", isDemo)}
                                                                    primary
                                                                />
                                                            }
                                                            content="View My Dashboards"
                                                        />
                                                        <Tooltip
                                                            trigger={
                                                                <a href={`${process.env.REACT_APP_HELP_LINK}`} target="_blank">
                                                                    <Button
                                                                        id="helpDashboard"
                                                                        icon={<QuestionCircleIcon />}
                                                                        iconOnly
                                                                        primary
                                                                    /></a>
                                                            }
                                                            content="Help"
                                                        />
                                                    </Flex>
                                                </FlexItem>
                                            </Flex>
                                            {
                                                loading ? <></> :
                                                    childViewLoading ? <Loader /> :

                                                        <MyInsights loading={childViewLoading} fnUpdateUserToView={fnUpdateUserToView} insightData={insightData} wellBeingScoreDescriptionList={wellBeingScoreDescriptions} isTeamView={isTeamView} filterData={filterData } />
                                            }
                                        </>
                        )
                    }
                </SignUpConsumer>
            )}
        </WindowContext.Consumer>
    );
};

export default TeamInsights;