import * as React from "react";
import { Chart, ChartTooltip, ChartTitle, ChartLegend, ChartSeries, ChartSeriesItem, ChartXAxis, ChartXAxisItem, ChartYAxis, ChartYAxisItem, ChartArea } from '@progress/kendo-react-charts';
import "hammerjs";
import "./ManagementInsights.scss";
import { Avatar, Tooltip, Popup, Box } from "@fluentui/react-northstar";
import { Image, Circle, Surface, Path, Text, Group, geometry, align, vAlign } from "@progress/kendo-drawing";
import WindowContext from "../../Shared/Context/Context";


const CellWithUserList = (props) => {
  const data = props.data;
  return (
    <WindowContext.Consumer>
      {(context) => (
        <td style={{ paddingLeft: "5px", paddingRight: "5px" }}>
          {data.length > (context.mediaCategory.sm ? 5 : 10) ?
            <>
              {
                data.slice(0, (context.mediaCategory.sm ? 5 : 10)).map(user => {
                  return <Tooltip
                    content={
                      <div>
                        <p className="pTooltipContent">{user.name}</p>
                        <p className="pTooltipContent">Co-Attended : {user.coAttendancePer}%</p>
                        <p className="pTooltipContent">One-to-One : {user.oneToOneDurationPerDay} mins/day</p>
                      </div>
                    }
                    //open={true}
                    trigger={<Avatar name={user.name} image={user.image} className="ui-avt-shadow" />}
                  />
                })
              }
              <Popup
                trigger={<Avatar className="ui-avt-shadow" label={"+" + (data.length - (context.mediaCategory.sm ? 5 : 10))} />}
                on="hover"
                pointing
                content={
                  <div style={{ maxWidth: "250px" }}>
                    {
                      data.slice((context.mediaCategory.sm ? 5 : 10)).map(user => {
                        return <Tooltip
                          content={
                            <div>
                              <p className="pTooltipContent">{user.name}</p>
                              <p className="pTooltipContent">Co-Attended : {user.coAttendancePer}%</p>
                              <p className="pTooltipContent">One-to-One : {user.oneToOneDurationPerDay} mins/day</p>
                            </div>
                          }
                          trigger={<Avatar name={user.name} image={user.image} className="ui-avt-shadow" />}
                        />
                      })
                    }
                  </div>
                }
              />
            </>
            :
            data.map(user => {
              return <Tooltip
                content={
                  <div>
                    <p className="pTooltipContent">{user.name}</p>
                    <p className="pTooltipContent">Co-Attended : {user.coAttendancePer}%</p>
                    <p className="pTooltipContent">One-to-One : {user.oneToOneDurationPerDay} mins/day</p>
                  </div>
                }
                trigger={<Avatar name={user.name} image={user.image} className="ui-avt-shadow" />}
              />
            })
          }
        </td>
      )}
    </WindowContext.Consumer>
  )
}

const ManagementInsights = ({ data }) => {
  const halfAxis = parseInt(data.maxYaxis / 2);
  return (
    <>
      <div className="card-body p-0">
        <div className="activity-view">
          <div className="usr-view">
            <div className="usr-vDtl">
              <div className="row">
                <div className="col-md-12 pr-2 pl-2">
                  <div className="tileTraks h-100 pl-3 pb-1">
                    <div className="vDtl-inr h-100 manage-ins">
                      <div className="manage-ins-axis-x">
                        <span></span>
                        <span style={{ paddingLeft: "40px" }}>50%</span>
                        <span>100%</span>
                        <div style={{ position: "absolute", top: "15px" }} className="col text-nowrap text-center text-secondary">
                          <span>Co-attended Meetings/Calls</span>
                        </div>
                      </div>
                      <div className="manage-ins-axis-y">
                        <span style={{ transform: 'rotate(90deg)' }}>0</span>
                        <span style={{ transform: 'rotate(90deg)' }}>{halfAxis}</span>
                        <span style={{ transform: 'rotate(90deg)' }}>{data.maxYaxis}</span>
                        <div style={{ position: "absolute", top: "-18px" }} className="col text-nowrap text-center text-secondary">
                          <span>One-to-One Time <span style={{ fontSize: "11px" }}>(mins/day)</span></span>
                        </div>
                      </div>
                      <div className="row h-50">
                        <div className="col-6 pr-0 d-flex align-items-center justify-content-center">
                          <div className="vDtlRow" style={{ backgroundColor: "#8B8CC7" }}>
                            <div className="vDtlRowTrk d-block">
                              <div className="vDtlRowTrk-p my-2 text-white">High One-to-One</div>
                              <div className="tblUsr moreUsr users-small mb-2">
                                <CellWithUserList data={data.managementDetails.filter(e => e.oneToOneDurationPerDay >= halfAxis && e.coAttendancePer < 50)} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 d-flex align-items-center justify-content-center" style={{ paddingLeft: "0px" }}>
                          <div className="vDtlRow" style={{ backgroundColor: "#464775" }}>
                            <div className="vDtlRowTrk d-block">
                              <div className="vDtlRowTrk-p my-2 text-white">High Interaction</div>
                              <div className="tblUsr moreUsr users-small mb-2">
                                <CellWithUserList data={data.managementDetails.filter(e => e.oneToOneDurationPerDay >= halfAxis && e.coAttendancePer >= 50)} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row h-50">
                        <div className="col-6 pr-0 d-flex align-items-center justify-content-center">
                          <div className="vDtlRow" style={{ backgroundColor: "#E2E2F6" }}>
                            <div className="vDtlRowTrk d-block">
                              <div className="vDtlRowTrk-p my-2 text-black">Low Interaction</div>
                              <div className="tblUsr moreUsr users-small mb-2">
                                <CellWithUserList data={data.managementDetails.filter(e => e.oneToOneDurationPerDay < halfAxis && e.coAttendancePer < 50)} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 d-flex align-items-center justify-content-center" style={{ paddingLeft: "0px" }}>
                          <div className="vDtlRow" style={{ backgroundColor: "#8B8CC7" }}>
                            <div className="vDtlRowTrk d-block">
                              <div className="vDtlRowTrk-p my-2 text-white">High Co-attended</div>
                              <div className="tblUsr moreUsr users-small mb-2">
                                <CellWithUserList data={data.managementDetails.filter(e => e.oneToOneDurationPerDay < halfAxis && e.coAttendancePer >= 50)} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}



export default ManagementInsights;