import * as React from 'react';
import './BasicSummary.scss';
import { CircleIcon } from '@fluentui/react-icons-northstar';

const SentimentIcon = ({ sentimentScore }) => {
    let sc = sentimentScore;
    let className = "st_good";
    switch (sentimentScore) {
        case "Negative":
            className="st_poor";
            break;
        case "Neutral":
            className="st_average";
            break;
        case "Positive":
            className="st_good";
            break;
        default:
            className = "st_good";
            sc = "Good";
    }
    return (
        <span>
            <CircleIcon className={className} /> {sc}
        </span>
    );
}

const BasicSummary = ({data}) => { 
    return (  
        <>   
        {
            data &&
            <div className="activity-view">
            <div className="smrL-label">
                    <div className="mainLabelRow">
                        <div className="mainLabel">
                            <div className="mainLabel-p">Posts</div>
                            <div className="mainLabel-val">{data.posted}</div>
                        </div>
                    </div>
                    <div className="mainLabelRow">
                        <div className="mainLabel">
                            <div className="mainLabel-p">Replies</div>
                            <div className="mainLabel-val">{data.replied}</div>
                        </div>
                    </div>
                    <div className="mainLabelRow">
                        <div className="mainLabel">
                            <div className="mainLabel-p">Mentions</div>
                            <div className="mainLabel-val">{data.mentioned}</div>
                        </div>
                    </div>
                    <div className="mainLabelRow">
                        <div className="mainLabel">
                            <div className="mainLabel-p">Reactions</div>
                            <div className="mainLabel-val">{data.reactions}</div>
                        </div>
                    </div>
                    <div className="mainLabelRow">
                        <div className="mainLabel">
                            <div className="mainLabel-p">Avg. Sentiment</div>
                            <div className="mainLabel-val lblValTxt">
                                <SentimentIcon sentimentScore={data.averageSentiment} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
    );
}

export default BasicSummary;