import { useEffect, useState, useContext } from "react";
import { Route, Redirect, useLocation, useHistory } from "react-router-dom";
import AuthHelper from "../services/auth-helper";
import TenantApiService from "../services/TenantApiService";
import { Loader } from "@fluentui/react-northstar";
import { SignUpProvider } from "./Shared/Context/SignUpContext";
import Impersonation from "../components/pages/Configuration/Impersonation";
import DashboardApiService from "../services/DashboardApiService";
import WindowContext from "./Shared/Context/Context";

// const renderRedirect = () => {
//   storeCurrentPath();

//   return <Redirect to={{ pathname: "/login", state: { from: location } }} />;
// };

const AuthenticatedRoute = ({ component: Component, viewStatus, ...rest }) => {
    const _location = useLocation();
    const _history = useHistory();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isAnalytics, setIsAnalytics] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [tenantStatus, setTenantStatus] = useState(100);
    const [subscriptionStatus, setSubscriptionStatus] = useState(-1);
    const [customerStatus, setCustomerStatus] = useState(0);
    const [isDeleted, setIsDeleted] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isFirstLogin, setIsFirstLogin] = useState(false);
    const [demoModeVisible, setDemoModeVisible] = useState(false);
    const [globalDemoModeOverride, setGlobalDemoModeOverride] = useState(false);
    const [isReporting, setIsReporting] = useState(0);
    const [isUpdatesSeen, setIsUpdatesSeen] = useState(1);
    const [demoDashboardJourneyState, setDemoDashboardJourneyState] = useState(2);
    const [dashboardJourneyState, setDashboardJourneyState] = useState(2);

    // Access the context
    const _context = useContext(WindowContext);


    const authSuccessCallback = (token) => {
        if (token) {
            setIsAuthenticated(true);
        }
        TenantApiService.GetAccountPermission(token).then((response) => {
            if (response) {
                setTenantStatus(response.tenantStatus);
                //response.subscriptionStatus = 4
                setSubscriptionStatus(response.subscriptionStatus);
                setCustomerStatus(response.customerStatus);
                if (response.tenantStatus >= 4) {
                    setIsAdmin((response.administrativeAccess === 1 || response.administrativeAccess === 2
                        || (response.administrativeAccess === 0 && (response.reportingAccess === 1 || response.reportingAccessInherited === 1))
                        || response.administrativeAccessInherited === 1 || response.administrativeAccessInherited === 2
                        || (response.administrativeAccessInherited === 0 && (response.reportingAccess === 1 || response.reportingAccessInherited === 1))));
                    setIsAnalytics((response.reportingAccess === 1 || response.reportingAccessInherited === 1));
                    setIsDeleted(response.isDeleted);
                    setDemoModeVisible(response.demoModeVisible);
                    setIsFirstLogin(response.isFirstTimeLogin);
                    setIsReporting(response.reportingAccessInherited === 1 ? response.reportingAccessInherited : response.reportingAccess);
                    setIsUpdatesSeen(response.isUpdatesSeen);
                    setDemoDashboardJourneyState(response.demoDashboardJourneyState);
                    setDashboardJourneyState(response.dashboardJourneyState);
                    _context.setLoadExternalTeamsCallCards(response.loadExternalTeamsCallCards);
                    _context.journeyState.setlatestDashboardJourneyState(response.dashboardJourneyState);
                    _context.journeyState.setlatestDemoDashboardJourneyState(response.demoDashboardJourneyState);
                }
            }


            let demo = global.localStorage.getItem("demoGlobal");
            if (demo === "true") {
                setGlobalDemoModeOverride(true);
            }
            else {
                setGlobalDemoModeOverride(false);
            }

            setIsLoaded(true);
        });
    };
    
    useEffect(() => {
        let demo = global.localStorage.getItem("demoGlobal");
        if (demo === "true" && _location.pathname.includes("dashboards")) {
            Impersonation.RemoveImpersonation();
        }
        global.localStorage.removeItem("isCustomerNotRegistered");
        AuthHelper.getAccessToken(authSuccessCallback);
    }, []);

    return (
        <>
            <WindowContext.Consumer>
                {(context) =>
                    <SignUpProvider value={tenantStatus}>
                        {isLoaded ?
                            (
                                <Route
                                    {...rest}
                                    render={(props) => {
                                        
                                        return isAuthenticated ?
                                            (
                                                (
                                                    customerStatus === 1
                                                    && !isDeleted
                                                    && (tenantStatus === 4 || tenantStatus === 5)
                                                    && [0, 1, 3, 6, 10].includes(subscriptionStatus)
                                                    && (
                                                        (!isFirstLogin && isUpdatesSeen === 1 && isAnalytics && props.location.pathname.includes("dashboards")/* && (dashboardJourneyState > 0 || context.journeyState.latestDashboardJourneyState > 0)*/)
                                                        || (isAdmin && props.location.pathname.includes("configuration"))
                                                    )
                                                )
                                                ||
                                                (
                                                    globalDemoModeOverride
                                                    //!globalDemoModeOverride ?
                                                    //    isAnalytics &&
                                                    //    (
                                                    //        props.location.pathname.includes("configuration") ||
                                                    //        (dashboardJourneyState === 0 && props.location.pathname.includes("dashboards/view")) ||
                                                    //        dashboardJourneyState > 0 ||
                                                    //        context.journeyState.latestDashboardJourneyState > 0
                                                    //    )
                                                    //    :
                                                    //    tenantStatus >= 4 && tenantStatus !== 100 ? (
                                                    //        props.location.pathname.includes("configuration") || 
                                                    //        (props.location.pathname.includes("dashboards")
                                                    //            && (
                                                    //                (!isFirstLogin && dashboardJourneyState > 0 && isAnalytics)
                                                    //                || (!isFirstLogin && demoDashboardJourneyState === 0 && props.location.pathname.includes("dashboards/view"))
                                                    //                || context.journeyState.latestDashboardJourneyState > 0
                                                    //            || (demoDashboardJourneyState > 0 && (!isAnalytics || (isAnalytics  && (dashboardJourneyState !== 0 || context.journeyState.latestDashboardJourneyState !== 0))))
                                                    //            )  //&& (tenantStatus === 4 || tenantStatus === 5) && [0, 6].includes(subscriptionStatus)
                                                    //            && (
                                                    //            context.journeyState.latestDemoDashboardJourneyState > 0 ||
                                                    //            demoDashboardJourneyState > 0 ||
                                                    //            (demoDashboardJourneyState === 0 && props.location.pathname.includes("dashboards/view"))
                                                    //            ))
                                                    //    ) : globalDemoModeOverride
                                                )
                                            ) ?
                                                <Component demoModeVisible={demoModeVisible} viewStatus={viewStatus} {...props} /> :
                                                /*((subscriptionStatus === 0 || subscriptionStatus === 6) && (isFirstLogin === true && isReporting === 1)) ?*/
                                                /*<Redirect to={{ pathname: '/appoverview', state: { from: _location} }} /> : */
                                                <Redirect to={{ pathname: "/provision", state: { from: _location, tenantStatus: tenantStatus, subscriptionStatus: subscriptionStatus, customerStatus: customerStatus, isFirstLogin: isFirstLogin, isDeleted: isDeleted, isReporting: isReporting, isUpdatesSeen: isUpdatesSeen, demoDashboardJourneyState: demoDashboardJourneyState, dashboardJourneyState: dashboardJourneyState } }} />
                                            :
                                            <Redirect to={{ pathname: "/login", state: { from: _location } }} />

                                    }

                                    }
                                ></Route>
                            ) : <Loader />
                        }
                    </SignUpProvider>
                }
            </WindowContext.Consumer>

        </>
    );
};

export default AuthenticatedRoute;
