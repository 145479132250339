import React, { useEffect, useState } from 'react';
import CircleChart from '../../Shared/uicomponents/circleChart';
import './UserComparisonView.scss';
import WindowContext from '../../Shared/Context/Context';


const UserComparisonView = ({ data }) => {

    return (
        <WindowContext>{
            (context) => (
                <div className="compareGraph">
                    <div className="compareTrk compareData">
                        <div className="compareDataInr">
                            <div className="compareDataInrRow compareDataInrRow-new tmRowTitle">
                                <div className="compareDataInrRow-val compareDataInrRow-pad compareDataInrRow-p-new">
                                    <div className="topUsrAreaPic">
                                        <div className="topUsrAreaPic-i">
                                            <div className={context.mediaCategory.sm ? "contact-list-icon contact-list-icon-size" : "contact-list-icon"}>
                                                <div className="contact-list-icon-txt">
                                                    <div className="contact-list-icon-img">
                                                        <img src={data.image} />
                                                    </div>
                                                    {data.image == "" ? data.initials1 : ""}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="compareDataInrRow-p compareDataInrRow-p-new"></div>
                                <div className="compareDataInrRow-val compareDataInrRow-pad compareDataInrRow-p-new">
                                    <div className="topUsrAreaPic">
                                        <div className="topUsrAreaPic-i">
                                            <div className={context.mediaCategory.sm ? "contact-list-icon contact-list-icon-size" : "contact-list-icon"}>
                                                <div className="contact-list-icon-txt">
                                                    <div className="contact-list-icon-img">
                                                        <img src={data.image1} />
                                                    </div>
                                                    {data.image1 == "" ? data.initials2 : ""}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="compareDataInrRow compareDataInrRow-new h-3r">
                                <div className="compareDataInrRow-val compareDataInrRow-pad d-flex flex-column">
                                    <div className="py-1">{data.totalTeamMemberships}</div>
                                    <div className="topUsrAreaDate">
                                        <div className="topUsrAreaDateTrk">
                                            <div className="topUsrAreaDateTrk-p text-nowrap">Active Channels <b>{data.activeChannels}</b></div>
                                            <div className="topUsrAreaDateTrk-p text-nowrap">Team Owner <b>{data.totalOwners}</b></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="compareDataInrRow-p compareDataInrRow-p-new">Team Memberships</div>
                                <div className="compareDataInrRow-val compareDataInrRow-pad d-flex flex-column">
                                    <div className="py-1">{data.totalTeamMemberships1}</div>
                                    <div className="topUsrAreaDate">
                                        <div className="topUsrAreaDateTrk">
                                            <div className="topUsrAreaDateTrk-p text-nowrap">Active Channels <b>{data.activeChannels1}</b></div>
                                            <div className="topUsrAreaDateTrk-p text-nowrap">Team Owner <b>{data.totalOwners1}</b></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="compareDataInrRow compareDataInrRow-new">
                                <div className={context.mediaCategory.sm ? "d-flex flex-column align-items-end compareDataInrRow-val compareDataInrRow-pad" : "d-flex justify-content-around compareDataInrRow-val compareDataInrRow-pad text-right"}>
                                    <div className={context.mediaCategory.sm ? "text-right pb-2" : ""}>
                                        <div className={context.mediaCategory.sm ? "topUsrAreaDateTrk d-flex align-items-center justify-content-end" : "topUsrAreaDateTrk pb-2"}>
                                            <div className={context.mediaCategory.sm ? "topUsrAreaDateTrk-p mr-2" : "topUsrAreaDateTrk-p"}>Meetings</div>
                                            <div className={context.mediaCategory.sm ? "lh-1" : ""} style={{ fontSize: "1.2em" }}>{data.meetingsTotal}</div>
                                        </div>
                                        <div className="topUsrAreaDate">
                                            <div className="topUsrAreaDateTrk">
                                                <div className="topUsrAreaDateTrk-p text-nowrap">External <b>{data.meetingsOrganizedExternal}%</b></div>
                                                <div className="topUsrAreaDateTrk-p text-nowrap">One-to-One <b>{data.meetingsOrganized1To1}%</b></div>
                                                <div className="topUsrAreaDateTrk-p text-nowrap">Organised <b>{data.meetingsByOrganizer}%</b></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={context.mediaCategory.sm ? "text-right" : ""}>
                                        <div className={context.mediaCategory.sm ? "topUsrAreaDateTrk d-flex align-items-center" : "topUsrAreaDateTrk pb-2"}>
                                            <div className={context.mediaCategory.sm ? "topUsrAreaDateTrk-p mr-2 text-nowrap" : "topUsrAreaDateTrk-p"}>Talk Time</div>
                                            <div className={context.mediaCategory.sm ? "lh-1" : ""} style={{ fontSize: "1.2em" }}>{data.timeInMeetings}</div>
                                        </div>
                                        <div className="topUsrAreaDate">
                                            <div className="topUsrAreaDateTrk">
                                                <div className="topUsrAreaDateTrk-p text-nowrap">Non-working hours <b>{data.meetingsOrganizedOOH}%</b></div>
                                                <div className="topUsrAreaDateTrk-p text-nowrap">Video On <b>{data.meetingsOrganizedVideo}%</b></div>
                                                <div className="topUsrAreaDateTrk-p text-nowrap">Recurring <b>{data.recurrence1}%</b></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="compareDataInrRow-p compareDataInrRow-p-new">Meetings</div>
                                <div className={context.mediaCategory.sm ? "d-flex flex-column align-items-start compareDataInrRow-val compareDataInrRow-pad" : "d-flex justify-content-around compareDataInrRow-val compareDataInrRow-pad text-right"}>
                                    <div className={context.mediaCategory.sm ? "text-left pb-2" : ""}>
                                        <div className={context.mediaCategory.sm ? "topUsrAreaDateTrk d-flex align-items-center" : "topUsrAreaDateTrk pb-2"}>
                                            <div className={context.mediaCategory.sm ? "topUsrAreaDateTrk-p mr-2" : "topUsrAreaDateTrk-p"}>Meetings</div>
                                            <div className={context.mediaCategory.sm ? "lh-1" : ""} style={{ fontSize: "1.2em" }}>{data.meetingsTotal1}</div>
                                        </div>
                                        <div className="topUsrAreaDate">
                                            <div className="topUsrAreaDateTrk">
                                                <div className="topUsrAreaDateTrk-p text-nowrap">External <b>{data.meetingsOrganizedExternal1}%</b></div>
                                                <div className="topUsrAreaDateTrk-p text-nowrap">One-to-One <b>{data.meetingsOrganized1To11}%</b></div>
                                                <div className="topUsrAreaDateTrk-p text-nowrap">Organised <b>{data.meetingsByOrganizer1}%</b></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={context.mediaCategory.sm ? "text-left" : ""}>
                                        <div className={context.mediaCategory.sm ? "topUsrAreaDateTrk d-flex align-items-center" : "topUsrAreaDateTrk pb-2"}>
                                            <div className={context.mediaCategory.sm ? "topUsrAreaDateTrk-p mr-2 text-nowrap" : "topUsrAreaDateTrk-p"}>Talk Time</div>
                                            <div className={context.mediaCategory.sm ? "lh-1" : ""} style={{ fontSize: "1.2em" }}>{data.timeInMeetings1}</div>
                                        </div>
                                        <div className="topUsrAreaDate">
                                            <div className="topUsrAreaDateTrk">
                                                <div className="topUsrAreaDateTrk-p text-nowrap">Non-working hours <b>{data.meetingsOrganizedOOH1}%</b></div>
                                                <div className="topUsrAreaDateTrk-p text-nowrap">Video On <b>{data.meetingsOrganizedVideo1}%</b></div>
                                                <div className="topUsrAreaDateTrk-p text-nowrap">Recurring <b>{data.recurrence2}%</b></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="d-flex justify-content-around compareDataInrRow-val compareDataInrRow-pad">
                                    <div>
                                        <div className="topUsrAreaDateTrk pb-2">
                                            <div className="topUsrAreaDateTrk-p ">Meeting</div>
                                            <div className="" style={{ fontSize: "1.2em" }}>{data.meetingsTotal1}</div>
                                        </div>
                                        <div className="topUsrAreaDate">
                                            <div className="topUsrAreaDateTrk">
                                                <div className="topUsrAreaDateTrk-p text-nowrap">External - {data.meetingsOrganizedExternal1}%</div>
                                                <div className="topUsrAreaDateTrk-p text-nowrap">One-to-One - {data.meetingsOrganized1To11}%</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="topUsrAreaDateTrk pb-2">
                                            <div className="topUsrAreaDateTrk-p">Talk Time</div>
                                            <div className="" style={{ fontSize: "1.2em" }}>{data.timeInMeetings1}</div>
                                        </div>
                                        <div className="topUsrAreaDate">
                                            <div className="topUsrAreaDateTrk">
                                                <div className="topUsrAreaDateTrk-p text-nowrap">Out of hours - {data.meetingsOrganizedOOH1}%</div>
                                                <div className="topUsrAreaDateTrk-p text-nowrap">Video - {data.meetingsOrganizedVideo1}%</div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className="compareDataInrRow compareDataInrRow-new">
                                <div className={context.mediaCategory.sm ? "d-flex flex-column align-items-end compareDataInrRow-val compareDataInrRow-pad" : "d-flex justify-content-around compareDataInrRow-val compareDataInrRow-pad text-right"}>
                                    <div className={context.mediaCategory.sm ? "text-right pb-2" : ""}>
                                        <div className={context.mediaCategory.sm ? "topUsrAreaDateTrk d-flex align-items-center justify-content-end" : "topUsrAreaDateTrk pb-2"}>
                                            <div className={context.mediaCategory.sm ? "topUsrAreaDateTrk-p mr-2" : "topUsrAreaDateTrk-p"}>Calls</div>
                                            <div className={context.mediaCategory.sm ? "lh-1" : ""} style={{ fontSize: "1.2em" }}>{data.totalCalls}</div>
                                        </div>
                                        <div className="topUsrAreaDate">
                                            <div className="topUsrAreaDateTrk">
                                                <div className="topUsrAreaDateTrk-p text-nowrap">External <b>{data.callsExternal}%</b></div>
                                                <div className="topUsrAreaDateTrk-p text-nowrap">One-to-One <b>{data.calls1To1}%</b></div>
                                                <div className="topUsrAreaDateTrk-p text-nowrap">Non-working hours <b>{data.callsOOH}%</b></div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className={context.mediaCategory.sm ? "text-right" : ""}>
                                        <div className={context.mediaCategory.sm ? "topUsrAreaDateTrk d-flex align-items-center" : "topUsrAreaDateTrk pb-2"}>
                                            <div className={context.mediaCategory.sm ? "topUsrAreaDateTrk-p mr-2 text-nowrap" : "topUsrAreaDateTrk-p"}>Talk Time</div>
                                            <div className={context.mediaCategory.sm ? "lh-1" : ""} style={{ fontSize: "1.2em" }}>{data.totalCallTalktime}</div>
                                        </div>
                                        <div className="topUsrAreaDate">
                                            <div className="topUsrAreaDateTrk">
                                                <div className="topUsrAreaDateTrk-p text-nowrap">Video On <b>{data.callsVideo}%</b></div>
                                                <div className="topUsrAreaDateTrk-p text-nowrap">Initiated <b>{data.totalCallsOrganized}%</b></div>
                                                <div className="topUsrAreaDateTrk-p text-nowrap d-none d-md-block">&nbsp;</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="compareDataInrRow-p compareDataInrRow-p-new">Calls</div>
                                <div className={context.mediaCategory.sm ? "d-flex flex-column align-items-start compareDataInrRow-val compareDataInrRow-pad" : "d-flex justify-content-around compareDataInrRow-val compareDataInrRow-pad text-right"}>
                                    <div className={context.mediaCategory.sm ? "text-left pb-2" : ""}>
                                        <div className={context.mediaCategory.sm ? "topUsrAreaDateTrk d-flex align-items-center" : "topUsrAreaDateTrk pb-2"}>
                                            <div className={context.mediaCategory.sm ? "topUsrAreaDateTrk-p mr-2" : "topUsrAreaDateTrk-p"}>Calls</div>
                                            <div className={context.mediaCategory.sm ? "lh-1" : ""} style={{ fontSize: "1.2em" }}>{data.totalCalls1}</div>
                                        </div>
                                        <div className="topUsrAreaDate">
                                            <div className="topUsrAreaDateTrk">
                                                <div className="topUsrAreaDateTrk-p text-nowrap">External <b>{data.callsExternal1}%</b></div>
                                                <div className="topUsrAreaDateTrk-p text-nowrap">One-to-One <b>{data.calls1To11}%</b></div>
                                                <div className="topUsrAreaDateTrk-p text-nowrap">Non-working hours <b>{data.callsOOH1}%</b></div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className={context.mediaCategory.sm ? "text-left" : ""}>
                                        <div className={context.mediaCategory.sm ? "topUsrAreaDateTrk d-flex align-items-center" : "topUsrAreaDateTrk pb-2"}>
                                            <div className={context.mediaCategory.sm ? "topUsrAreaDateTrk-p mr-2 text-nowrap" : "topUsrAreaDateTrk-p"}>Talk Time</div>
                                            <div className={context.mediaCategory.sm ? "lh-1" : ""} style={{ fontSize: "1.2em" }}>{data.totalCallTalktime1}</div>
                                        </div>
                                        <div className="topUsrAreaDate">
                                            <div className="topUsrAreaDateTrk">
                                                <div className="topUsrAreaDateTrk-p text-nowrap">Video On <b>{data.callsVideo1}%</b></div>
                                                <div className="topUsrAreaDateTrk-p text-nowrap">Initiated <b>{data.totalCallsOrganized1}%</b></div>
                                                <div className="topUsrAreaDateTrk-p text-nowrap d-none d-md-block">&nbsp;</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="compareDataInrRow compareDataInrRow-new">
                                {/* <div className="compareDataInrRow-val compareDataInrRow-pad d-flex flex-column">
                                    <div className="pb-3">{data.messagesTotal}</div>
                                    <div className="topUsrAreaDate">
                                        <div className="topUsrAreaDateTrk">
                                            <div className="topUsrAreaDateTrk-p text-nowrap">External {data.messagesExternal}%</div>
                                            <div className="topUsrAreaDateTrk-p text-nowrap">One-to-One {data.messages1To1}%</div>
                                            <div className="topUsrAreaDateTrk-p text-nowrap">Non-working hours {data.messagesOOH}%</div>
                                            <div className="topUsrAreaDateTrk-p text-nowrap">Within Team {data.messagesWithinTeam}%</div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="compareDataInrRow-val compareDataInrRow-pad">
                                    <div className={context.mediaCategory.sm ? "d-flex flex-column align-items-end" : "d-flex justify-content-around"}>
                                        <div className={context.mediaCategory.sm ? "text-right" : ""}>
                                            <div className={context.mediaCategory.sm ? "topUsrAreaDateTrk d-flex align-items-center justify-content-end" : "topUsrAreaDateTrk m-2"}>
                                                <div className={context.mediaCategory.sm ? "topUsrAreaDateTrk-p mr-2 pb-1" : "topUsrAreaDateTrk-p"}>Chat</div>
                                                <div className={context.mediaCategory.sm ? "lh-1 pb-1" : ""} style={{ fontSize: "1.2em" }}>{data.messagesChats1}</div>
                                            </div>
                                            <div className={context.mediaCategory.sm ? "topUsrAreaDateTrk d-flex align-items-center justify-content-end" : "topUsrAreaDateTrk m-2"}>
                                                <div className={context.mediaCategory.sm ? "topUsrAreaDateTrk-p mr-2 pb-1" : "topUsrAreaDateTrk-p"}>Mentions</div>
                                                <div className={context.mediaCategory.sm ? "lh-1 pb-1" : ""} style={{ fontSize: "1.2em" }}>{data.messagesMentions1}</div>
                                            </div>
                                        </div>
                                        <div className={context.mediaCategory.sm ? "text-right" : ""}>
                                            <div className={context.mediaCategory.sm ? "topUsrAreaDateTrk d-flex align-items-center justify-content-end" : "topUsrAreaDateTrk m-2"}>
                                                <div className={context.mediaCategory.sm ? "topUsrAreaDateTrk-p mr-2 pb-1" : "topUsrAreaDateTrk-p"}>Posts</div>
                                                <div className={context.mediaCategory.sm ? "lh-1 pb-1" : ""} style={{ fontSize: "1.2em" }}>{data.messagesPosts1}</div>
                                            </div>
                                            <div className={context.mediaCategory.sm ? "topUsrAreaDateTrk d-flex align-items-center justify-content-end" : "topUsrAreaDateTrk m-2"}>
                                                <div className={context.mediaCategory.sm ? "topUsrAreaDateTrk-p mr-2 pb-1" : "topUsrAreaDateTrk-p"}>Replies</div>
                                                <div className={context.mediaCategory.sm ? "lh-1 pb-1" : ""} style={{ fontSize: "1.2em" }}>{data.messagesReplies1}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={context.mediaCategory.sm ? "d-flex flex-column align-items-end" : "d-flex justify-content-around"}>
                                        <div className={context.mediaCategory.sm ? "text-right" : "mx-auto"}>
                                            <div className={context.mediaCategory.sm ? "topUsrAreaDateTrk align-items-center justify-content-end" : "topUsrAreaDateTrk"}>
                                                <div className="topUsrAreaDateTrk-p text-nowrap">External <b>{data.messagesExternal}%</b></div>
                                                <div className="topUsrAreaDateTrk-p text-nowrap">One-to-One <b>{data.messages1To1}%</b></div>
                                            </div>
                                        </div>
                                        <div className={context.mediaCategory.sm ? "text-right" : "mx-auto"}>
                                            <div className={context.mediaCategory.sm ? "topUsrAreaDateTrk align-items-center justify-content-end" : "topUsrAreaDateTrk"}>
                                                <div className="topUsrAreaDateTrk-p text-nowrap">Non-working hours <b>{data.messagesOOH}%</b></div>
                                                <div className="topUsrAreaDateTrk-p text-nowrap">Within Team <b>{data.messagesWithinTeam}%</b></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="compareDataInrRow-p compareDataInrRow-p-new">Messages</div>
                                <div className="compareDataInrRow-val compareDataInrRow-pad">
                                    <div className={context.mediaCategory.sm ? "d-flex flex-column align-items-start " : "d-flex justify-content-around"}>
                                        <div className={context.mediaCategory.sm ? "text-left" : ""}>
                                            <div className={context.mediaCategory.sm ? "topUsrAreaDateTrk d-flex align-items-center" : "topUsrAreaDateTrk m-2"}>
                                                <div className={context.mediaCategory.sm ? "topUsrAreaDateTrk-p mr-2 pb-1" : "topUsrAreaDateTrk-p"}>Chat</div>
                                                <div className={context.mediaCategory.sm ? "lh-1 pb-1" : ""} style={{ fontSize: "1.2em" }}>{data.messagesChats2}</div>
                                            </div>
                                            <div className={context.mediaCategory.sm ? "topUsrAreaDateTrk d-flex align-items-center" : "topUsrAreaDateTrk m-2"}>
                                                <div className={context.mediaCategory.sm ? "topUsrAreaDateTrk-p mr-2 pb-1" : "topUsrAreaDateTrk-p"}>Mentions</div>
                                                <div className={context.mediaCategory.sm ? "lh-1 pb-1" : ""} style={{ fontSize: "1.2em" }}>{data.messagesMentions2}</div>
                                            </div>
                                        </div>
                                        <div className={context.mediaCategory.sm ? "text-left" : ""}>
                                            <div className={context.mediaCategory.sm ? "topUsrAreaDateTrk d-flex align-items-center" : "topUsrAreaDateTrk m-2"}>
                                                <div className={context.mediaCategory.sm ? "topUsrAreaDateTrk-p mr-2 pb-1" : "topUsrAreaDateTrk-p"}>Posts</div>
                                                <div className={context.mediaCategory.sm ? "lh-1 pb-1" : ""} style={{ fontSize: "1.2em" }}>{data.messagesPosts2}</div>
                                            </div>
                                            <div className={context.mediaCategory.sm ? "topUsrAreaDateTrk d-flex align-items-center" : "topUsrAreaDateTrk m-2"}>
                                                <div className={context.mediaCategory.sm ? "topUsrAreaDateTrk-p mr-2 pb-1" : "topUsrAreaDateTrk-p"}>Replies</div>
                                                <div className={context.mediaCategory.sm ? "lh-1 pb-1" : ""} style={{ fontSize: "1.2em" }}>{data.messagesReplies2}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={context.mediaCategory.sm ? "d-flex flex-column align-items-start" : "d-flex justify-content-around"}>
                                        <div className={context.mediaCategory.sm ? "text-left" : "mx-auto"}>
                                            <div className={context.mediaCategory.sm ? "topUsrAreaDateTrk align-items-center" : "topUsrAreaDateTrk"}>
                                                <div className="topUsrAreaDateTrk-p text-nowrap">External <b>{data.messagesExternal1}%</b></div>
                                                <div className="topUsrAreaDateTrk-p text-nowrap">One-to-One <b>{data.messages1To11}%</b></div>
                                            </div>
                                        </div>
                                        <div className={context.mediaCategory.sm ? "text-left" : "mx-auto"}>
                                            <div className={context.mediaCategory.sm ? "topUsrAreaDateTrk align-items-center" : "topUsrAreaDateTrk"}>
                                                <div className="topUsrAreaDateTrk-p text-nowrap">Non-working hours <b>{data.messagesOOH1}%</b></div>
                                                <div className="topUsrAreaDateTrk-p text-nowrap">Within Team <b>{data.messagesWithinTeam1}%</b></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="compareDataInrRow-val compareDataInrRow-pad d-flex flex-column">
                                    <div className="pb-3">{data.messagesTotal1}</div>
                                    <div className="topUsrAreaDate">
                                        <div className="topUsrAreaDateTrk">
                                            <div className="topUsrAreaDateTrk-p text-nowrap">External {data.messagesExternal1}%</div>
                                            <div className="topUsrAreaDateTrk-p text-nowrap">One-to-One {data.messages1To11}%</div>
                                            <div className="topUsrAreaDateTrk-p text-nowrap">Non-working hours {data.messagesOOH1}%</div>
                                            <div className="topUsrAreaDateTrk-p text-nowrap">Within Team {data.messagesWithinTeam1}%</div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className="compareDataInrRow compareDataInrRow-new h-3r">
                                <div className="compareDataInrRow-val compareDataInrRow-pad d-flex flex-column">
                                    <div className="progress" style={{ height: ".5rem", width: "90%" }}>
                                        <div className="progress-bar bg-success" style={{ width: data.goodSentiment1 + "%" }} title={`good :` + data.goodSentiment1 + "%"}>
                                        </div>
                                        <div className="progress-bar bg-warning border-left" style={{ width: data.neutralSentiment1 + "%" }} title={"average :" + data.neutralSentiment1 + "%"}>
                                        </div>
                                        <div className="progress-bar bg-danger border-left" style={{ width: data.poorSentiment1 + "%" }} title={`poor :` + data.poorSentiment1 + "%"}>
                                        </div>
                                    </div>
                                </div>
                                <div className="compareDataInrRow-p compareDataInrRow-p-new">Sentiments</div>
                                <div className="compareDataInrRow-val compareDataInrRow-pad d-flex flex-column">
                                    <div className="progress" style={{ height: ".5rem", width: "90%" }}>
                                        <div className="progress-bar bg-success" style={{ width: data.goodSentiment2 + "%" }} title={`good :` + data.goodSentiment2 + "%"}>
                                        </div>
                                        <div className="progress-bar bg-warning border-left" style={{ width: data.neutralSentiment2 + "%" }} title={"average :" + data.neutralSentiment2 + "%"}>
                                        </div>
                                        <div className="progress-bar bg-danger border-left" style={{ width: data.poorSentiment2 + "%" }} title={`poor :` + data.poorSentiment2 + "%"}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* //     <div classNameName="compareGraph">
                
      
        //                 </div>
        //                 <div classNameName="compareDataInrRow compareDataInrRow-new">
        //                     <div classNameName="compareDataInrRow-val compareDataInrRow-pad compareDataInrRow-p-new">{data.timeInMeetings}</div>
        //                     <div classNameName="compareDataInrRow-p compareDataInrRow-p-new text-secondary">Time In Meetings</div>
        //                     <div classNameName="compareDataInrRow-val compareDataInrRow-pad compareDataInrRow-p-new">{data.timeInMeetings1}</div>
        //                 </div>
        //                 <div classNameName="compareDataInrRow compareDataInrRow-new">
        //                     <div classNameName="compareDataInrRow-val compareDataInrRow-pad">
        //                         {data.meetingsOrganizedTotal}
        //                         <div classNameName="topUsrAreaDate">
        //                             <div classNameName="topUsrAreaDateTrk">
        //                                 <div classNameName="topUsrAreaDateTrk-p text-nowrap">External% - {data.meetingsOrganizedExternal}%</div>
        //                                 <div classNameName="topUsrAreaDateTrk-p text-nowrap">One to One% - {data.meetingsOrganized1To1}%</div>
        //                                 <div classNameName="topUsrAreaDateTrk-p text-nowrap">OOH% - {data.meetingsOrganizedOOH}%</div>
        //                                 <div classNameName="topUsrAreaDateTrk-p text-nowrap">Video% - {data.meetingsOrganizedVideo}%</div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                     <div classNameName="compareDataInrRow-p compareDataInrRow-p-new text-secondary">Meetings Organized</div>
        //                     <div classNameName="compareDataInrRow-val compareDataInrRow-pad">
        //                     {data.meetingsOrganizedTotal1}

        //                         <div classNameName="topUsrAreaDate">
        //                             <div classNameName="topUsrAreaDateTrk">
        //                                 <div classNameName="topUsrAreaDateTrk-p text-nowrap">External% - {data.meetingsOrganizedExternal1}%</div>
        //                                 <div classNameName="topUsrAreaDateTrk-p text-nowrap">One to One% - {data.meetingsOrganized1To11}%</div>
        //                                 <div classNameName="topUsrAreaDateTrk-p text-nowrap">OOH% - {data.meetingsOrganizedOOH1}%</div>
        //                                 <div classNameName="topUsrAreaDateTrk-p text-nowrap">Video% - {data.meetingsOrganizedVideo1}%</div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //                 <div classNameName="compareDataInrRow compareDataInrRow-new">
        //                     <div classNameName="compareDataInrRow-val compareDataInrRow-pad compareDataInrRow-p-new">{data.totalCallTalktime}</div>
        //                     <div classNameName="compareDataInrRow-p compareDataInrRow-p-new text-secondary">Total Call Talktime</div>
        //                     <div classNameName="compareDataInrRow-val compareDataInrRow-pad compareDataInrRow-p-new">{data.totalCallTalktime1}</div>
        //                 </div>
        //                 <div classNameName="compareDataInrRow compareDataInrRow-new">
        //                     <div classNameName="compareDataInrRow-val compareDataInrRow-pad">
        //                        {data.totalCalls}
        //                         <div classNameName="topUsrAreaDate">
        //                             <div classNameName="topUsrAreaDateTrk">
        //                                 <div classNameName="topUsrAreaDateTrk-p text-nowrap">External% - {data.callsExternal}%</div>
        //                                 <div classNameName="topUsrAreaDateTrk-p text-nowrap">One to One% - {data.calls1To1}%</div>
        //                                 <div classNameName="topUsrAreaDateTrk-p text-nowrap">OOH% - {data.callsOOH}%</div>
        //                                 <div classNameName="topUsrAreaDateTrk-p text-nowrap">Video% - {data.callsVideo}%</div>
        //                                 <div classNameName="topUsrAreaDateTrk-p text-nowrap">Organized% - {data.totalCallsOrganized}%</div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                     <div classNameName="compareDataInrRow-p compareDataInrRow-p-new text-secondary">Total Calls</div>
        //                     <div classNameName="compareDataInrRow-val compareDataInrRow-pad">
        //                         {data.totalCalls1}
                                
        //                         <div classNameName="topUsrAreaDate">
        //                             <div classNameName="topUsrAreaDateTrk">
        //                                 <div classNameName="topUsrAreaDateTrk-p text-nowrap">External% - {data.callsExternal1}%</div>
        //                                 <div classNameName="topUsrAreaDateTrk-p text-nowrap">One to One% - {data.calls1To11}%</div>
        //                                 <div classNameName="topUsrAreaDateTrk-p text-nowrap">OOH% - {data.callsOOH1}%</div>
        //                                 <div classNameName="topUsrAreaDateTrk-p text-nowrap">Video% - {data.callsVideo1}%</div>
        //                                 <div classNameName="topUsrAreaDateTrk-p text-nowrap">Organized% - {data.totalCallsOrganized1}%</div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //                 <div classNameName="compareDataInrRow compareDataInrRow-new">
        //                     <div classNameName="compareDataInrRow-val compareDataInrRow-pad compareDataInrRow-p-new">{data.totalTeamMemberships}</div>
        //                     <div classNameName="compareDataInrRow-p compareDataInrRow-p-new text-secondary">Total Team Memberships</div>
        //                     <div classNameName="compareDataInrRow-val compareDataInrRow-pad compareDataInrRow-p-new">{data.totalTeamMemberships1}</div>
        //                 </div>
        //                 <div classNameName="compareDataInrRow compareDataInrRow-new">
        //                     <div classNameName="compareDataInrRow-val compareDataInrRow-pad">
        //                         {data.messagesTotal}
        //                         <div classNameName="topUsrAreaDate">
        //                             <div classNameName="topUsrAreaDateTrk">
        //                                 <div classNameName="topUsrAreaDateTrk-p text-nowrap">External% - {data.messagesExternal}%</div>
        //                                 <div classNameName="topUsrAreaDateTrk-p text-nowrap">One to One% - {data.messages1To1}%</div>
        //                                 <div classNameName="topUsrAreaDateTrk-p text-nowrap">OOH% - {data.messagesOOH}%</div>
        //                                 <div classNameName="topUsrAreaDateTrk-p text-nowrap">Within Team% - {data.messagesWithinTeam}%</div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                     <div classNameName="compareDataInrRow-p compareDataInrRow-p-new text-secondary">Total Messages (including chats, posts, etc.)</div>
        //                     <div classNameName="compareDataInrRow-val compareDataInrRow-pad">
        //                         {data.messagesTotal1}
        //                         <div classNameName="topUsrAreaDate">
        //                             <div classNameName="topUsrAreaDateTrk">
        //                                 <div classNameName="topUsrAreaDateTrk-p text-nowrap">External% - {data.messagesExternal1}%</div>
        //                                 <div classNameName="topUsrAreaDateTrk-p text-nowrap">One to One% - {data.messages1To11}%</div>
        //                                 <div classNameName="topUsrAreaDateTrk-p text-nowrap">OOH% - {data.messagesOOH1}%</div>
        //                                 <div classNameName="topUsrAreaDateTrk-p text-nowrap">Within Team% - {data.messagesWithinTeam1}%</div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
               
        // </div> */}
                </div >
            )
        }
        </WindowContext >
    );
}

export default UserComparisonView;