import React, { useState, useEffect, Fragment } from "react";
// import { TimePicker } from "@progress/kendo-react-dateinputs";
import {
    Grid,
    GridColumn as Column,
    GridNoRecords,
} from "@progress/kendo-react-grid";
import {
    AcceptIcon,
    ExclamationTriangleIcon,
} from "@fluentui/react-icons-northstar";

import { getter } from "@progress/kendo-react-common";

import {
    Form,
    Loader,
    Flex,
    Dialog as FluentDialog,
    Button,
    Text,
    Input,
    FormLabel,
    FormDropdown,
    FormField,
    FormCheckbox,
    FormButton
} from "@fluentui/react-northstar";

import {
    CellWithTypeIcon,
    CellWithCategoryIcon,
} from "../../../pages/CustomCells/CustomCells";

import DashboardApiService from "../../../../services/DashboardApiService";
import FiltersApiService from "../../../../services/FiltersApiService";
import AuthHelper from "../../../../services/auth-helper";
import {
    FilterTypes,
    cardFilters,
} from "../CardFiltersData/CardFiltersData";
import { Dialog } from "@progress/kendo-react-dialogs";
import { WidgetPreview } from "../../../cards/Card/Card";
import CenteredHeader from "../../../Shared/Header/Header";
import "./MultiCardSettings.scss";
import WindowContext from "../../../Shared/Context/Context";
import TeamsTimePicker from "../../../Shared/uicomponents/TeamsTimePicker";
import NativeTimePicker from "../../../Shared/uicomponents/NativeTimePicker";
import TeamsDatePicker from "../../../Shared/uicomponents/TeamsDatePicker";
import NativeDatePicker from "../../../Shared/uicomponents/NativeDatePicker";
import * as moment from "moment";
import { orderBy } from "@progress/kendo-data-query";
import { OPERATIONSTATUS } from "../../../Shared/uicomponents/OperationStatus"
const DATA_ITEM_KEY = "cardWidgetID";
const SELECTED_FIELD = "checked";
const idGetter = getter(DATA_ITEM_KEY);

const CellWithViewIcon = (props) => {
    const data = props.dataItem;
    return (
        <Button
            // title="Preview"
            onClick={() => props.handleExpandChange(data)}
            className="widget_category_container"
            text
            iconOnly
            content={
                <>
                    {data.categoryTypeID === 1 && (
                        <i title="Click to view" className={"analytics-summary"} />
                    )}
                    {data.categoryTypeID === 2 && (
                        <i title="Click to view" className={"analytics-activity2"} />
                    )}
                    {data.categoryTypeID === 3 && (
                        <i title="Click to view" className={"analytics-trends"} />
                    )}
                    {data.categoryTypeID === 4 && (
                        <i title="Click to view" className={"analytics-comparison2"} />
                    )}
                    {data.categoryTypeID === 5 && (
                        <i title="Click to view" className={"analytics-insights"} />
                    )}
                    {data.categoryTypeID === 6 && (
                        <i title="Click to view" className={"analytics-list"} />
                    )}
                </>
            }
        />
    );
};

const DetailComponent = (props) => {
    const dataItem = props.dataItem;
    return (
        <section style={{ paddingRight: "32px" }}>
            <WidgetPreview
                widgetID={dataItem.cardWidgetID}
                viewTypeID={dataItem.cardViewTypeID}
            />
        </section>
    );
};

const MultiCardSettings = (props) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [dashboardId, setDashboardId] = useState(props.dashboardId);
    const [loaded, setLoaded] = useState(false);
    const [filterData, setFilterData] = useState(props.userFiltersData);
    const [submitButtonState, setSubmitButtonState] = useState(0);

    const [date, setDate] = useState(1);
    const [time, setTime] = useState(0);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [teams, setTeams] = useState([]);
    const [groups, setGroups] = useState([]);
    const [users, setUsers] = useState([]);
    const [callQueues, setCallQueues] = useState([]);
    const [agents, setAgents] = useState([]);
    const [allcards, setAllcards] = useState(props.addcardList);

    const [dateItems, setDateItems] = useState([]);
    const [timeItems, setTimeItems] = useState([]);
    const [filterTypes, setFilterTypes] = useState([]);
    const [filterTypeId, setFilterTypeId] = useState(0);
    const [filterType, setFilterType] = useState(FilterTypes[0]);
    const [isValid, setIsValid] = useState(false);
    const [teamIsGroup, setTeamIsGroup] = useState(false);
    const [teamIsGroupDisabled, setTeamIsGroupDisabled] = useState(false);

    const [expandPeople, setExpandPeople] = useState(0);
    const [peopleMasKList, setPeopleMaskList] = useState([]);

    const [widgetNameMap, setWidgetNameMap] = useState(null);
    const [selectedState, setSelectedState] = useState({});
    const [filterProperty, setFilterProperty] = useState("users");
    const [filterValue, setFilterValue] = useState(0);
    const [widgets, setWidgets] = useState([]);
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);
    const [openPreview, setOpenPreview] = useState(false);
    const [previewObj, setPreviewObj] = useState(null);
    const [checkedItems, setCheckedItems] = useState(new Map());
    const [open, setOpen] = useState(false);
    const [dropDownOptions, setDropDownOptions] = useState([]);
    const [teamIsGroupList, setTeamIsGroupList] = useState(null);
    const [teamIsNotGroupList, setTeamIsNotGroupList] = useState(null);

    const [sort, setSort] = useState([]);
    const [isDemo, setIsDemo] = useState(false);
    const [isDemoMode, setIsDemoMode] = useState(global.localStorage.getItem("demoGlobal"));
    const [submitDemoButtonState, setSubmitDemoButtonState] = useState(0);

    const [_fromDateLabel, _setFromDateLabel] = useState();
    const [_toDateLabel, _setToDateLabel] = useState();
    const [cWidgetID, setCWidgetID] = useState([]);
    const [showMessage, setShowMessage] = useState(0);
    useEffect(() => {
        AuthHelper.getAccessToken(function (token) {
            DashboardApiService.GetDashboardCards(dashboardId, token).then((response) => {
                if (response !== undefined && response !== " ") {
                    setCWidgetID(
                        response.map(
                            (d) => {
                                return {
                                    key: d.cardID,
                                    widgetID: d.widgetID
                                }
                            }
                        )
                    )
                    global.localStorage.setItem("TestItems", response.length);
                }
                else {
                    console.log("No dashboard widget found");
                }
            });
        });
    }, []);

    useEffect(() => {
        if (isDemoMode === "true") {
            setIsDemo(true);
        } else {
            setIsDemo(false);
        }
    }, []);

    useEffect(() => {
        //setWidgetNameMap(WIDGETSNAMEMAP);
        setFilterData(props.userFiltersData);
        setStates(props.userFiltersData);
        setPeopleOptions(props.userFiltersData);
    }, []);
    useEffect(() => {
        var list = [];
        let data = { ...props.userFiltersData.teamFilter };
        for (let i = 0; i < data.items.length; i++) {
            if (data.items[i].teamIsGroup === false)
                list.push(data.items[i]);
        }
        setTeamIsNotGroupList(list);
    }, []);
    useEffect(() => {

        if (activeIndex === 1) {
            const WIDGETSWITHFILTERS = allcards.map((obj) => {
                return {
                    ...obj,
                    ...cardFilters[obj.cardFilterID],
                    checked: false,
                };
            });
            // setWidgets(WIDGETSWITHFILTERS);
            setLoaded(true);
        } else {
            setLoaded(false);
        }
    }, [activeIndex]);

    const handleQuarter = (value) => {
        const today = new Date();
        const quarter = Math.floor((today.getMonth() / 3));
        var _fromDateLabel;
        if (value === 9) {
            _fromDateLabel = new Date(today.getFullYear(), quarter * 3, 1);
        }
        else if (value === 10) {
            _fromDateLabel = new Date(today.getFullYear(), quarter * 3 - 3, 1);
        }

        if (value === 9 || value === 10) {
            let _toDateLabel = new Date(_fromDateLabel.getFullYear(), _fromDateLabel.getMonth() + 3, 0);

            _setFromDateLabel(_fromDateLabel.toLocaleString('en-US', {
                year: "numeric",
                month: "long",
                day: "numeric",
            }));
            _setToDateLabel(_toDateLabel.toLocaleString('en-US', {
                year: "numeric",
                month: "long",
                day: "numeric",
            }));
        }
    }
    const rowRender = (tr, props) => {
        const gridRowWidget = props.dataItem;
        const dashboardAddedWidgets = cWidgetID;
        let checkPresent = false;
        for (var i = 0; i < dashboardAddedWidgets.length; i++) {
            if (gridRowWidget.cardWidgetID === dashboardAddedWidgets[i].widgetID && gridRowWidget.checked == true) {
                checkPresent = true;
                setShowMessage(1);
                break;
            }
        }
        const trProps = {
            className: checkPresent ? "alert-info" : " ",
        };

        return React.cloneElement(
            tr,
            {
                ...trProps,
            },

            tr.props.children
        );
    };

    const selectionChange = (event) => {
        const checked = event.syntheticEvent.target.checked;
        const dataItemKey = idGetter(event.dataItem);

        setSelectedState({ ...selectedState, [dataItemKey]: checked });
        setCheckedItems(checkedItems.set(dataItemKey, checked));

        let checkedArr = [];
        let unCheckedArr = [];

        for (let [key, value] of checkedItems.entries()) {
            if (value === true) {
                checkedArr.push(key);
            } else {
                unCheckedArr.push(key);
                setShowMessage(0);
            }
        }

        if (checkedArr.length >= 1) {
            setIsSaveEnabled(true);
        } else {
            setIsSaveEnabled(false);
        }
        if (isDemo)
            setIsSaveEnabled(true);
    };

    const headerSelectionChange = (event) => {
        const checked = event.syntheticEvent.target.checked;
        const newSelectedState = {};
        widgets.forEach((item) => {
            newSelectedState[idGetter(item)] = checked;
            setCheckedItems(checkedItems.set(idGetter(item), checked));
        });
        setIsSaveEnabled(checked);
        setSelectedState(newSelectedState);

        let checkedArr = [];
        let unCheckedArr = [];

        for (let [key, value] of checkedItems.entries()) {
            if (value === true) {
                checkedArr.push(key);
            } else {
                unCheckedArr.push(key);
            }
        }

        if (checkedArr.length >= 1) {
            setIsSaveEnabled(true);
        } else {
            setIsSaveEnabled(false);
        }
        if (isDemo)
            setIsSaveEnabled(true);
    };

    const handleExpandChangeOnTypeCell = (dataItem) => {
        let newData = widgets.map((item) => {
            if (item.widgetName === dataItem.widgetName) {
                item.expanded = !dataItem.expanded;
            }
            return item;
        });
        setWidgets(newData);
    };

    const checkValid = (_filterType, _selectCount) => {
        if (_filterType === 0) {
            return _selectCount >= 1 ? true : false;
        } else if (_filterType === 1) {
            return _selectCount >= 1 ? true : false;
        } else if (_filterType === 2) {
            return _selectCount >= 1 ? true : false;
        } else if (_filterType === 3) {
            return _selectCount >= 1 ? true : false;
        } else if (_filterType === 4) {
            return _selectCount >= 1 ? true : false;
        }
    };

    const setPeopleOptions = (_userFilterData) => {
        let peopleOptions = _userFilterData.userFilter.items.map((e) => e);
        let directReporteeArr = [];
        peopleOptions.slice(2).forEach((el) => {
            if (el.isdirectreportee === false) {
                directReporteeArr.push(el);
            }
        });

        if (directReporteeArr.length === peopleOptions.slice(2).length) {
            let updateArr = peopleOptions.filter((el) => el.key !== -2);
            setDropDownOptions(updateArr);
        } else {
            setDropDownOptions(peopleOptions);
        }
    };

    //Initial setup
    const setStates = (_filterData) => {
        setSubmitButtonState(0);
        setExpandPeople(0);
        // setPeopleMaskList([]);
        setFilterTypes([]);
        setFilterTypeId(0);
        setFilterType(FilterTypes[0]);
        let settingsData = {
            runPeriod: 3,
            timePeriod: 2,
            selectedStartDate: moment().toDate(),
            selectedEndDate: moment().toDate(),
            selectedStartTime: "00:00",
            selectedEndTime: "23:59",
        };
        setDate(settingsData.runPeriod);
        setTime(settingsData.timePeriod);
        setStartTime(
            settingsData.selectedStartTime !== ""
                ? settingsData.selectedStartTime
                : "00:00"
        );
        setEndTime(
            settingsData.selectedEndTime !== ""
                ? settingsData.selectedEndTime
                : "23:59"
        );
        setStartDate(settingsData.selectedStartDate);
        setEndDate(settingsData.selectedEndDate);
        setFilterTypes(FilterTypes);
        setDateItems(_filterData.dateFilter.items);
        setTimeItems(_filterData.timeFilter.items);

        // setLoaded(true);
    };

    const handleSubmit = () => {
        if (isDemo) {
            setSubmitDemoButtonState(1)
        }
        else {
            setSubmitButtonState(1);
        }
        let widgetIdArr = [];
        let nameArr = [];
        Object.keys(selectedState).forEach((key) => {
            if (selectedState[key] === true) {
                let widgetId = Number(key);
                widgetIdArr.push(widgetId);
                nameArr.push((allcards.filter(el => el.cardWidgetID === widgetId))[0].widgetName);
            }
        });

        let settingsData = {
            cardId: -1,
            Name: nameArr.toString(),
            dashboardId: dashboardId,
            widgetIdList: widgetIdArr.toString(),
            runPeriod: date,
            timePeriod: time,
            selectedStartTime: time === 2 ? startTime : "",
            selectedEndTime: time === 2 ? endTime : "",
            selectedStartDate: date === 5 ? (new Date(startDate)).toDateString() : null,
            selectedEndDate: date === 5 ? (new Date(endDate)).toDateString() : null,
            TeamFilter:
                filterTypeId === 1 ? teams.map((d) => d.value).toString() : "",
            teamIsGroup: filterTypeId === 1 ? teamIsGroup : false,
            GroupFilter: filterTypeId === 2 ? groups.map((d) => d.value).toString() : "",
            userFilter: filterTypeId === 0 ? users.map((d) => d.value).toString() : "",
            callQueueFilter: filterTypeId === 3 ? callQueues.map((d) => d.value).toString() : "",
            agentFilter: filterTypeId === 4 ? agents.map((d) => d.value).toString() : "",
            callQualityFilter: "0,1,2",
            sentimentFilter: "0,1,2",
            reactionFilter: "0,1,2,3,4,5,6",
            modalitiesFilter: "0,1,2",
            msgParticipantsFilter: "0,1",
            callParticipantsFilter: "0,1",
            joinedAsFilter: "0,1",
            collaborationTypeFilter: "0,1",
            messagesFilter: false,
            xPosition: 0,
            yPosition: 0,
        };
        if (isDemo) {
            settingsData.userFilter = "";
            settingsData.agentFilter = "";
            settingsData.TeamFilter = "";
            settingsData.GroupFilter = "";
            settingsData.callQueueFilter = "";
        }
        var nameString = "";
        AuthHelper.getAccessToken(function (token) {
            DashboardApiService.GetDashboardCards(dashboardId, token).then((response) => {


                for (var j = 0; j < nameArr.length; j++) {
                    var i = -1;
                    var count = -1;
                    count = response.filter(
                        (item) => item.name === nameArr[j] && item.cardID !== settingsData.cardId
                    ).length;

                    var generateCardName = true;

                    if (count === 0)
                        generateCardName = false;
                    else
                        count = -1;
                    while (count !== 0) {
                        i = i + 1;
                        count = response.filter(
                            (item) => item.name === (nameArr[j] + " (" + (i + 1) + ")") && item.cardID !== settingsData.cardID
                        ).length;
                    }

                    if (generateCardName) {

                        nameString = nameString === "" ? nameString + nameArr[j] + " (" + (i + 1) + ")" : nameString + "," + nameArr[j] + " (" + (i + 1) + ")";

                    }
                    else
                        nameString = nameString === "" ? nameString + nameArr[j] : nameString + "," + nameArr[j];
                }
                settingsData.Name = nameString;

                FiltersApiService.AddCardWithMultipleFilters(settingsData, token)
                    .then((response) => {
                        if (response.data === "/dashboards") {
                            window.location.replace("/dashboards");
                        }
                        if (response && response.status == 200) {
                            setSubmitButtonState(2);
                            props.submitHandler();
                            props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
                        } else {
                            setSubmitButtonState(3);
                            if (response.status == 401)
                                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
                            else
                                props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
                        }
                    })
                    .finally(() => {
                        setTimeout(function () {
                            setSubmitButtonState(0);
                        }, 1000);
                    });
            });
        });

        // AuthHelper.getAccessToken(function (token) {

        // });
    };

    const handleFilterTypeChange = (value) => {
        setFilterTypeId(value);
        if (value === 0) {
            setFilterProperty("users");
            setFilterValue(users.some((v) => v.key < 0) ? 2 : users.length === 2 ? 3 : users.length > 1 ? 2 : 1);
            setIsValid(checkValid(0, users.length));
        }
        else if (value === 1) {
            setFilterProperty("teams");
            setFilterValue(teams.length === 2 ? 3 : teams.length > 1 ? 2 : 1);
            setIsValid(checkValid(1, teams.length));
            setTime(2);
            //setStartTime("00:00:00");
            //setEndTime("23:59:59");
        }
        else if (value === 2) {
            setFilterProperty("groups");
            setFilterValue(groups.length === 2 ? 3 : groups.length > 1 ? 2 : 1);
            setIsValid(checkValid(2, groups.length));
        }
        else if (value === 3) {
            setFilterProperty("callQueues");
            setFilterValue(callQueues.length === 2 ? 3 : callQueues.length > 1 ? 2 : 1);
            setIsValid(checkValid(1, callQueues.length));
        }
        else if (value === 4) {
            setFilterProperty("agents");
            setFilterValue(agents.length === 2 ? 3 : agents.length > 1 ? 2 : 1);
            setIsValid(checkValid(1, agents.length));
        }
    };

    const handleTeamChange = (value) => {
        let checkTeamIsGroup = value.filter(el => el.teamIsGroup === false);
        if (checkTeamIsGroup.length === 0) {
            setTeamIsGroupDisabled(false)
        } else {
            setTeamIsGroupDisabled(true)
            setTeamIsGroup(false)
        }
        setTeams(value);
        setFilterProperty("teams");
        setFilterValue(value.length === 2 ? 3 : value.length > 1 ? 2 : 1);
        setIsValid(checkValid(1, value.length));
    };

    const handleGroupChange = (value) => {
        setGroups(value);
        setFilterProperty("groups");
        setFilterValue(value.length === 2 ? 3 : value.length > 1 ? 2 : 1);
        setIsValid(checkValid(2, value.length));
    };

    const handleCallQueueChange = (value) => {
        setCallQueues(value);
        setFilterProperty("callQueues");
        setFilterValue(value.length === 2 ? 3 : value.length > 1 ? 2 : 1);
        setIsValid(checkValid(2, value.length));
    };

    const handleAgentChange = (value) => {
        setAgents(value);
        setFilterProperty("agents");
        setFilterValue(value.length === 2 ? 3 : value.length > 1 ? 2 : 1);
        setIsValid(checkValid(2, value.length));
    };

    const handleUserChange = (value) => {
        setUsers(value);
        setFilterProperty("users");
        setFilterValue(value.some((v) => v.key < 0) ? 2 : value.length === 2 ? 3 : value.length > 1 ? 2 : 1);
        setIsValid(checkValid(0, value.length));
    };

    const onExpandCancel = () => {
        setExpandPeople(0);
    };

    const onExpandConfirm = () => {
        let currentSelection = users;
        currentSelection.splice(
            currentSelection.findIndex((cs) => cs.value === expandPeople),
            1
        );
        if (expandPeople === -1) {
            filterData.userFilter.items
                .filter((f) => f.key > 0)
                .forEach(function (obj, v) {
                    if (currentSelection.findIndex((f) => f.value === obj.value) === -1) {
                        currentSelection.push(obj);
                    }
                });
            setPeopleMaskList([-1, -2]);
        } else if (expandPeople === -2) {
            filterData.userFilter.items
                .filter((f) => f.key > 0 && f.isdirectreportee === true)
                .forEach(function (obj, v) {
                    if (currentSelection.findIndex((f) => f.value === obj.value) === -2) {
                        currentSelection.push(obj);
                    }
                });
            setPeopleMaskList([-2]);
        }
        setUsers(currentSelection);
        setExpandPeople(0);
    };

    const ViewCell = (props) => {
        return (
            <td className="text-center">
                <CellWithViewIcon
                    {...props}
                    handleExpandChange={(_prewiewObj) => {
                        handleExpandChangeOnTypeCell(_prewiewObj);
                    }}
                />
            </td>
        );
    };

    const onPrevious = () => {
        // setActiveIndex(activeIndex - 1);
        // setSelectedState({});
        if (isSaveEnabled === true) {
            setOpen(true);
        } else {
            setActiveIndex(activeIndex - 1);
            setSelectedState({});
        }
    };


    const onNext = () => {
        const WIDGETSWITHFILTERS = allcards.map((obj) => {
            return {
                ...obj,
                ...cardFilters[obj.cardFilterID],
                checked: false,
            };
        });
        var widgetToRemove = WIDGETSWITHFILTERS.filter((f) => f.users === 1 || f.callQueues === 1);
        let haveMyPeople = false;
        for (let i = 0; i < users.length; i++) {
            if (users[i].header === "My Reporting Network" || users.length > 1)
                haveMyPeople = true;
        }
        if (callQueues.length > 1)
            haveMyPeople = true;
        let withoutMyPeople = [];
        if (filterValue === 3) {
            withoutMyPeople = WIDGETSWITHFILTERS.filter((f) => f[filterProperty == "teams" && teamIsGroup ? "groups" : filterProperty] !== 0);
            if (haveMyPeople && users.length > 0) {
                for (let i = 0; i < widgetToRemove.length; i++) {
                    withoutMyPeople.splice(withoutMyPeople.findIndex((cs) => cs.cardWidgetID === widgetToRemove[i].cardWidgetID), 1);
                }
            }
            if (haveMyPeople && callQueues.length > 0) {
                for (let i = 0; i < widgetToRemove.length; i++) {
                    let indexToRemove = withoutMyPeople.findIndex((cs) => cs.cardWidgetID === widgetToRemove[i].cardWidgetID);
                    if (indexToRemove !== -1)
                        withoutMyPeople.splice(indexToRemove, 1);
                }
            }
        } else {
            withoutMyPeople = WIDGETSWITHFILTERS.filter(((f) => f[filterProperty == "teams" && teamIsGroup ? "groups" : filterProperty] !== 0
                && f[filterProperty == "teams" && teamIsGroup ? "groups" : filterProperty] !== 3));
            if (haveMyPeople) {
                if (haveMyPeople && users.length > 0) {
                    for (let i = 0; i < widgetToRemove.length; i++) {
                        withoutMyPeople.splice(withoutMyPeople.findIndex((cs) => cs.cardWidgetID === widgetToRemove[i].cardWidgetID), 1);
                    }
                }
                if (haveMyPeople && callQueues.length > 0) {
                    for (let i = 0; i < widgetToRemove.length; i++) {
                        let indexToRemove = withoutMyPeople.findIndex((cs) => cs.cardWidgetID === widgetToRemove[i].cardWidgetID);
                        if (indexToRemove !== -1)
                            withoutMyPeople.splice(indexToRemove, 1);
                    }
                }
            }
        }
        setWidgets(withoutMyPeople);
        setIsSaveEnabled(false);
        setActiveIndex(activeIndex + 1);
    };

    const onConfirm = () => {
        setActiveIndex(activeIndex - 1);
        setOpen(false);
        setSelectedState({});
    };

    const onCancel = () => {
        setOpen(false);
    };

    const onTeamIsGroup = (checked) => {
        setTeamIsGroup(checked);
        if (checked) {
            var list = [];
            let data = { ...props.userFiltersData.teamFilter };
            for (let i = 0; i < data.items.length; i++) {
                if (data.items[i].teamIsGroup === true)
                    list.push(data.items[i]);
            }
            setTeamIsGroupList(list);
            console.log(props.userFiltersData); console.log(list); console.log(data);
        }
    }
    const sortChange = (event) => {
        if (event.sort.length === 0) {
            setSort(event.sort);
            return;
        }
        var sortOrder = event.sort[0].dir;
        var columnName = event.sort[0].field;
        if (columnName === "widgetName") {
            let sortArray = [{ field: 'widgetName', dir: sortOrder }];
            setSort(sortArray);
            console.log(sortArray);
        }
        else
            if (columnName === "collaborationTypeID") {
                let sortArray = [{ field: 'collaborationTypeID', dir: sortOrder }];
                setSort(sortArray);
            }
    };
    return (
        <WindowContext.Consumer>
            {(context) => (
                
                <Fragment>
                    <Form
                        className="formCardSettings"
                        style={{ overflow: "hidden", minHeight: "70vh" }}
                    >
                        <div
                            className="multiCard-step-content-holder mb-0"
                            style={{ transform: `translateX(-${activeIndex * 50}%)`, height: "calc(100% - 42px)" }}
                        >
                            <div style={{ width: "100%", padding: "0 1em" }}>
                                <div className="dropdownSection mb-3">
                                    <FormDropdown
                                        id="quickAddPeriod"
                                        fluid
                                        
                                        label="Period"
                                        items={dateItems}
                                        defaultValue={
                                            dateItems[
                                            dateItems.map((e) => e.value).indexOf(date) === -1
                                                ? 0
                                                : dateItems.map((e) => e.value).indexOf(date)
                                            ]
                                        }
                                        value={
                                            dateItems[
                                            dateItems.map((e) => e.value).indexOf(date) === -1
                                                ? 0
                                                : dateItems.map((e) => e.value).indexOf(date)
                                            ]
                                        }
                                        onChange={(e, { value }) => {
                                            setDate(value.value);
                                        }}
                                    />

                                    {<FormDropdown
                                        id="quickAddTime"
                                        fluid
                                        
                                        label="Time"
                                        items={timeItems}
                                        disabled={filterTypeId === 1}
                                        defaultValue={
                                            timeItems[
                                            timeItems.map((e) => e.value).indexOf(time) === -1
                                                ? 0
                                                : timeItems.map((e) => e.value).indexOf(time)
                                            ]
                                        }
                                        value={
                                            timeItems[
                                            timeItems.map((e) => e.value).indexOf(time) === -1
                                                ? 0
                                                : timeItems.map((e) => e.value).indexOf(time)
                                            ]
                                        }
                                        onChange={(e, { value }) => {
                                            setTime(value.value);
                                        }}
                                    />}
                                    {date === 5 ? (
                                        <>
                                            <div className="custom-date-container">
                                                <div>
                                                    <label>Start Date</label>
                                                    {
                                                        context.teams.hostClientType.web ||
                                                            context.teams.hostClientType.desktop ? (
                                                            <TeamsDatePicker
                                                                required={true}
                                                                value={startDate}
                                                                maxDate={endDate}
                                                                onChange={(value) => {
                                                                    setStartDate(value);
                                                                }}
                                                            />
                                                        ) : (
                                                            <NativeDatePicker
                                                                id="startDate"
                                                                value={startDate}
                                                                maxDate={endDate}
                                                                onChange={(value) => {
                                                                    setStartDate(value);
                                                                }}
                                                                required={true}
                                                            />
                                                        )
                                                        // <FormField
                                                        //   className="timePicker"
                                                        //   control = {{ as: Input, value: startTime, type: 'time',
                                                        //     onChange: (e)=>{setStartTime(e.target.value)}
                                                        //   }}
                                                        // />
                                                    }
                                                </div>
                                                <div>
                                                    <label>End Date</label>
                                                    {
                                                        context.teams.hostClientType.web ||
                                                            context.teams.hostClientType.desktop ? (
                                                            <TeamsDatePicker
                                                                required={true}
                                                                minDate={startDate}
                                                                value={endDate}
                                                                onChange={(value) => {
                                                                    setEndDate(value);
                                                                }}
                                                            />
                                                        ) : (
                                                            <NativeDatePicker
                                                                id="endDate"
                                                                minDate={startDate}
                                                                value={endDate}
                                                                onChange={(value) => {
                                                                    setEndDate(value);
                                                                }}
                                                                required={true}
                                                            />
                                                        )
                                                        // <FormField
                                                        //   className="timePicker"
                                                        //   control = {{ as: Input, value: endTime, type: 'time',
                                                        //     onChange: (e)=>{setEndTime(e.target.value)}
                                                        //   }}
                                                        // />
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        date === 9 || date === 10 ? <FormLabel className="form-text">
                                            {handleQuarter(date)}
                                            <span className="text-secondary">Selected Quarter is</span> {_fromDateLabel} <span className="text-secondary">to</span> {_toDateLabel}</FormLabel> :
                                            <></>
                                    )
                                    }


                                    {time === 2 && (
                                        <> {date === 5 || date === 9 || date === 10 ? (<></>) : (<div></div>)}
                                            <div className="custom-time-container">
                                                <div>
                                                    <label>Start Time</label>
                                                    {
                                                        context.teams.hostClientType.web ||
                                                            context.teams.hostClientType.desktop ? (
                                                            <TeamsTimePicker
                                                                required={true}
                                                                value={startTime}
                                                                onChange={(value) => {
                                                                    setStartTime(value);
                                                                }}
                                                            />
                                                        ) : (
                                                            <NativeTimePicker
                                                                id="startTime"
                                                                value={startTime}
                                                                onChange={(value) => {
                                                                    setStartTime(value);
                                                                }}
                                                                required={true}
                                                            />
                                                        )
                                                        // <FormField
                                                        //   className="timePicker"
                                                        //   control = {{ as: Input, value: startTime, type: 'time',
                                                        //     onChange: (e)=>{setStartTime(e.target.value)}
                                                        //   }}
                                                        // />
                                                    }
                                                </div>
                                                <div>
                                                    <label>End Time</label>
                                                    {
                                                        context.teams.hostClientType.web ||
                                                            context.teams.hostClientType.desktop ? (
                                                            <TeamsTimePicker
                                                                required={true}
                                                                min={startTime}
                                                                value={endTime}
                                                                onChange={(value) => {
                                                                    setEndTime(value);
                                                                }}
                                                            />
                                                        ) : (
                                                            <NativeTimePicker
                                                                id="endTime"
                                                                min={startTime}
                                                                value={endTime}
                                                                onChange={(value) => {
                                                                    setEndTime(value);
                                                                }}
                                                                required={true}
                                                            />
                                                        )
                                                        // <FormField
                                                        //   className="timePicker"
                                                        //   control = {{ as: Input, value: endTime, type: 'time',
                                                        //     onChange: (e)=>{setEndTime(e.target.value)}
                                                        //   }}
                                                        // />
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    )}


                                </div>

                                <div className="dropdownSection mb-10 groupFilter">
                                    <FormDropdown
                                        id="quickAddOrg"
                                        fluid
                                        
                                        label="Organization"
                                        items={context.loadExternalTeamsCallCards === true ? filterTypes : filterTypes.filter(f => !(f.value == 4 || f.value == 3))}
                                        value={FilterTypes[filterTypeId]}
                                        defaultValue={FilterTypes[filterTypeId]}
                                        onChange={(e, { value }) => {
                                            handleFilterTypeChange(value.value);
                                        }}
                                    />
                                    {filterTypeId === 0 && (
                                        <FormDropdown
                                            id="quickAddPeople"
                                            fluid
                                            
                                            label="People"
                                            search
                                            multiple
                                            autoSize
                                            placeholder="Select People"
                                            items={dropDownOptions.filter(
                                                (uf) => !peopleMasKList.includes(uf.key)
                                            )}
                                            // items={filterData.userFilter.items.filter(
                                            //   (uf) => !peopleMasKList.includes(uf.key)
                                            // )}
                                            defaultValue={[]}
                                            value={users}
                                            onChange={(e, { value }) => {
                                                handleUserChange(value);
                                            }}
                                            noResultsMessage="We couldn't find any matches."
                                            itemToValue={(obj) => {
                                                return obj.key;
                                            }}
                                            renderSelectedItem={(Component, props) => {
                                                if (props.key < 0) {
                                                    props.className = props.className + " _expandable";
                                                    props.onClick = (n, a) => {
                                                        if (
                                                            !n.currentTarget.classList.contains(
                                                                "ui-dropdown__selecteditem__icon"
                                                            )
                                                        ) {
                                                            setExpandPeople(props.key);
                                                        }
                                                    };
                                                }
                                                return <Component {...props} />;
                                            }}
                                        />
                                    )}
                                    {filterTypeId === 1 &&
                                        <FormCheckbox
                                            id="quickAddCheckTeamGroup"
                                            className="checkBoxTeamisGroup align-items-center"
                                            style={{ fontSize: "12px" }}
                                            label="Include reporting on activity outside selected Teams."
                                            value={teamIsGroup}
                                            checked={teamIsGroup}
                                            disabled={teamIsGroupDisabled}
                                            onChange={(e, { checked }) => { onTeamIsGroup(checked) }}
                                        />
                                    }
                                    {filterTypeId === 2 && (
                                        <FormDropdown
                                            id="quickAddGroups"
                                            fluid
                                            
                                            label="Groups"
                                            search
                                            multiple
                                            autoSize
                                            placeholder="Select Groups"
                                            items={filterData.groupFilter.items}
                                            defaultValue={groups}
                                            value={groups}
                                            itemToValue={(obj) => {
                                                return obj.key;
                                            }}
                                            onChange={(e, { value }) => {
                                                handleGroupChange(value);
                                            }}
                                            noResultsMessage="We couldn't find any matches."
                                        />
                                    )}
                                    {filterTypeId === 3 && (
                                        <FormDropdown
                                            id="quickAddCallQ"
                                            fluid
                                            
                                            label="Call Queues"
                                            search
                                            multiple
                                            autoSize
                                            placeholder="Select Call Queues"
                                            items={filterData.callQueueFilter.items}
                                            defaultValue={callQueues}
                                            value={callQueues}
                                            itemToValue={(obj) => {
                                                return obj.key;
                                            }}
                                            onChange={(e, { value }) => {
                                                handleCallQueueChange(value);
                                            }}
                                            noResultsMessage="We couldn't find any matches."
                                        />
                                    )}
                                    {filterTypeId === 4 && (
                                        <FormDropdown
                                            id="quickAddAgents"
                                            fluid
                                            
                                            label="Agents"
                                            search
                                            multiple
                                            autoSize
                                            placeholder="Select Agents"
                                            items={filterData.agentFilter.items}
                                            defaultValue={agents}
                                            value={agents}
                                            itemToValue={(obj) => {
                                                return obj.key;
                                            }}
                                            onChange={(e, { value }) => {
                                                handleAgentChange(value);
                                            }}
                                            noResultsMessage="We couldn't find any matches."
                                        />
                                    )}
                                </div>
                                <div className="dropdownSection mt-2 groupFilter">
                                    <div className="mb-2">
                                        {(filterTypeId === 1 && filterData.teamFilter.items.length === 0) ?
                                            <Text>You do not have permission to any team. Please get team permissions in settings</Text>
                                            : <></>
                                        }
                                        {(filterTypeId === 2 && filterData.groupFilter.items.length === 0) ?
                                            <Text>You do not have permission to any group. Please get group permissions in settings</Text>
                                            : <></>
                                        }
                                    </div>
                                    <div className="mb-2">
                                        {filterTypeId === 1 && (
                                            <FormDropdown
                                                id="quickAddTeams"
                                                fluid
                                                
                                                label="Teams"
                                                search
                                                multiple
                                                autoSize
                                                placeholder="Select teams"
                                                items={teamIsGroup ? teamIsGroupList : teamIsNotGroupList}
                                                defaultValue={teams}
                                                value={teams}
                                                itemToValue={(obj) => {
                                                    return obj.key;
                                                }}
                                                onChange={(e, { value }) => {
                                                    handleTeamChange(value);
                                                }}
                                                noResultsMessage="We couldn't find any matches."
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            {loaded && (
                                <div style={{ width: "100%", padding: "0 1em" }}>
                                    <Grid
                                        data={orderBy(widgets != null ?
                                            widgets//.sort((a, b) => a.widgetName > b.widgetName ? 1 : -1)
                                                .map((item) => ({
                                                    ...item,
                                                    [SELECTED_FIELD]: selectedState[idGetter(item)],
                                                }))
                                            : null, sort)
                                        }
                                        sortable={true}
                                        sort={sort}
                                        onSortChange={sortChange}
                                        style={{
                                            maxHeight: "100%",
                                        }}
                                        dataItemKey={DATA_ITEM_KEY}
                                        selectedField={SELECTED_FIELD}
                                        selectable={{
                                            enabled: true,
                                            drag: false,
                                            cell: false,
                                        }}
                                        onSelectionChange={selectionChange}
                                        onHeaderSelectionChange={headerSelectionChange}
                                        detail={DetailComponent}
                                        expandField="expanded"
                                        rowRender={rowRender}
                                    >
                                        <GridNoRecords>
                                            {widgets != null ? ("No cards found for the filters selected."
                                            ) : (
                                                <Loader />
                                            )}
                                        </GridNoRecords>
                                        <Column
                                            field="checked"
                                            width="32px"
                                            headerSelectionValue={
                                                widgets.findIndex(
                                                    (item) => !selectedState[idGetter(item)]
                                                ) === -1
                                            }
                                        />
                                        <Column
                                            field="widgetName"
                                            title="Card"
                                            width={
                                                context.mediaCategory.sm
                                                    ? "calc(100% - 172px)"
                                                    : "calc(100% - 302px)"
                                            }
                                        />
                                        <Column
                                            width="80px"
                                            title="Category"
                                            cell={CellWithCategoryIcon}
                                            headerCell={CenteredHeader}
                                        />
                                        <Column
                                            width="60px"
                                            title="View"
                                            cell={ViewCell}
                                            headerCell={CenteredHeader}
                                        />
                                        {!context.mediaCategory.sm && (
                                            <Column
                                                width="130px"
                                                title="Type"
                                                field="collaborationTypeID"//"collaborationTypeID"
                                                cell={CellWithTypeIcon}
                                            />
                                        )}
                                    </Grid>
                                </div>
                            )}
                        </div>
                        <Flex className="align-items-center mb-0" gap="gap.small" fill hAlign="center" style={{ height: "auto" }}>
                            {activeIndex !== 0 && showMessage === 1 && <div className="col text-center px-1 lh-1">The highlighted cards will be saved as duplicates.</div>}
                        </Flex>

                        <Flex className="align-items-center" gap="gap.small" fill hAlign="end" style={{ height: "auto" }}>
                            {activeIndex !== 0 && isDemo && <div className="col text-right px-0 text-nowrap">Exploring app with sample data.</div>}
                            {activeIndex === 0 ? (
                                <Button
                                    id="quickAddCancel"
                                    content="Cancel"
                                    onClick={() => props.cancelHandler()}
                                />
                            ) : activeIndex !== 0 ? (
                                <Button id="quickAddBack" content="Back" onClick={onPrevious} />
                            ) : (
                                <></>
                            )}
                            {activeIndex === 0 ? (
                                <Button
                                    id="quickAddNext"
                                    disabled={isValid === false}
                                    primary
                                    content="Next"
                                    onClick={onNext}
                                />
                            ) : isDemo ? (
                                <FormButton
                                    id="quickAddSaveDemo"
                                    disabled={isSaveEnabled === false || submitDemoButtonState !== 0}
                                    icon={submitDemoButtonState === 2 && <AcceptIcon />}
                                    loading={submitDemoButtonState === 1}
                                    content={"Save"}
                                    primary
                                    onClick={() => handleSubmit()}
                                // style={isSaveEnabled ? {
                                //   color: "#212529",
                                //   backgroundColor: "#ffc107",
                                //   borderColor: "#ffc107"
                                // } : null}
                                />
                            ) :
                                activeIndex === 1 ? (
                                    <FormButton
                                        id="quickAddSave"
                                        disabled={isSaveEnabled === false || submitButtonState !== 0 || isDemo}
                                        icon={submitButtonState === 2 && <AcceptIcon />}
                                        loading={submitButtonState === 1}
                                        content={
                                            submitButtonState === 0
                                                ? "Save"
                                                : submitButtonState === 1
                                                    ? "Saving"
                                                    : submitButtonState === 2
                                                        ? "Saved"
                                                        : "Failed"
                                        }
                                        primary
                                        onClick={() => handleSubmit()}
                                    />
                                ) : (
                                    <></>
                                )}
                        </Flex>
                    </Form>

                    <FluentDialog
                        style={{ maxWidth: "300px" }}
                        backdrop={true}
                        open={open}
                        footer={{
                            children: (Component, props) => {
                                const { styles, ...rest } = props;
                                return (
                                    <Flex styles={styles} hAlign="center" gap="gap.small">
                                        <Button size="small" content="Cancel" onClick={onCancel} />
                                        <Button
                                            size="small"
                                            primary
                                            content="Ok"
                                            onClick={onConfirm}
                                        />
                                    </Flex>
                                );
                            },
                        }}
                        content={
                            <>
                                <Flex
                                    fill
                                    hAlign="center"
                                    style={{ paddingBottom: "15px" }}
                                    gap="gap.small"
                                >
                                    <ExclamationTriangleIcon
                                        size="largest"
                                        styles={{ color: "#fce100" }}
                                    />
                                    <Flex.Item>
                                        <Text content="If you go back, your selection will be lost." />
                                    </Flex.Item>
                                </Flex>
                            </>
                        }
                    />

                    <FluentDialog
                        style={{ maxWidth: "300px" }}
                        backdrop={true}
                        open={expandPeople < 0}
                        footer={{
                            children: (Component, props) => {
                                const { styles, ...rest } = props;
                                return (
                                    <Flex styles={styles} hAlign="center" gap="gap.small">
                                        <Button
                                            size="small"
                                            content="Cancel"
                                            onClick={onExpandCancel}
                                        />
                                        <Button
                                            size="small"
                                            primary
                                            content="Expand"
                                            onClick={onExpandConfirm}
                                        />
                                    </Flex>
                                );
                            },
                        }}
                        content={
                            <>
                                <Flex
                                    fill
                                    hAlign="center"
                                    style={{ paddingBottom: "15px" }}
                                    gap="gap.small"
                                >
                                    <ExclamationTriangleIcon
                                        size="largest"
                                        styles={{ color: "#fce100" }}
                                    />
                                    <Flex.Item>
                                        <Text content="If you expand the list, the list will be replaced by individual members. It is not possible to collapse the list again." />
                                    </Flex.Item>
                                </Flex>
                            </>
                        }
                    />
                    {openPreview && (
                        <Dialog
                            autoFocus={true}
                            className="previewDialog"
                            title={previewObj.widgetName}
                            onClose={() => setOpenPreview(false)}
                        >
                            <WidgetPreview
                                widgetID={previewObj.cardWidgetID}
                                viewTypeID={previewObj.cardViewTypeID}
                            />
                        </Dialog>
                    )}
                </Fragment>
            )}
        </WindowContext.Consumer>
    );
};

export default MultiCardSettings;
