import {
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartLegend,
    ChartTooltip,
    ChartValueAxis,
    ChartValueAxisItem
} from '@progress/kendo-react-charts';
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import * as React from "react";
import { orderBy } from "@progress/kendo-data-query";
import { EmojiIcon } from "@fluentui/react-northstar";
import { tooltipAsLabelBehavior } from '@fluentui/react-northstar';
import "hammerjs";
import { CallIcon, ChatIcon } from '@fluentui/react-icons-northstar'
import { SavePDFConsumer } from '../../Shared/Context/ExploreAppMode';
import { useInView } from 'react-intersection-observer';

const TeamEngagementView = ({ data }) => {
    const [sort, setSort] = React.useState([]);
    const [callstate, setCallstate] = React.useState(false);
    const { ref, inView } = useInView()
    const renderTooltip = ({
        point
    }) => <span>{point.category.toString()}: {point.value}</span>;
    // const data = props.data
    return (
        <SavePDFConsumer>
            {
                (yesPDF) => {
                    return <>
                        <div className="usr-view" style={{ maxHeight: "220px" }}>
                            <div className="row" style={{ minHeight: "250px", marginBottom: "15px" }}>
                                <div className="col-md-4 mb-2 px-md-2 ">
                                    <div className="usr-vDtlRowTrk border h-100">
                                        <div className="vDtl-t border-bottom">
                                            <span>Total Meetings <span>&nbsp;</span> {data.teamMeetings.totalMeetings}</span>
                                        </div>
                                        <div className="vDtl-inr calcH43 p-0">
                                            <div className="row h-50">
                                                <div className="col-md-6 pr-md-0 d-flex align-items-center justify-content-center">
                                                    <div className="vDtlRow">
                                                        <div className="vDtlRowTrk">
                                                            <div className="vDtlRowTrkUL w-100">
                                                                <div className="vDtlRowTrkLi d-md-block">
                                                                    <div className="col px-0 vDtlRowTrk-val" style={{ fontSize: "1.5em" }}>{data.teamMeetings.internalMeetings}</div>
                                                                    <div className="col px-0 vDtlRowTrk-p">Internal</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 pl-md-0 d-flex align-items-center justify-content-center">
                                                    <div className="vDtlRow">
                                                        <div className="vDtlRowTrk">
                                                            <div className="vDtlRowTrkUL w-100">
                                                                <div className="vDtlRowTrkLi d-md-block">
                                                                    <div className="col px-0 vDtlRowTrk-val" style={{ fontSize: "1.5em" }} >{data.teamMeetings.externalMeetings}</div>
                                                                    <div className="col px-0 vDtlRowTrk-p">External</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row h-50">
                                                <div className="col-md-6 pr-md-0 d-flex align-items-center justify-content-center">
                                                    <div className="vDtlRow">
                                                        <div className="vDtlRowTrk">
                                                            <div className="vDtlRowTrkUL w-100">
                                                                <div className="vDtlRowTrkLi d-md-block">
                                                                    <div className="col px-0 vDtlRowTrk-val" style={{ fontSize: "1.5em" }}>{data.teamMeetings.totalRecurringMeetings}</div>
                                                                    <div className="col px-0 vDtlRowTrk-p">Recurring</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 pl-md-0 d-flex align-items-center justify-content-center">
                                                    <div className="vDtlRow">
                                                        <div className="vDtlRowTrk">
                                                            <div className="vDtlRowTrkUL w-100">
                                                                <div className="vDtlRowTrkLi d-md-block">
                                                                    <div className="col px-0 vDtlRowTrk-val" style={{ fontSize: "1.5em" }}>{data.teamMeetings.totalTimeInMeeting}</div>
                                                                    <div className="col px-0 vDtlRowTrk-p">Total Duration</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-2 px-md-2 ">
                                    <div className="usr-vDtlRowTrk border h-100">
                                        <div className="vDtl-t border-bottom">
                                            <span>Total Messages <span>&nbsp;</span> {data.teamMessages.totalMessages}</span>
                                        </div>
                                        <div className="vDtl-inr calcH43 p-0">
                                            <div className="row h-50">
                                                <div className="col-md-6 pr-md-0 d-flex align-items-center justify-content-center">
                                                    <div className="vDtlRow">
                                                        <div className="vDtlRowTrk">
                                                            <div className="vDtlRowTrkUL w-100">
                                                                <div className="vDtlRowTrkLi d-md-block">
                                                                    <div className="col px-0 vDtlRowTrk-val" style={{ fontSize: "1.5em" }}>{data.teamMessages.totalMessages}</div>
                                                                    <div className="col px-0 vDtlRowTrk-p">Posts, Replies & Reactions</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 pl-md-0 d-flex align-items-center justify-content-center">
                                                    <div className="vDtlRow">
                                                        <div className="vDtlRowTrk">
                                                            <div className="vDtlRowTrkUL w-100">
                                                                <div className="vDtlRowTrkLi d-md-block">
                                                                    <div className="col px-0 vDtlRowTrk-val" style={{ fontSize: "1.5em" }}>{data.teamMessages.extPostsRepliesReactions}</div>
                                                                    <div className="col px-0 vDtlRowTrk-p">External Posts, Replies & Reactions</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row h-50">
                                                <div className="col-md-6 pr-md-0 d-flex align-items-center justify-content-center">
                                                    <div className="vDtlRow">
                                                        <div className="vDtlRowTrk">
                                                            <div className="vDtlRowTrkUL w-100">
                                                                <div className="vDtlRowTrkLi d-md-block">
                                                                    <div className="col px-0 vDtlRowTrk-val" style={{ fontSize: "1.5em" }}>{data.teamMessages.messageMentions}</div>
                                                                    <div className="col px-0 vDtlRowTrk-p">Mentions</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 pl-md-0 d-flex align-items-center justify-content-center">
                                                    <div className="vDtlRow">
                                                        <div className="vDtlRowTrk">
                                                            <div className="vDtlRowTrkUL w-100">
                                                                <div className="vDtlRowTrkLi d-md-block">
                                                                    <div className="compareDataInrRow-val" style={{ width: "100%" }}>
                                                                        <div className="progress" style={{ height: ".5rem", width: "90%" }}>
                                                                            <div className="progress-bar bg-success" style={{ width: `${(data.teamMessages.goodSentiment * 100) / (data.teamMessages.goodSentiment + data.teamMessages.neutralSentiment + data.teamMessages.poorSentiment)}%` }} title={`good : ${data.teamMessages.goodSentiment}`}>
                                                                            </div>
                                                                            <div className="progress-bar bg-warning border-left" style={{ width: `${(data.teamMessages.neutralSentiment * 100) / (data.teamMessages.goodSentiment + data.teamMessages.neutralSentiment + data.teamMessages.poorSentiment)}%` }} title={`Average : ${data.teamMessages.neutralSentiment}`}>
                                                                            </div>
                                                                            <div className="progress-bar bg-danger border-left" style={{ width: `${(data.teamMessages.poorSentiment * 100) / (data.teamMessages.goodSentiment + data.teamMessages.neutralSentiment + data.teamMessages.poorSentiment)}%` }} title={`Poor : ${data.teamMessages.poorSentiment}`}>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col px-0 vDtlRowTrk-p">Message Sentiment</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-2 px-md-2 ">
                                    <div className="usr-vDtlRowTrk border h-100">
                                        <div className="vDtl-t border-bottom">
                                            {/*<span>Collaborators</span><span><CallIcon /><ChatIcon /></span>*/}
                                            <span>Top 5 Collaborators </span>
                                            <div className="float-right">
                                                <a href="#" role="button" title="Meetings" className="p-r-3 text-secondary" onClick={(event) => { event.preventDefault(); setCallstate(true) }}>
                                                    {callstate ? <i className="bi bi-transparent bi-telephone-fill text-primary"></i> : <i className="bi bi-transparent bi-telephone"></i>}
                                                </a>
                                                <a href="#" role="button" title="Messages" className="text-secondary" onClick={(event) => { event.preventDefault(); setCallstate(false) }} >
                                                    {!callstate ? <i className="bi bi-transparent bi-chat-left-text-fill text-primary vl-l p-l-6" style={{ padding: '0 0 0 7px', margin: '0 0 0 7px' }}></i> : <i className="bi bi-transparent bi-chat-left-text vl-l p-l-6" style={{ padding: '0 0 0 7px', margin: '0 0 0 7px' }}></i>}
                                                </a>
                                            </div>
                                        </div>
                                        <div className="vDtl-inr">
                                            {data.teamCollaborators && callstate ?
                                                orderBy(data.teamCollaborators.callCollabList, sort).map((collaborator) => {
                                                    return (
                                                        <div className="vDtl-inrRow">
                                                            <div className="vDtl-inrRow-p">{collaborator.userName}</div>
                                                            <div className="vDtl-inrRow-val">{collaborator.callDuration}</div>
                                                        </div>
                                                    )
                                                })
                                                : []}
                                            {data.teamCollaborators.callCollabList.length === 0 && callstate ? <div className="vDtl-inrRow-p" style={{ paddingTop: '12px', paddingBottom: '12px' }}>No data to display</div> : []}
                                        </div>
                                        <div className="vDtl-inr">
                                            {data.teamCollaborators && !callstate ?
                                                orderBy(data.teamCollaborators.messageCollabList, sort).map((collaborator) => {
                                                    return (
                                                        <div className="vDtl-inrRow">
                                                            <div className="vDtl-inrRow-p">{collaborator.userName}</div>
                                                            <div className="vDtl-inrRow-val">{collaborator.messageCount}</div>
                                                        </div>
                                                    )
                                                })
                                                : []}
                                            {data.teamCollaborators.messageCollabList.length === 0 && !callstate ? <div className="vDtl-inrRow-p" style={{ paddingTop: '12px', paddingBottom: '12px' }}>No data to display</div> : []}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {data.teamsStackChartData.categories !== null && <div>{data.teamsStackChartData.categories.length >= 50 && <div ref={ref}> {inView && <div className="hideMeAfter5Seconds">
                                <p className='click-drag-flash-3 p-2 rounded'><i className="bi bi-arrow-left-short"></i> <i className="bi bi-mouse-fill"></i> <i className="bi bi-arrow-right-short"></i></p>
                            </div>}
                            </div>}</div>}
                            {data.teamsStackChartData && data.teamsStackChartData.categories && data.teamsStackChartData.categories.length > 0 &&
                                <div className="h-100" style={{ minHeight: "280px" }}>
                                    <Chart style={{ height: '100%' }} pannable={{
                                        lock: "y",
                                    }}
                                        zoomable={{
                                            mousewheel: {
                                                lock: yesPDF ? ["y", "x"] : "y"
                                            },
                                            selection: {
                                                lock: yesPDF ? ["y", "x"] : "y"
                                            },
                                        }}>
                                        <ChartValueAxis>
                                            <ChartValueAxisItem
                                                min={0}
                                            />
                                        </ChartValueAxis>
                                        <ChartLegend position="top" orientation="horizontal" />
                                        <ChartCategoryAxis>
                                            <ChartCategoryAxisItem categories={data.teamsStackChartData.categories} labels={{
                                                rotation: 'auto'
                                            }} maxDivisions={10} max={yesPDF ? null : 50} />
                                        </ChartCategoryAxis>
                                        <ChartSeries>
                                            {data.teamsStackChartData.series.map((item, idx) => (
                                                <ChartSeriesItem
                                                    key={idx}
                                                    color={item.color}
                                                    type="column" stack={true}
                                                    tooltip={{ visible: true }}
                                                    data={item.data}
                                                    name={item.name}
                                                />
                                            ))}
                                        </ChartSeries>
                                        <ChartTooltip render={renderTooltip} />
                                    </Chart>
                                </div>
                            }
                        </div>
                    </>
                }
            }
        </SavePDFConsumer>
    );
};

export default TeamEngagementView;
